export const dealsStatuses = [
    { label: "На рассмотрении", value: "in_review", color: "#BD10E0" },
    { label: "Утверждение условий", value: "conditions_approval", color: "#74E0F5" },
    { label: "Ожидают оплаты", value: "awaiting_payment", color: "#417505" },
    { label: "В процессе", value: "in_progress", color: "#4A90E2" },
    { label: "Ожидают проверки", value: "awaiting_verification", color: "#F5A623" },
    { label: "Проверка гаранта", value: "guarantor_check", color: "#6AC798" },
    { label: "Завершенные", value: "done", color: "#5CE6CD" },
    { label: "Отмененные", value: "denied", color: "#D0021B" }
];

export const dealStatuses = [
    { label: "На рассмотрении", value: "in_review", color: "#BD10E0" },
    { label: "Утверждение условий", value: "conditions_approval", color: "#74E0F5" },
    { label: "Ожидает оплаты", value: "awaiting_payment", color: "#417505" },
    { label: "В процессе", value: "in_progress", color: "#4A90E2" },
    { label: "Ожидает проверки", value: "awaiting_verification", color: "#F5A623" },
    { label: "Проверка гаранта", value: "guarantor_check", color: "#6AC798" },
    { label: "Завершен", value: "done", color: "#5CE6CD" },
    { label: "Отменен", value: "denied", color: "#D0021B" }
];

export const stepsStatuses = [
    { label: "На рассмотрении", value: "in_review", color: "#BD10E0", classes: "waiting" },
    { label: "Утверждение условий", value: "conditions_approval", color: "#74E0F5", classes: "inactive" },
    { label: "Ожидает оплаты", value: "awaiting_payment", color: "#417505", classes: "inactive" },
    { label: "В процессе", value: "in_progress", color: "#4A90E2", classes: "inactive" },
    { label: "Ожидает проверки", value: "awaiting_verification", color: "#F5A623", classes: "inactive" },
    { label: "Проверка гаранта", value: "guarantor_check", color: "#6AC798", classes: "inactive" },
    { label: "Завершена", value: "done", color: "#5CE6CD", classes: "inactive" }
];

export const notificationsDeals = [
    { label: "на рассмотрении", value: "in_review", color: "#BD10E0" },
    { label: "ожидает утверждения условий", value: "conditions_approval", color: "#74E0F5" },
    { label: "ожидает оплаты", value: "awaiting_payment", color: "#417505" },
    { label: "в процессе", value: "in_progress", color: "#4A90E2" },
    { label: "ожидает проверки", value: "awaiting_verification", color: "#F5A623" },
    { label: "ожидает проверки гаранта", value: "guarantor_check", color: "#6AC798" },
    { label: "в арбитраже", value: "arbitration", color: "#6AC798" },
    { label: "завершена", value: "done", color: "#5CE6CD" },
    { label: "отменена", value: "denied", color: "#D0021B" }
];

export const tasksStatuses = [
    { label: "В начале видео", value: "preroll" },
    { label: "В средине видео", value: "middle" },
    { label: "В конце видео", value: "postroll" },
    { label: "Интеграция в видео", value: "video_integration" },
    { label: "Ссылка в сообществе канала", value: "society_link" },
    { label: "Заказной ролик", value: "customized_video" },
    { label: "Реклама в YouTube-истории", value: "stories_ad" }
];
