import * as types from "../actions/constants";
import { splitMaleData, splitFemaleData } from "../helpers/functions";
const INITIAL_STATE = {
    all_bloggers: [],
    nextPage: null,
    error: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_BLOGGERS_SUCCESS:
            return {
                ...state,
                all_bloggers: [...state.all_bloggers, ...action.payload.data.results],
                nextPage: action.payload.data.next
            };
        case types.DISPATCH_EMPTY_PRICELIST:
            const tempBloggers = [...state.all_bloggers];
            tempBloggers.forEach((el) => el.blogger_id === action.id && (el.price_list = {}));
            return {
                ...state,
                all_bloggers: tempBloggers
            };
        case types.RESET_BLOGGERS:
            return INITIAL_STATE;
        case types.GET_CURRENT_BLOGGER_SUCCESS:
            let dataMale = [];
            let dataFemale = [];
            let dataCountries = [];
            let dataDemographics = [];
            if (action.payload.data.country_views && action.payload.data.country_views.length > 0) {
                dataCountries = [...action.payload.data.country_views];
                const others = action.payload.data.country_views.filter((el) => el[0] === "Other");
                dataCountries.sort((a, b) => parseFloat(a[1]) - parseFloat(b[1]));
                dataCountries.reverse();
                dataCountries = dataCountries.filter((el) => el[0] !== "Other");
                if (others.length !== 0) {
                    dataCountries.push(others[0]);
                }
            }
            if (
                action.payload.data.demographics.demographics &&
                action.payload.data.demographics.demographics.length > 0
            ) {
                let firstArr = [...action.payload.data.demographics.demographics.filter((el) => el[0] === "age13-17")];
                dataMale.push(...splitMaleData(firstArr));
                dataFemale.push(...splitFemaleData(firstArr));
                let secondArr = [...action.payload.data.demographics.demographics.filter((el) => el[0] === "age18-24")];
                dataMale.push(...splitMaleData(secondArr));
                dataFemale.push(...splitFemaleData(secondArr));
                let thirdArr = [...action.payload.data.demographics.demographics.filter((el) => el[0] === "age25-34")];
                dataMale.push(...splitMaleData(thirdArr));
                dataFemale.push(...splitFemaleData(thirdArr));
                let fourthArr = [...action.payload.data.demographics.demographics.filter((el) => el[0] === "age35-44")];
                dataMale.push(...splitMaleData(fourthArr));
                dataFemale.push(...splitFemaleData(fourthArr));
                let fifthArr = [...action.payload.data.demographics.demographics.filter((el) => el[0] === "age45-54")];
                dataMale.push(...splitMaleData(fifthArr));
                dataFemale.push(...splitFemaleData(fifthArr));
                let sixthArr = [...action.payload.data.demographics.demographics.filter((el) => el[0] === "age55-65")];
                dataMale.push(...splitMaleData(sixthArr));
                dataFemale.push(...splitFemaleData(sixthArr));
                let seventhArr = [...action.payload.data.demographics.demographics.filter((el) => el[0] === "age65")];
                dataMale.push(...splitMaleData(seventhArr));
                dataFemale.push(...splitFemaleData(seventhArr));
            }
            if (
                action.payload.data.demographics.demographics_diagram &&
                action.payload.data.demographics.demographics_diagram.length > 0
            ) {
                dataDemographics = [...action.payload.data.demographics.demographics_diagram];
                dataDemographics.forEach((item, i) => {
                    if (item[0] === "age13-17") dataDemographics[i][0] = "13 - 17 лет";
                    if (item[0] === "age18-24") dataDemographics[i][0] = "18 - 24 лет";
                    if (item[0] === "age25-34") dataDemographics[i][0] = "25 - 34 лет";
                    if (item[0] === "age35-44") dataDemographics[i][0] = "35 - 44 лет";
                    if (item[0] === "age45-54") dataDemographics[i][0] = "45 - 54 лет";
                    if (item[0] === "age55-64") dataDemographics[i][0] = "55 - 64 лет";
                    if (item[0] === "age65-") dataDemographics[i][0] = "65 лет и старше";
                });
            }
            return {
                ...state,
                current_blogger: action.payload.data,
                dataMale: dataMale,
                dataFemale: dataFemale,
                dataDemographics: dataDemographics,
                dataCountries: dataCountries,
                max: dataMale.length === 0 ? 10 : Math.floor(Math.round(Math.max(...dataMale) / 10) * 10 + 10),
                min:
                    dataFemale.length === 0
                        ? Number(-10)
                        : Math.floor(Math.round(Math.min(...dataFemale) / 10) * 10 - 10)
            };
        case types.GET_BLOGGERS_STATISTICS_SUCCESS:
            return {
                ...state,
                views: action.payload.data.views ? action.payload.data.view : [],
                subscribers: action.payload.data.subscribers ? action.payload.data.subscribers : []
            };
        default:
            return state;
    }
}
