import * as types from "./constants.jsx";

export function getProfile() {
    return {
        type: types.GET_PROFILE,
        payload: {
            client: "default",
            request: {
                url: `/profile/`,
                method: "get"
            }
        }
    };
}

export function patchProfile(data) {
    return {
        type: types.PATCH_PROFILE,
        payload: {
            client: "default",
            request: {
                url: `/profile/`,
                method: "patch",
                data
            }
        }
    };
}

export function patchPaymentInformation(data) {
    return {
        type: types.PATCH_PAYMENT_INFORMATION,
        payload: {
            client: "default",
            request: {
                url: `/profile/update-payment-data/`,
                method: "patch",
                data
            }
        }
    };
}

export function patchProfileChangePassword(data) {
    return {
        type: types.PATCH_PROFILE_CHANGE_PASSWORD,
        payload: {
            client: "default",
            request: {
                url: `/profile/change-password/`,
                method: "patch",
                data
            }
        }
    };
}

export function postAdvertiserType(data) {
    return {
        type: types.POST_ADVERTISER_TYPE,
        payload: {
            client: "default",
            request: {
                url: `/profile/set-advertiser-type/`,
                method: "post",
                data
            }
        }
    };
}

export function getAdvertiserType() {
    return {
        type: types.GET_ADVERTISER_TYPE,
        payload: {
            client: "default",
            request: {
                url: `/profile/set-advertiser-type/`,
                method: "get"
            }
        }
    };
}

export function resetErrorPassword() {
    return { type: types.RESET_PASSWORD_ERROR };
}
