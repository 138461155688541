import * as types from "../actions/constants";

const INITIAL_STATE = {
    error_auth: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.REGISTER_FAIL:
            return { ...state, error_auth: action.error.response.data };
        case types.LOGIN_FAIL:
            return { ...state, error_auth: action.error.response.data };
        case types.CHANGE_PASSWORD_FAIL:
            return { ...state, error_auth: action.error.response.data };

        default:
            return state;
    }
}
