import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FlagIcon from "../../../FlagIcon.js";
import countries from "../../../countries.json";

import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";
import Loader from "../../HelperComponents/Loader/Loader";
import BarChart from "../../HelperComponents/BarChart/BarChart";
// import DoughnutChart from "../../HelperComponents/DoughnutChart/DoughnutChart";
import StockChart from "../../HelperComponents/StockChart/StockChart";

import { getCurrentBlogger } from "../../../actions/bloggerAction";

import "./LectureHall.scss";

class LectureHall extends Component {
    state = {
        loading: true
    };

    componentDidMount() {
        const { getCurrentBlogger, id } = this.props;

        getCurrentBlogger(id).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ loading: false });
            }
        });
    }

    render() {
        const { dataMale, dataFemale, min, max, dataDemographics, dataCountries } = this.props;
        const { loading } = this.state;
        if (loading /* || dataCountries.length === 0 */)
            return (
                <div className="blogger_loader">
                    <Loader height={65} width={5} margin="15px" color={"#5ce6cd"} />
                </div>
            );

        return (
            <TransitionedBlock>
                <div className="lecture_hall_wrapper">
                    <div className="block_wrapper_bg">
                        <div className="bar_chart_wrapper">
                            <BarChart dataMale={dataMale} dataFemale={dataFemale} min={min} max={max} />
                        </div>
                        <div className="doughnut_chart_wrapper">
                            <div>
                                {/*<DoughnutChart
                                    data={dataDemographics}
                                />*/}
                                <StockChart data={dataDemographics} />
                            </div>
                            <div>
                                <div className="countries_title">Страны</div>
                                {dataCountries.map((el, id) => (
                                    <div className="country_block" key={id}>
                                        <div className="icon_block">
                                            {el[0] !== "Other" && (
                                                <FlagIcon code={el[0].toLowerCase()} squared={true} size={"lg"} />
                                            )}
                                            {el[0] !== "Other" ? (
                                                <span className="country_name">{countries.countries[el[0]]}</span>
                                            ) : (
                                                <span className="others">Остальные</span>
                                            )}
                                        </div>
                                        <div className="progress_bar">
                                            <div style={{ width: `${el[1]}%` }} className="progress_track" />
                                        </div>
                                        <div className="percentage">{Math.round(el[1])}%</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </TransitionedBlock>
        );
    }
}

function mapStateToProps(state) {
    return {
        blogger: state.bloggers.current_blogger,
        dataMale: state.bloggers.dataMale,
        dataFemale: state.bloggers.dataFemale,
        min: state.bloggers.min,
        max: state.bloggers.max,
        dataDemographics: state.bloggers.dataDemographics,
        dataCountries: state.bloggers.dataCountries
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getCurrentBlogger
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LectureHall);
