import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import help from "../../../assets/image/help.svg";
import ApplyBlogger from "../../../components/Blogger/Pricelist/ApplyBlogger/ApplyBlogger";
import { getTaskByType } from "../../../actions/taskGetterActions";
import { patchPricelist } from "../../../actions/pricelistAction";
import { numberWithSpaces } from "../../../helpers/functions";

import "./Pricelist.scss";
// import {preventInertiaScroll} from "react-select/src/internal/ScrollLock/utils";

class Pricelist extends Component {
    state = {
        open: false,
        firstElId: null,
        loading: false,
        task_type: "",
        reRender: false,
        price_list: this.props.price_list
    };

    toggleDialog = () => {
        this.setState(({ open, reRender }) => ({
            open: !open,
            reRender: !reRender
        }));
    };

    getRequest = (request) => {
        const { getTaskByType } = this.props;
        this.setState({ loading: true, task_type: request });
        this.toggleDialog();

        getTaskByType(request).then((res) => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                if (res.payload.data[0]) {
                    this.setState({ firstElId: res.payload.data[0].id });
                }
                this.setState({ loading: false });
            }
        });
    };

    changeValue = (props) => {
        let typeName = props.target.getAttribute("type_name"),
            obj = {},
            newPrice = this.state.price_list;
        const { patchPricelist } = this.props;

        newPrice[typeName] = props.target.value;
        obj[typeName] = props.target.value ? props.target.value : null;

        patchPricelist(obj, this.props.channelId).then((res) => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
            }
        });

        this.setState({ price_list: newPrice });
        props.target.placeholder = this.state.price_list[typeName];
    };

    changeInput = (props) => {
        let typeName = props.target.getAttribute("type_name");

        props.target.value = this.state.price_list[typeName];
    };

    clear = (props) => {
        console.log(props.target.value);

        //props.target.placeholder = props.target.value !== '' ? props.target.value : '';
        //props.target.value = props.target.placeholder;
        if (props.target.value === "") props.target.placeholder = "0";
    };

    render() {
        const { open, firstElId, loading, task_type, reRender } = this.state;
        const { bloggerInterface, price_list, match } = this.props;
        const blogger_id = match.params;

        return !price_list.preroll &&
            !price_list.middle &&
            !price_list.postroll &&
            !price_list.video_integration &&
            !price_list.society_link &&
            !price_list.customized_video &&
            !price_list.stories_ad &&
            !bloggerInterface ? (
            ""
        ) : (
            <div className="price_list_wrapper">
                {!price_list.preroll && !price_list.middle && !price_list.postroll && !bloggerInterface ? (
                    ""
                ) : (
                    <div className="block_wrapper_bg price_block price_block_big">
                        <div className="title">
                            Реклама в видео
                            <div className="box_tooltip">
                                <img src={help} alt="help" />
                                <div className="tooltip">
                                    Явная реклама продолжительностью 15 - 30сек., размещаемая в начале/в середине/в
                                    конце видео, состоящая из нарезки кадров о продвигаемом объекте + озвучивание
                                    особенностей/преимуществ
                                </div>
                            </div>
                        </div>
                        {!price_list.preroll && !bloggerInterface ? (
                            ""
                        ) : (
                            <div>
                                <div className="descriptions">- в начале («преролл»)</div>
                                <div className="blogger_price_list_info">
                                    {bloggerInterface ? (
                                        <Fragment>
                                            <span className="price">Цена</span>
                                            <div className="block_price">
                                                <p>
                                                    <input
                                                        type="number"
                                                        pattern="[0-9]*"
                                                        className={
                                                            !this.state.price_list.preroll ? "disable-placeholder" : ""
                                                        }
                                                        placeholder={this.state.price_list.preroll || 0}
                                                        disabled={!bloggerInterface}
                                                        type_name="preroll"
                                                        onChange={this.clear}
                                                        onFocus={this.changeInput}
                                                        onBlur={this.changeValue}
                                                    />
                                                </p>
                                                <span>руб.</span>
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <div className="price_amount">
                                            <div>{numberWithSpaces(price_list.preroll)} руб.</div>
                                            <button className="green_btn" onClick={() => this.getRequest("preroll")}>
                                                Заказать
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {!price_list.middle && !bloggerInterface ? (
                            ""
                        ) : (
                            <div>
                                <div className="descriptions">- в середине</div>
                                <div className="blogger_price_list_info">
                                    {bloggerInterface ? (
                                        <Fragment>
                                            <span className="price">Цена</span>
                                            <div className="block_price">
                                                <p>
                                                    <input
                                                        type="number"
                                                        className={
                                                            !this.state.price_list.middle ? "disable-placeholder" : ""
                                                        }
                                                        placeholder={this.state.price_list.middle || 0}
                                                        disabled={!bloggerInterface}
                                                        type_name="middle"
                                                        onChange={this.clear}
                                                        onFocus={this.changeInput}
                                                        onBlur={this.changeValue}
                                                    />
                                                </p>
                                                <span>руб.</span>
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <div className="price_amount">
                                            <div>{numberWithSpaces(price_list.middle)} руб.</div>
                                            <button className="green_btn" onClick={() => this.getRequest("middle")}>
                                                Заказать
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {!price_list.postroll && !bloggerInterface ? (
                            ""
                        ) : (
                            <div>
                                <div className="descriptions">- в конце («постролл»)</div>
                                <div className="blogger_price_list_info">
                                    {bloggerInterface ? (
                                        <Fragment>
                                            <span className="price">Цена</span>
                                            <div className="block_price">
                                                <p>
                                                    <input
                                                        type="number"
                                                        className={
                                                            !this.state.price_list.postroll ? "disable-placeholder" : ""
                                                        }
                                                        placeholder={this.state.price_list.postroll || 0}
                                                        disabled={!bloggerInterface}
                                                        type_name="postroll"
                                                        onChange={this.clear}
                                                        onFocus={this.changeInput}
                                                        onBlur={this.changeValue}
                                                    />
                                                </p>
                                                <span>руб.</span>
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <div className="price_amount">
                                            <div>{numberWithSpaces(price_list.postroll)} руб.</div>
                                            <button className="green_btn" onClick={() => this.getRequest("postroll")}>
                                                Заказать
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {!price_list.video_integration && !bloggerInterface ? (
                    ""
                ) : (
                    <div className="block_wrapper_bg price_block">
                        <div className="title">
                            Интеграция в видео
                            <div className="box_tooltip">
                                <img src={help} alt="help" />
                                <div className="tooltip">
                                    Интеграция рекламы в контексте видео (Скрытая реклама/Продакт плейсмент)
                                </div>
                            </div>
                        </div>
                        <div className="blogger_price_list_info">
                            {bloggerInterface ? (
                                <Fragment>
                                    <span className="price">Цена</span>
                                    <div className="block_price">
                                        <p>
                                            <input
                                                type="number"
                                                className={
                                                    !this.state.price_list.video_integration
                                                        ? "disable-placeholder"
                                                        : ""
                                                }
                                                placeholder={this.state.price_list.video_integration || 0}
                                                disabled={!bloggerInterface}
                                                type_name="video_integration"
                                                onChange={this.clear}
                                                onFocus={this.changeInput}
                                                onBlur={this.changeValue}
                                            />
                                        </p>
                                        <span>руб.</span>
                                    </div>
                                </Fragment>
                            ) : (
                                <div className="price_amount">
                                    <div>{numberWithSpaces(price_list.video_integration)} руб.</div>
                                    <button className="green_btn" onClick={() => this.getRequest("video_integration")}>
                                        Заказать
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {!price_list.society_link && !bloggerInterface ? (
                    ""
                ) : (
                    <div className="block_wrapper_bg price_block">
                        <div className="title">
                            Ссылка в сообществе канала
                            <div className="box_tooltip">
                                <img src={help} alt="help" />
                                <div className="tooltip">Ссылка будет отображаться во вкладке канала "Сообщество"</div>
                            </div>
                        </div>
                        <div className="blogger_price_list_info">
                            {bloggerInterface ? (
                                <Fragment>
                                    <span className="price">Цена</span>
                                    <div className="block_price">
                                        <p>
                                            <input
                                                type="number"
                                                className={
                                                    !this.state.price_list.society_link ? "disable-placeholder" : ""
                                                }
                                                placeholder={this.state.price_list.society_link || 0}
                                                disabled={!bloggerInterface}
                                                type_name="society_link"
                                                onChange={this.clear}
                                                onFocus={this.changeInput}
                                                onBlur={this.changeValue}
                                            />
                                        </p>
                                        <span>руб.</span>
                                    </div>
                                </Fragment>
                            ) : (
                                <div className="price_amount">
                                    <div>{numberWithSpaces(price_list.society_link)} руб.</div>
                                    <button className="green_btn" onClick={() => this.getRequest("society_link")}>
                                        Заказать
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {!price_list.customized_video && !bloggerInterface ? (
                    ""
                ) : (
                    <div className="block_wrapper_bg price_block">
                        <div className="title">
                            Заказной ролик
                            <div className="box_tooltip">
                                <img src={help} alt="help" />
                                <div className="tooltip">
                                    Будет снят ролик полностью посвящённый рекламируемому товару/услуге
                                </div>
                            </div>
                        </div>
                        <div className="blogger_price_list_info">
                            {bloggerInterface ? (
                                <Fragment>
                                    <span className="price">Цена</span>
                                    <div className="block_price">
                                        <p>
                                            <input
                                                type="number"
                                                className={
                                                    !this.state.price_list.customized_video ? "disable-placeholder" : ""
                                                }
                                                placeholder={this.state.price_list.customized_video || 0}
                                                disabled={!bloggerInterface}
                                                type_name="customized_video"
                                                onChange={this.clear}
                                                onFocus={this.changeInput}
                                                onBlur={this.changeValue}
                                            />
                                        </p>
                                        <span>руб.</span>
                                    </div>
                                </Fragment>
                            ) : (
                                <div className="price_amount">
                                    <div>{numberWithSpaces(price_list.customized_video)} руб.</div>
                                    <button className="green_btn" onClick={() => this.getRequest("customized_video")}>
                                        Заказать
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {!price_list.stories_ad && !bloggerInterface ? (
                    ""
                ) : (
                    <div className="block_wrapper_bg price_block">
                        <div className="title">
                            Реклама в YouTube-истории
                            <div className="box_tooltip">
                                <img src={help} alt="help" />
                                <div className="tooltip">Реклама будет размещена в YouTube-истории канала</div>
                            </div>
                        </div>
                        <div className="blogger_price_list_info">
                            {bloggerInterface ? (
                                <Fragment>
                                    <span className="price">Цена</span>
                                    <div className="block_price">
                                        <p>
                                            <input
                                                type="number"
                                                className={
                                                    !this.state.price_list.stories_ad ? "disable-placeholder" : ""
                                                }
                                                placeholder={this.state.price_list.stories_ad || 0}
                                                disabled={!bloggerInterface}
                                                type_name="stories_ad"
                                                onChange={this.clear}
                                                onFocus={this.changeInput}
                                                onBlur={this.changeValue}
                                            />
                                        </p>
                                        <span>руб.</span>
                                    </div>
                                </Fragment>
                            ) : (
                                <div className="price_amount">
                                    <div>{numberWithSpaces(price_list.stories_ad)} руб.</div>
                                    <button className="green_btn" onClick={() => this.getRequest("stories_ad")}>
                                        Заказать
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <ApplyBlogger
                    open={open}
                    toggleDialog={this.toggleDialog}
                    id={firstElId}
                    loading={loading}
                    bloggerId={blogger_id}
                    task_type={task_type}
                    reRender={reRender}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getTaskByType,
            patchPricelist
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Pricelist);
