import React, { Component, Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Snack from "../../components/HelperComponents/Snack/Snack";
import Head from "../../components/Head/Head";
import Panel from "../../components/Panel/Panel";
import Dashboard from "../../components/Dashboard/Dashboard";
import BloggersSearch from "../../components/BloggersSearch/BloggersSearch";
import Blogger from "../../components/Blogger/Blogger";
import TestYoutubeAPI from "../../components/TestYoutubeAPI/TestYoutubeAPI";
import ProfileAdvertiser from "../../components/ProfileAdvertiser/ProfileAdvertiser";
import CampaingPage from "../../components/CampaingPage/CampaingPage";
import DealsPage from "../../components/DealsPage/DealsPage";
import AddChannel from "../../components/AddChannel/AddChannel";
import FinancePage from "../../components/FinancePage/FinancePage";
import SearchTasks from "../../components/SearchTasks/SearchTasks";
import DealsInnerPage from "../../components/DealsInnerPage/DealsInnerPage";
import Loader from "../../components/HelperComponents/ContentLoader/ContentLoader";

import { getProfile, patchProfile } from "../../actions/profileActions";
import { closeErrorSnack, closeSuccessSnack, handlePaymentDialog, getAllNotifications } from "../../actions/appActions";
import DialogComponent from "../../components/HelperComponents/DialogComponent/DialogComponent";
import PaymentDetails from "../../components/PaymentDetails/PaymentDetails";
import PublicOffer from "../../components/PublicOffer/PublicOffer";
import FAQ from "../../components/PublicPages/FAQ";

class Container extends Component {
    state = {
        loading: true,
        bloggerInterface: true,
        isAgent: false
    };

    componentDidMount() {
        this.doRequest();
    }

    doRequest = () => {
        const { getProfile, history, handlePaymentDialog, getAllNotifications } = this.props;
        this.setState({ loading: true });
        getProfile().then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ isAgent: res.payload.data.agent });

                getAllNotifications().then(() => {
                    this.setNotificationsInterval();
                    localStorage.setItem("bloggerInterface", res.payload.data.current_role);
                    if (!!res.payload.data[`${res.payload.data.current_role}_payment_popup`]) {
                        handlePaymentDialog(true);
                    }
                    this.setState({ loading: false });
                });
            }
            if (res.error && res.error.response && res.error.response.status && res.error.response.status === 401) {
                localStorage.clear();
                history.push("/auth");
            }
        });
    };

    setNotificationsInterval = () => {
        const { getAllNotifications } = this.props;
        setInterval(() => {
            getAllNotifications();
        }, 30000);
    };

    handleInterfaceChange = item => {
        const { patchProfile, history, handlePaymentDialog, getProfile } = this.props;
        patchProfile({ current_role: item }).then(res => {
            getProfile().then(profile => {
                const { channels, current_role } = profile.payload.data;
                localStorage.setItem("bloggerInterface", current_role);
                if (!!res.payload.data[`${res.payload.data.current_role}_payment_popup`]) {
                    handlePaymentDialog(true);
                }
                if (current_role === "blogger") {
                    history.push(channels && channels.length > 0 ? "/main/search-tasks" : "/main/add-channel");
                } else {
                    history.push("/main");
                }
            });
        });
    };

    loadData = () => {
        this.setState(({ loading }) => ({
            loading: !loading
        }));
    };

    closePaymentPopup = () => this.props.handlePaymentDialog(false);

    render() {
        const {
            match,
            history,
            chennel,
            id,
            errorSnack,
            errorSnackText,
            successSnack,
            successSnackText,
            closeErrorSnack,
            closeSuccessSnack,
            openPaymentDetails,
            profilePaymentInfo
        } = this.props;
        const { loading, isAgent } = this.state;
        const bloggerInterface = localStorage.getItem("bloggerInterface");
        if (loading)
            return (
                <div className="loader">
                    <Loader height={65} width={5} margin="15px" color={"#5ce6cd"} />
                </div>
            );
        return (
            <Fragment>
                <Head
                    history={history}
                    match={match}
                    handleInterfaceChange={this.handleInterfaceChange}
                    bloggerInterface={bloggerInterface}
                    isAgent={isAgent}
                />
                <div className="block_page">
                    <Panel
                        bloggerInterface={bloggerInterface}
                        channel={chennel}
                        id={id}
                        loadData={this.loadData}
                        doRequest={this.doRequest}
                    />
                    <Switch>
                        <Route
                            path={match.url}
                            exact
                            render={() =>
                                bloggerInterface === "blogger" ? (
                                    chennel && chennel.length === 0 ? (
                                        <Redirect to="/main/add-channel" />
                                    ) : (
                                        <Redirect to="/main/search-tasks" />
                                    )
                                ) : (
                                    <Redirect to="/main/bloggers-search" />
                                )
                            }
                        />
                        <Route path={`${match.url}/dashboard`} component={Dashboard} />
                        <Route path={`${match.url}/blogger/:id`} component={Blogger} />
                        <Route path={`${match.url}/my-page/:id`} component={Blogger} />
                        <Route path={`${match.url}/test-youtube-api`} exact component={TestYoutubeAPI} />
                        <Route path={`${match.url}/profile`} component={ProfileAdvertiser} />
                        <Route path={`${match.url}/public-offer`} component={PublicOffer} />
                        <Route path={`${match.url}/faq`} component={FAQ} />
                        <Route path={`${match.url}/campaing-page/:id`} component={CampaingPage} />
                        <Route
                            path={`${match.url}/finance-page`}
                            render={() => (bloggerInterface !== "blogger" ? <FinancePage /> : <Redirect to="/main" />)}
                        />
                        <Route path={`${match.url}/deals-page`} component={DealsPage} />
                        <Route path={`${match.url}/deals-inner/:id`} component={DealsInnerPage} />
                        <Route
                            path={`${match.url}/add-channel`}
                            render={() => (bloggerInterface === "blogger" ? <AddChannel /> : <Redirect to="/main" />)}
                        />
                        <Route
                            path={`${match.url}/search-tasks`}
                            render={() => (bloggerInterface === "blogger" ? <SearchTasks /> : <Redirect to="/main" />)}
                        />
                        <Route
                            path={`${match.url}/bloggers-search`}
                            render={() =>
                                bloggerInterface !== "blogger" ? <BloggersSearch /> : <Redirect to="/main" />
                            }
                        />
                        {/*<Route path={`${match.url}/add-channel`} component={AddChannel} />*/}
                        {/*<Route path={`${match.url}/search-tasks`} component={SearchTasks} />*/}
                        {/*<Route path={`${match.url}/bloggers-search`} component={BloggersSearch} />*/}
                        <Route render={() => <p>Not found</p>} />
                    </Switch>
                    <Snack open={errorSnack} handleSnack={closeErrorSnack} message={errorSnackText} type="error" />
                    <Snack open={successSnack} handleSnack={closeSuccessSnack} message={successSnackText} />
                    <DialogComponent open={openPaymentDetails} onClose={this.closePaymentPopup}>
                        <div className="payment_details_dialog">
                            <div className="big_title">Платёжные данные</div>
                            <div className="description">
                                Вы также можете ввести реквизиты в настройках вашего личного кабинета.
                            </div>
                            <PaymentDetails
                                initialValues={profilePaymentInfo}
                                closePaymentPopup={this.closePaymentPopup}
                                bloggerInterface={bloggerInterface}
                            />
                        </div>
                    </DialogComponent>
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        chennel: state.profile.channel,
        profilePaymentInfo: state.profile.profile_data.profilePaymentInfo,
        id: state.profile.id,
        errorSnack: state.app.errorSnack,
        errorSnackText: state.app.errorSnackText,
        successSnack: state.app.successSnack,
        successSnackText: state.app.successSnackText,
        openPaymentDetails: state.app.openPaymentDetails
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getProfile,
            closeErrorSnack,
            closeSuccessSnack,
            patchProfile,
            handlePaymentDialog,
            getAllNotifications
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);
