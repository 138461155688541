import React from "react";

import PaperIcon from "../../../assets/image/paper.svg";

import "./DocumentsBlock.scss";

const DocumentsBlock = ({ documents }) => {
    return (
        <div className="documents_block">
            <div className="title">Документы</div>
            <div className="documents">
                {documents.map(({ file, name }) => (
                    <a download href={file} key={name}>
                        <img src={PaperIcon} alt="document" />
                        {decodeURIComponent(name)}
                    </a>
                ))}
            </div>
        </div>
    );
};

export default DocumentsBlock;
