import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import iconOk from "../../../assets/image/ok_icons.png";
import RenderCheckField from "../../HelperComponents/RenderField/RenderCheckField/RenderCheckField";
import { patchBloggersAbout } from "../../../actions/bloggerAction";
import MultiSelectComponent from "../../HelperComponents/MultiSelectComponent/MultiSelectComponent";

import "./AboutChannelBlogger.scss";
import moment from "moment";

export const start_categories = [
    "Авто и транспорт",
    "Анимация",
    "Аниме и комиксы",
    "Архитектура",
    "Бизнес",
    "Военная тематика",
    "Гаджеты",
    "Детское",
    "Диджитал и IT",
    "Для взрослых {18+)",
    "Здоровье",
    "Игры",
    "Искусство и фото",
    "Красота и мода",
    "Криптовалюты и блокчейн",
    "Кулинария",
    "Личная страница",
    "Люди и блоги",
    "Маркетинг, реклама, PR",
    "Медицина",
    "Музыка",
    "Мультфильмы",
    "Наука и технологии",
    "Новости",
    "Новости и политика",
    "Обзоры",
    "Образование",
    "Общество",
    "Природа и животные",
    "Путешествия",
    "Развлечения",
    "Ремонт и строительство",
    "Семейное",
    "Социальное",
    "Спорт",
    "Фильмы и сериалы",
    "Хобби и увлечения",
    "Хэндмейд и DIY",
    "Юмор",
    "Другое"
];

export const start_genres = [
    "DIY",
    "Влоги",
    "Десятичасовые видео",
    "Лайфхаки",
    "Обзор",
    "Переозвучка",
    "Пранки",
    "Прохождение",
    "Разговорные",
    "Распаковки",
    "Скетчи",
    "Стримы",
    "Тест-драйв",
    "Туториал",
    "Челенджи",
    "Шоу",
    "Эксперименты",
    "Другое"
];

class AboutChannelBlogger extends Component {
    state = {
        open: false,
        categories: [],
        shownCats: ["nope"],
        categories_numbers: [],
        genres: [],
        shownGens: ["nope"],
        genres_numbers: []
    };

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    toggleDialog = () => {
        this.setState(({ open }) => ({
            open: !open
        }));
    };

    submitForm = data => {
        const {
            patchBloggersAbout,
            aboutTabContent: { categories, genres }
        } = this.props;
        const { categories_numbers, genres_numbers, shownCats, shownGens } = this.state;
        let request = {
            language_en: false,
            language_ru: false
        };

        if (data.language_en) request.language_en = data.language_en;
        if (data.language_ru) request.language_ru = data.language_ru;
        request.categories = shownCats[0] !== "nope" ? categories_numbers : this.writeCatNumber(categories, true);
        request.genres = shownGens[0] !== "nope" ? genres_numbers : this.writeGenNumber(genres, true);

        patchBloggersAbout(request).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
            }
        });
    };

    handleCatChange = event => {
        this.setState(({ categories, shownCats }) => ({
            categories: event.target.value,
            shownCats: event.target.value
        }));
        this.timeout = setTimeout(() => {
            this.writeCatNumber(this.state.categories);
        }, 0);
    };

    writeCatNumber = (cats, out) => {
        let newArr = [];

        for (let i = 0; i < cats.length; i++) {
            if (start_categories.indexOf(cats[i]) !== -1) {
                newArr.push(start_categories.indexOf(cats[i]) + 1);
            }
        }
        if (!out) {
            this.setState({ categories_numbers: newArr });
        } else {
            return newArr;
        }
    };

    handleGenChange = event => {
        this.setState({
            genres: event.target.value,
            shownGens: event.target.value
        });
        this.timeout = setTimeout(() => {
            this.writeGenNumber(this.state.genres);
        }, 0);
    };

    writeGenNumber = (gens, out) => {
        let newArr = [];

        for (let i = 0; i < gens.length; i++) {
            if (start_genres.indexOf(gens[i]) !== -1) {
                newArr.push(start_genres.indexOf(gens[i]) + 1);
            }
        }
        if (!out) {
            this.setState({ genres_numbers: newArr });
        } else {
            return newArr;
        }
    };

    render() {
        const { open, shownCats, shownGens } = this.state;
        const {
            handleSubmit,
            aboutTabContent: {
                categories,
                genres,
                registration_date,
                author_content,
                last_deal,
                non_problem,
                completed
            }
        } = this.props;
        console.log(completed);
        return (
            <div className="about_channel_blogger_wrapper">
                <form className="block_info_blogger" onSubmit={handleSubmit(this.submitForm)}>
                    <div className="block_wrapper_bg channel_info_wrapper">
                        <div className="title">Информация о канале</div>
                        <div className="wrapper_info_channel">
                            <div>
                                <div>
                                    <span className="descriptions">Язык канала</span>
                                    <div className="wrapper_checkbox">
                                        <div className="checkbox">
                                            <label>
                                                <Field
                                                    name="language_ru"
                                                    value="ru"
                                                    component={RenderCheckField}
                                                    type="checkbox"
                                                    className="green_checkbox"
                                                />
                                                <span />
                                                Русский
                                            </label>
                                        </div>
                                        <div className="checkbox">
                                            <label>
                                                <Field
                                                    name="language_en"
                                                    value="en"
                                                    component={RenderCheckField}
                                                    type="checkbox"
                                                    className="green_checkbox"
                                                />
                                                <span />
                                                Английский
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <MultiSelectComponent
                                    item={shownCats[0] !== "nope" ? shownCats : categories}
                                    items={start_categories}
                                    handleChange={this.handleCatChange}
                                    maxItems={2}
                                    label={"Категория"}
                                    placeholder={"Выберите категории"}
                                />
                                <MultiSelectComponent
                                    item={shownGens[0] !== "nope" ? shownGens : genres}
                                    items={start_genres}
                                    handleChange={this.handleGenChange}
                                    maxItems={2}
                                    label={"Жанры"}
                                    placeholder={"Выберите жанры"}
                                />
                            </div>
                            <div>
                                <div>
                                    <span className="descriptions">Авторский контент</span>
                                    <p className="text_green">{author_content}</p>
                                </div>
                                <div>
                                    <span className="descriptions">Дата регистрации канала</span>
                                    <p>{moment(registration_date).format("DD.MM.YYYY")}</p>
                                </div>
                                <div></div>
                            </div>
                        </div>
                        <div className="wrapper_btn">
                            <button className="green_btn" onClick={this.toggleDialog}>
                                Сохранить
                            </button>
                        </div>
                    </div>
                </form>
                <div className="block_wrapper_bg transaction_statistics">
                    <div className="title">Статистика сделок</div>
                    <div className="transaction_statistics_info">
                        <div>
                            <div>Завершенные</div>
                            <span>{completed > 0 ? completed : "-"}</span>
                        </div>
                        <div>
                            <div>Последняя сделка</div>
                            <span>{last_deal ? moment(last_deal).format("DD.MM.YYYY") : "-"}</span>
                        </div>
                        {/* <div>
                            <div>Беспроблемность</div>
                            <span className="text_green">{non_problem}</span>
                        </div> */}
                    </div>
                </div>
                <DialogComponent open={open} onClose={this.toggleDialog}>
                    <div className="changes_dialog">
                        <img src={iconOk} alt="iconOk" />
                        <div className="title">Изменения успешно сохранены</div>
                        <div className="btn_wrapper">
                            <button className="green_btn" onClick={this.toggleDialog}>
                                Ок
                            </button>
                        </div>
                    </div>
                </DialogComponent>
            </div>
        );
    }
}

AboutChannelBlogger = reduxForm({
    form: "AboutChannelBlogger"
})(AboutChannelBlogger);

function mapStateToProps(state, props) {
    return {
        initialValues: {
            language_ru: props.aboutTabContent.language_ru,
            language_en: props.aboutTabContent.language_en
        }
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            patchBloggersAbout
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutChannelBlogger);
