import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";

import TransitionedBlock from "../../../HelperComponents/TransitionedBlock/TransitionedBlock";
import Loader from "../../../HelperComponents/Loader/Loader";
import RenderField_pass from "../../../HelperComponents/RenderField/RenderField_pass";

import { postChangePassword } from "../../../../actions/authActions";

class ThirdStep extends Component {
    state = {
        loading: false
    };

    componentWillMount() {
        this.props.auth.error_auth = {};
    }

    submitForm = (data) => {
        const {
            postChangePassword,
            location: { search },
            history
        } = this.props;
        let params = new URLSearchParams(search.substring(1));
        let obj = {
            user_id: params.get("user_id"),
            code: params.get("code"),
            security_token: params.get("security_token"),
            password: data.password,
            password_check: data.password_check
        };
        this.setState({ loading: true });
        setTimeout(() => {
            return postChangePassword(obj).then((res) => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    history.push("/auth/password-recovery/fourth-step");
                } else {
                    this.setState({ loading: false });
                }
            });
        }, 500);
    };

    render() {
        const {
            handleSubmit,
            submitting,
            pristine,
            valid,
            auth: { error_auth }
        } = this.props;
        const { loading } = this.state;

        return (
            <TransitionedBlock>
                <div className="authentication-form">
                    <div className="title_form">Восстановление пароля</div>
                    <div className="authentication-content">
                        <form className="form_auth" onSubmit={handleSubmit(this.submitForm)}>
                            <div className="text_info">Введите и подтвердите новый пароль</div>
                            <Field
                                name="password"
                                className="phone-input"
                                type="password"
                                component={RenderField_pass}
                                placeholder="Новый пароль"
                                autoComplete="off"
                            />
                            <Field
                                name="password_check"
                                className="phone-input"
                                type="password"
                                component={RenderField_pass}
                                placeholder="Подтвердите пароль"
                                autoComplete="off"
                            />
                            <button className="green_btn_big" type="submit" disabled={submitting || pristine || !valid}>
                                {loading ? (
                                    <Loader height={20} width={2} margin="2px" color={"#FFF"} />
                                ) : (
                                    "Сменить пароль"
                                )}
                            </button>
                            {error_auth && Object.keys(error_auth).length !== 0 ? (
                                <div className="back_error">
                                    <span>{error_auth[Object.keys(error_auth)[0]]}</span>
                                </div>
                            ) : null}
                        </form>
                    </div>
                    <div className="info_aut">
                        <span>Вспомнили пароль?</span>
                        <Link to={`/auth`}>Войти</Link>
                    </div>
                </div>
            </TransitionedBlock>
        );
    }
}

export const validate = (values) => {
    const errors = {};
    if (!values.password) {
        errors.password = "Обязательное поле";
    } else if (values.password.length < 8) {
        errors.password = "Должно быть 8 символов или больше";
    }
    if (values.password !== values.password_check) {
        errors.password_check = "Пароли должны совпадать";
    }
    return errors;
};

ThirdStep = reduxForm({
    form: "ThirdStep",
    validate
})(ThirdStep);

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            postChangePassword
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdStep);
