import React, { Component } from "react";

import TransitionedBlock from "../../../HelperComponents/TransitionedBlock/TransitionedBlock";

import mail_large from "../../../../assets/image/mail_large.svg";

class SecondStep extends Component {
    render() {
        return (
            <TransitionedBlock>
                <div className="authentication-form">
                    <div className="title_form">Восстановление пароля</div>
                    <div className="authentication-content">
                        <div className="form_auth">
                            <div className="text_info">
                                Мы отправили письмо со ссылкой для смены пароля на указанный Вами электронный адрес.
                            </div>
                            <div className="box_icon_aut">
                                <img src={mail_large} alt="mail_large" />
                            </div>
                        </div>
                    </div>
                    <div className="info_aut" />
                </div>
            </TransitionedBlock>
        );
    }
}

export default SecondStep;
