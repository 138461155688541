import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { offerCheck } from "../../actions/appActions";
import "./OfferForm.scss";

const OfferForm = ({ handleOfferPopup }) => {
    const dispatch = useDispatch();
    const {
        handleSubmit,
        register,
        formState: { isDirty, isValid }
    } = useForm({
        mode: "onChange",
        defaultValues: {
            acquainted_offer: false
        }
    });

    const submitOffer = data => {
        dispatch(offerCheck(data));
    };

    return (
        <form className="offer_form" onSubmit={handleSubmit(submitOffer)}>
            <div className="default_dialog">
                <div className="title">Обновление оферты</div>
                <div className="description">
                    Пожалуйста, ознакомьтесь с последней версией правил использования платформы:
                </div>

                <a className="offer_link" href="https://imast.info/main/public-offer">
                    https://imast.info/main/public-offer
                </a>
            </div>
            <div className="col col-checkbox ">
                <div className="checkbox">
                    <label className="offer_label">
                        <input {...register("acquainted_offer", { required: true })} type="checkbox" /> <span /> С
                        изменениями ознакомлен(а)
                    </label>
                </div>
            </div>
            <hr />
            <div className="btn_wrapper">
                <button
                    onClick={() => handleOfferPopup()}
                    type="submit"
                    className={`button${!isDirty || !isValid ? " btn-disabled" : ""}`}
                    disabled={!isDirty || !isValid}
                >
                    ОК
                </button>
            </div>
        </form>
    );
};

export default OfferForm;
