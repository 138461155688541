import React, { Component, Fragment } from "react";
import "./ProfileAdvertiser.scss";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import Ok_icons from "../../assets/image/ok_icons.png";
import timer from "../../assets/image/timer.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    getProfile,
    patchProfile,
    patchProfileChangePassword,
    resetErrorPassword,
    patchPaymentInformation
} from "../../actions/profileActions";
import clsx from "clsx";

const classes_def = {
    root: "default_dialog_root",
    paper: "paper_custom_dialog profile-dialog"
};

class ProfileAdvertiser extends Component {
    state = {
        modalChangePass: false,
        succesDataModal: false,
        successChangePass: false,
        bloggerInterface: localStorage.getItem("bloggerInterface") === "blogger",
        userData: {
            first_name: "",
            last_name: "",
            phone_number: "",
            company: "",
            email: "",
            email_notifications: false,
            payment_information: {
                payment_account: "",
                bank_code: "",
                inn: "",
                kpp: "",
                organisation_name: "",
                recipient: "",
                bank_name: "",
                correspondent_account: "",
                first_name: "",
                middle_name: "",
                last_name: "",
                type: ""
            }
        },
        dataPassword: {
            password: "",
            old_password: "",
            password_check: ""
        }
    };

    resetPassword() {
        this.setState({
            dataPassword: {
                password: "",
                old_password: "",
                password_check: ""
            }
        });
    }

    componentDidMount() {
        const { getProfile } = this.props;
        const { bloggerInterface } = this.state;
        getProfile().then((res) => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                const {
                    first_name,
                    last_name,
                    phone_number,
                    company,
                    email,
                    email_notifications,
                    payment_information
                } = res.payload.data;
                let paymentInformation = {
                    payment_account: this.isPropertyNull(payment_information.payment_account),
                    bank_code: this.isPropertyNull(payment_information.bank_code),
                    inn: this.isPropertyNull(payment_information.inn),
                    bank_name: this.isPropertyNull(payment_information.bank_name),
                    correspondent_account: this.isPropertyNull(payment_information.correspondent_account),
                    type: this.isPropertyNull(payment_information.type)
                };
                if (bloggerInterface) {
                    paymentInformation = {
                        ...paymentInformation,
                        recipient: this.isPropertyNull(payment_information.recipient)
                    };
                } else {
                    paymentInformation = {
                        ...paymentInformation,
                        kpp: this.isPropertyNull(payment_information.kpp),
                        organisation_name: this.isPropertyNull(payment_information.organisation_name),
                        first_name: this.isPropertyNull(payment_information.first_name),
                        middle_name: this.isPropertyNull(payment_information.middle_name),
                        last_name: this.isPropertyNull(payment_information.last_name)
                    };
                }
                this.setState({
                    userData: {
                        first_name: this.isPropertyNull(first_name),
                        last_name: this.isPropertyNull(last_name),
                        phone_number: this.isPropertyNull(phone_number),
                        company: this.isPropertyNull(company),
                        email: this.isPropertyNull(email),
                        email_notifications: this.isPropertyNull(email_notifications),
                        payment_information: paymentInformation
                    }
                });
            }
        });
    }

    isPropertyNull(value) {
        return value === null ? "" : value;
    }

    handleInputChangeProfile = (event) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        console.log(target);

        if (
            name === "payment_account" ||
            name === "bank_code" ||
            name === "kpp" ||
            name === "organisation_name" ||
            name === "inn" ||
            name === "recipient" ||
            name === "bank_name" ||
            name === "correspondent_account" ||
            name === "middle_name" 
        ) {
            this.setState({
                userData: {
                    ...this.state.userData,
                    payment_information: {
                        ...this.state.userData.payment_information,
                        [name]: value
                    }
                }
            });
        } else {
            this.setState({
                userData: {
                    ...this.state.userData,
                    [name]: value
                }
            });
        }
    };

    handleInputChangePassword = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            dataPassword: {
                ...this.state.dataPassword,
                [name]: value
            }
        });
    };

    profileDataSend = () => {
        const { patchProfile, patchPaymentInformation } = this.props;
        const { userData, bloggerInterface } = this.state;
        let submitPaymentInfo;
        patchProfile(userData).then((res) => {
            if (res.payload) {
                if (bloggerInterface) {
                    submitPaymentInfo =
                        !!userData.payment_information.payment_account ||
                        !!userData.payment_information.bank_code ||
                        !!userData.payment_information.recipient ||
                        !!userData.payment_information.inn ||
                        !!userData.payment_information.bank_name ||
                        !!userData.payment_information.correspondent_account;
                } else {
                    submitPaymentInfo =
                        !!userData.payment_information.payment_account ||
                        !!userData.payment_information.bank_code ||
                        !!userData.payment_information.kpp ||
                        !!userData.payment_information.organisation_name ||
                        !!userData.payment_information.inn ||
                        !!userData.payment_information.bank_name ||
                        !!userData.payment_information.correspondent_account ||
                        !!userData.payment_information.first_name ||
                        !!userData.payment_information.middle_name ||
                        !!userData.payment_information.last_name;
                }
                if (submitPaymentInfo) {
                    patchPaymentInformation(userData.payment_information).then((response) => {
                        if (response.payload && response.payload.status && response.payload.status === 200) {
                            this.controlModal("succesDataModal");
                        }
                    });
                } else {
                    this.controlModal("succesDataModal");
                }
            }
        });
    };

    passwordSend = () => {
        const { patchProfileChangePassword } = this.props;
        patchProfileChangePassword(this.state.dataPassword).then((res) => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                localStorage.token = res.payload.data.token;
                this.controlModal("successChangePass");
            }
        });
    };

    controlModal = (data) => {
        switch (data) {
            case "modalChangePass":
                this.setState(
                    ({ modalChangePass }) => ({
                        modalChangePass: !modalChangePass
                    }),
                    () => {
                        this.resetPassword();
                        this.props.resetErrorPassword();
                    }
                );
                break;
            case "succesDataModal":
                this.setState(({ succesDataModal }) => ({
                    succesDataModal: !succesDataModal
                }));
                break;
            case "successChangePass":
                this.setState(({ successChangePass, modalChangePass }) => ({
                    successChangePass: !successChangePass,
                    modalChangePass: false
                }));
                break;
            default:
                break;
        }
    };

    showErrorPass(error_pas) {
        if (error_pas) {
            if (Array.isArray(error_pas)) {
                return error_pas.map((item, index) => {
                    return (
                        <div key={index} className="back_error">
                            <span>{item}</span>
                        </div>
                    );
                });
            } else {
                return (
                    <div className="back_error">
                        <span>{error_pas}</span>
                    </div>
                );
            }
        }
        return null;
    }

    handlerKeyDown(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    }

    renderApprovingBlock = () => {
        return (
            <div className="approving_block">
                <img src={timer} alt="timer" />
                Реквизиты на рассмотрении
            </div>
        );
    };

    renderBloggerFields = () => {
        const {
            payment_information: { payment_account, bank_code, inn, recipient, bank_name, correspondent_account }
        } = this.state.userData;
        const { profilePaymentInfo } = this.props.profile.profile_data;
        const { error_profile } = this.props.profile;
        let showApprovingBlock = !!profilePaymentInfo.payment_account && !profilePaymentInfo.approved;
        return (
            <div>
                <p>Платёжные данные</p>
                {showApprovingBlock && this.renderApprovingBlock()}
                <div className={clsx("row", showApprovingBlock && "inactive_fields")}>
                    <div className="col">
                        <label>Номер счёта</label>
                        <input
                            type="text"
                            value={payment_account}
                            name="payment_account"
                            placeholder="Введите номер счёта"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile && error_profile.payment_information && error_profile.payment_information.payment_account && (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        )}
                    </div>
                    <div className="col">
                        <label>Наименование банка</label>
                        <input
                            type="text"
                            value={bank_name}
                            name="bank_name"
                            placeholder="Введите наименование банка"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile.payment_information && error_profile.payment_information.bank_name && (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className={clsx("row", showApprovingBlock && "inactive_fields")}>
                    <div className="col">
                        <label>БИК банка</label>
                        <input
                            type="text"
                            value={bank_code}
                            name="bank_code"
                            placeholder="Введите БИК банка"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile.payment_information && error_profile.payment_information.bank_code && (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        )}
                    </div>
                    <div className="col">
                        <label>Корреспондентский счёт</label>
                        <input
                            type="text"
                            value={correspondent_account}
                            name="correspondent_account"
                            placeholder="Введите корреспондентский счёт"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile.payment_information && error_profile.payment_information.correspondent_account && (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className={clsx("row", showApprovingBlock && "inactive_fields")}>
                    <div className="col">
                        <label>Получатель</label>
                        <input
                            type="text"
                            value={recipient}
                            name="recipient"
                            placeholder="Введите имя получателя"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile.payment_information && error_profile.payment_information.recipient && (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        )}
                    </div>
                    <div className="col">
                        <label>ИНН</label>
                        <input
                            type="text"
                            value={inn}
                            name="inn"
                            placeholder="Введите ИНН"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile.payment_information &&
                        error_profile.payment_information.inn &&
                        error_profile.payment_information.inn[0] !== "This field may not be blank." ? (
                            this.showErrorPass(error_profile.payment_information.inn)
                        ) : error_profile.payment_information && error_profile.payment_information.inn ? (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        );
    };

    renderAdvertiserULFields = () => {
        const {
            payment_information: {
                payment_account,
                bank_code,
                bank_name,
                kpp,
                organisation_name,
                inn,
                correspondent_account
            }
        } = this.state.userData;
        const { profilePaymentInfo } = this.props.profile.profile_data;
        const { error_profile } = this.props.profile;
        let showApprovingBlock = !!profilePaymentInfo.payment_account && !profilePaymentInfo.approved;
        return (
            <div>
                <p>Платёжные данные</p>
                {showApprovingBlock && this.renderApprovingBlock()}
                <div className={clsx("row", showApprovingBlock && "inactive_fields")}>
                    <div className="col">
                        <label>Номер счёта</label>
                        <input
                            type="text"
                            value={payment_account}
                            name="payment_account"
                            placeholder="Введите номер счёта"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile && error_profile.payment_information && error_profile.payment_information.payment_account && (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        )}
                    </div>
                    <div className="col">
                        <label>Наименование банка</label>
                        <input
                            type="text"
                            value={bank_name}
                            name="bank_name"
                            placeholder="Введите наименование банка"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile.payment_information && error_profile.payment_information.bank_name && (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className={clsx("row", showApprovingBlock && "inactive_fields")}>
                    <div className="col">
                        <label>КПП</label>
                        <input
                            type="text"
                            value={kpp}
                            name="kpp"
                            placeholder="Введите КПП"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile.payment_information && error_profile.payment_information.kpp && (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        )}
                    </div>
                    <div className="col">
                        <label>БИК банка</label>
                        <input
                            type="text"
                            value={bank_code}
                            name="bank_code"
                            placeholder="Введите БИК банка"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile.payment_information && error_profile.payment_information.bank_code && (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className={clsx("row", showApprovingBlock && "inactive_fields")}>
                    <div className="row">
                        <div className="col">
                            <label>Корреспондентский счёт</label>
                            <input
                                type="text"
                                value={correspondent_account}
                                name="correspondent_account"
                                placeholder="Введите корреспондентский счёт"
                                onChange={this.handleInputChangeProfile}
                                onKeyDown={this.handlerKeyDown}
                            />
                            {error_profile.payment_information &&
                                error_profile.payment_information.correspondent_account && (
                                    <div className="back_error">
                                        <span>Обязательное поле</span>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                <div className={clsx("row", showApprovingBlock && "inactive_fields")}>
                    <div className="col">
                        <label>Наименование организации</label>
                        <input
                            type="text"
                            value={organisation_name}
                            name="organisation_name"
                            placeholder="Введите наименование организации"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile.payment_information && error_profile.payment_information.organisation_name && (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        )}
                    </div>
                    <div className="col">
                        <label>ИНН</label>
                        <input
                            type="text"
                            value={inn}
                            name="inn"
                            placeholder="Введите ИНН"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile.payment_information &&
                        error_profile.payment_information.inn &&
                        error_profile.payment_information.inn[0] !== "This field may not be blank." ? (
                            this.showErrorPass(error_profile.payment_information.inn)
                        ) : error_profile.payment_information && error_profile.payment_information.inn ? (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        );
    };

    renderAdvertiserIPFields = () => {
        const {
            payment_information: {
                payment_account,
                bank_code,
                bank_name,
                inn,
                correspondent_account,
                first_name,
                middle_name,
                last_name
            }
        } = this.state.userData;
        const { profilePaymentInfo } = this.props.profile.profile_data;
        const { error_profile } = this.props.profile;
        let showApprovingBlock = !!profilePaymentInfo.payment_account && !profilePaymentInfo.approved;
        return (
            <div>
                <p>Платёжные данные</p>
                {showApprovingBlock && this.renderApprovingBlock()}
                <div className={clsx("row", showApprovingBlock && "inactive_fields")}>
                    <div className="col">
                        <label>Номер счёта</label>
                        <input
                            type="text"
                            value={payment_account}
                            name="payment_account"
                            placeholder="Введите номер счёта"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile && error_profile.payment_information && error_profile.payment_information.payment_account && (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        )}
                    </div>
                    <div className="col">
                        <label>Наименование банка</label>
                        <input
                            type="text"
                            value={bank_name}
                            name="bank_name"
                            placeholder="Введите наименование банка"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile.payment_information && error_profile.payment_information.bank_name && (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className={clsx("row", showApprovingBlock && "inactive_fields")}>
                    <div className="col">
                        <label>БИК банка</label>
                        <input
                            type="text"
                            value={bank_code}
                            name="bank_code"
                            placeholder="Введите БИК банка"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile.payment_information && error_profile.payment_information.bank_code && (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        )}
                    </div>
                    <div className="col">
                        <label>Корреспондентский счёт</label>
                        <input
                            type="text"
                            value={correspondent_account}
                            name="correspondent_account"
                            placeholder="Введите корреспондентский счёт"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile.payment_information && error_profile.payment_information.correspondent_account && (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className={clsx("row", showApprovingBlock && "inactive_fields")}>
                    <div className="col">
                        <label>Фамилия получателя</label>
                        <input
                            type="text"
                            value={last_name}
                            name="last_name"
                            placeholder="Введите фамилию получателя"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile.payment_information && error_profile.payment_information.last_name && (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        )}
                    </div>
                    <div className="col">
                        <label>Имя получателя</label>
                        <input
                            type="text"
                            value={first_name}
                            name="first_name"
                            placeholder="Введите имя получателя"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile.payment_information && error_profile.payment_information.first_name && (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className={clsx("row", showApprovingBlock && "inactive_fields")}>
                    <div className="col">
                        <label>Отчество получателя</label>
                        <input
                            type="text"
                            value={middle_name}
                            name="middle_name"
                            placeholder="Введите отчество получателя"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile.payment_information && error_profile.payment_information.middle_name && (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        )}
                    </div>
                    <div className="col">
                        <label>ИНН</label>
                        <input
                            type="text"
                            value={inn}
                            name="inn"
                            placeholder="Введите ИНН"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                        {error_profile.payment_information &&
                        error_profile.payment_information.inn &&
                        error_profile.payment_information.inn[0] !== "This field may not be blank." ? (
                            this.showErrorPass(error_profile.payment_information.inn)
                        ) : error_profile.payment_information && error_profile.payment_information.inn ? (
                            <div className="back_error">
                                <span>Обязательное поле</span>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const {
            first_name,
            last_name,
            phone_number,
            company,
            email,
            email_notifications,
            payment_information: { type }
        } = this.state.userData;
        const { password, old_password, password_check } = this.state.dataPassword;
        const { error_password, error_profile } = this.props.profile;
        const { bloggerInterface } = this.state;
        const paymentFieldsToRender = bloggerInterface
            ? this.renderBloggerFields()
            : type === "UL"
            ? this.renderAdvertiserULFields()
            : this.renderAdvertiserIPFields();
        return (
            <div className="content_block_page  profile-advertiser">
                <h1>Настройки профиля</h1>
                <p>Общая информация</p>
                <form className="container-form">
                    <div className="row">
                        <div className="col">
                            <label>Имя</label>
                            <input
                                type="text"
                                value={first_name}
                                name="first_name"
                                onChange={this.handleInputChangeProfile}
                                placeholder="Введите свое имя"
                                onKeyDown={this.handlerKeyDown}
                            />
                            {this.showErrorPass(error_profile.first_name)}
                        </div>
                        <div className="col">
                            <label>Фамилия</label>
                            <input
                                type="text"
                                value={last_name}
                                name="last_name"
                                onChange={this.handleInputChangeProfile}
                                placeholder="Введите свою фамилию"
                                onKeyDown={this.handlerKeyDown}
                            />
                            {this.showErrorPass(error_profile.last_name)}
                        </div>
                    </div>
                    {!bloggerInterface ? (
                        <Fragment>
                            <div className="row">
                                <div className="col">
                                    <label>Телефон</label>
                                    <input
                                        type="text"
                                        value={phone_number}
                                        name="phone_number"
                                        onChange={this.handleInputChangeProfile}
                                        placeholder="Введите номер телефона"
                                        onKeyDown={this.handlerKeyDown}
                                    />
                                    {this.showErrorPass(error_profile.phone_number)}
                                </div>
                                <div className="col">
                                    <label>Компания</label>
                                    <input
                                        type="text"
                                        value={company}
                                        name="company"
                                        onChange={this.handleInputChangeProfile}
                                        placeholder="Введите название компании"
                                        onKeyDown={this.handlerKeyDown}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Электронный адрес</label>
                                    <input
                                        type="text"
                                        value={email}
                                        disabled={true}
                                        name="email"
                                        onChange={this.handleInputChangeProfile}
                                        onKeyDown={this.handlerKeyDown}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        <div className="row">
                            <div className="col">
                                <label>Электронный адрес</label>
                                <input
                                    type="text"
                                    value={email}
                                    disabled={true}
                                    name="email"
                                    onChange={this.handleInputChangeProfile}
                                    onKeyDown={this.handlerKeyDown}
                                />
                            </div>
                            <div className="col">
                                <label>Телефон</label>
                                <input
                                    type="text"
                                    value={phone_number}
                                    name="phone_number"
                                    onChange={this.handleInputChangeProfile}
                                    placeholder="Введите номер телефона"
                                    onKeyDown={this.handlerKeyDown}
                                />
                                {this.showErrorPass(error_profile.phone_number)}
                            </div>
                        </div>
                    )}
                    <div className="row margin_bottom_20">
                        <div className="col col-checkbox">
                            <input
                                type="checkbox"
                                id="check1"
                                className="inp-checkbox"
                                name="email_notifications"
                                onChange={this.handleInputChangeProfile}
                                checked={email_notifications}
                            />
                            <label htmlFor="check1">Получать уведомления на указанный электронный адрес</label>
                        </div>
                    </div>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            this.controlModal("modalChangePass");
                        }}
                        className="btn-change-password"
                    >
                        Сменить пароль
                    </button>
                    <hr />
                    {paymentFieldsToRender}
                    <hr />
                    <button
                        className="green_btn"
                        type="submit"
                        onClick={(e) => {
                            e.preventDefault();
                            this.profileDataSend();
                        }}
                    >
                        Сохранить
                    </button>
                </form>
                <DialogComponent
                    open={this.state.modalChangePass}
                    onClose={() => this.controlModal("modalChangePass")}
                    classes={classes_def}
                >
                    <div className="change-password">
                        <form action="" autoComplete="off">
                            <div className="row">
                                <h1>Сменить пароль</h1>
                                <div className="col">
                                    <label>Текущий пароль</label>
                                    <input
                                        type="password"
                                        value={old_password}
                                        name="old_password"
                                        placeholder="Введите текущий пароль"
                                        onChange={this.handleInputChangePassword}
                                    />
                                    {this.showErrorPass(error_password.old_password)}
                                </div>
                                <div className="col">
                                    <label>Новый пароль</label>
                                    <input
                                        type="password"
                                        value={password}
                                        name="password"
                                        placeholder="Введите новый пароль"
                                        onChange={this.handleInputChangePassword}
                                    />
                                    {this.showErrorPass(error_password.password)}
                                </div>
                                <div className="col">
                                    <label>Подтвердите пароль</label>
                                    <input
                                        type="password"
                                        value={password_check}
                                        name="password_check"
                                        placeholder="Подтвердите новый пароль"
                                        onChange={this.handleInputChangePassword}
                                    />
                                    {this.showErrorPass(error_password.password_check)}
                                </div>
                                <hr />
                                <div className="dialog-button-wrapper">
                                    <button
                                        className="cancel_btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.controlModal("modalChangePass");
                                        }}
                                    >
                                        Отмена
                                    </button>
                                    <button
                                        className="green_btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.passwordSend();
                                        }}
                                    >
                                        Сохранить
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </DialogComponent>
                <DialogComponent
                    open={this.state.successChangePass}
                    onClose={() => this.controlModal("successChangePass")}
                    classes={classes_def}
                >
                    <div className="success-change-password">
                        <img src={Ok_icons} alt="ok" />
                        <p>Ваш пароль успешно изменен</p>
                        <hr />
                        <button className="green_btn" onClick={() => this.controlModal("successChangePass")}>
                            ок
                        </button>
                    </div>
                </DialogComponent>
                <DialogComponent
                    open={this.state.succesDataModal}
                    onClose={() => this.controlModal("succesDataModal")}
                    classes={classes_def}
                >
                    <div className="success-change-password">
                        <img src={Ok_icons} alt="ok" />
                        <p>Изменения успешно сохранены</p>
                        <hr />
                        <button
                            className="green_btn"
                            onClick={(e) => {
                                e.preventDefault();
                                this.controlModal("succesDataModal");
                            }}
                        >
                            ок
                        </button>
                    </div>
                </DialogComponent>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        profile: state.profile,
        error_profile: state.error_profile,
        error_password: state.error_password
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getProfile,
            patchProfile,
            patchPaymentInformation,
            patchProfileChangePassword,
            resetErrorPassword
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAdvertiser);
