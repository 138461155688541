import { put, takeEvery, all } from "redux-saga/effects";
import { handleDealsActions, handleDealsLoader, handleDealsSnacks } from "./deals";
import { getProfile } from "../actions/profileActions";

export default function* rootSaga() {
    yield all([handleSnack(), handleAppActions(), handleDealsActions(), handleDealsLoader(), handleDealsSnacks()]);
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* handleAppActions() {
    yield takeEvery("PATCH_PROFILE_SUCCESS", fetchProfile);
    yield takeEvery("PATCH_PAYMENT_INFORMATION_SUCCESS", fetchProfile);
    yield takeEvery("GET_PRICE_TO_INBOX_SUCCESS", () => openSuccessSnack("Запрос отправлен"));
    // yield takeEvery("PATCH_PROFILE_FAIL", e => openErrorSnack(e, "double_inner"));
}

function* fetchProfile() {
    yield put(getProfile());
}

export function* openErrorSnack(e, depth) {
    yield put({
        type: "ERROR_SNACK_OPEN",
        payload:
            e && e.error && e.error.response && e.error.response.data
                ? e.error.response.data.error || Object.values(e.error.response.data)[0]
                : e.error && e.error.data
                ? e.error.data
                : e.error
                ? e.error
                : "Something went wrong"
    });
}

export function* openSuccessSnack(e) {
    yield put({ type: "SUCCESS_SNACK_OPEN", payload: e });
}

export function* clearSnackTexts() {
    yield delay(1000);
    yield put({ type: "SNACK_TEXTS_CLEARING" });
}

export function* handleSnack() {
    yield takeEvery("ERROR_SNACK_CLOSE", clearSnackTexts);
}

export function* enableLoader() {
    yield put({ type: "LOADING", payload: true });
}

export function* disableLoader() {
    yield put({ type: "LOADING", payload: false });
}

export function* enableButtonLoader() {
    yield put({ type: "BUTTON_LOADING", payload: true });
}

export function* disableButtonLoader() {
    yield put({ type: "BUTTON_LOADING", payload: false });
}
