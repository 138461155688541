import React from "react";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";

import "./MultiSelectComponent.scss";

const MultiSelectComponent = ({ items, item, handleChange, maxItems, placeholder, label }) => {
    return (
        <div className="wrapper_multiselect">
            <span className="descriptions">{label}</span>
            {item.length === 0 && <span className="select_placeholder">{placeholder}</span>}
            <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={item}
                onChange={handleChange}
                input={<Input />}
                renderValue={(selected) => {
                    if (selected.length <= maxItems) {
                        return selected.join(", ");
                    }
                    return `${selected[0]}, ${selected[1]} + еще ${selected.length - 2}`;
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
            >
                {items
                    .filter((value) => item.indexOf(value) > -1)
                    .map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={item.indexOf(name) > -1}>
                                <span />
                            </Checkbox>

                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                {items.filter((value) => item.indexOf(value) > -1).length > 0 && <div className="line_check" />}
                {items
                    .filter((value) => item.indexOf(value) === -1)
                    .map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={item.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
            </Select>
        </div>
    );
};

export default MultiSelectComponent;
