import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import FormControl from "@material-ui/core/FormControl";
import SelectComponent from "../../HelperComponents/SelectComponent/SelectComponent";
import RenderCheckField from "../../HelperComponents/RenderField/RenderCheckField/RenderCheckField";

import { renderField, renderTextarea } from "../CampaingPage";
import { regExpNumber } from "../../../helpers/validation";

import "./Tasks.scss";

class EditTaskDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            advertise_options: [
                {
                    label: (
                        <div className="status_select">
                            <div>в начале</div>
                        </div>
                    ),
                    value: "preroll"
                },
                {
                    label: (
                        <div className="status_select">
                            <div>в середине</div>
                        </div>
                    ),
                    value: "middle"
                },
                {
                    label: (
                        <div className="status_select">
                            <div>в конце</div>
                        </div>
                    ),
                    value: "postroll"
                }
            ],
            advertise_type: {
                label: (
                    <div className="status_select">
                        <div>в начале</div>
                    </div>
                ),
                value: "preroll"
            }
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.item && nextProps.item.task_type) {
            if (
                nextProps.item !== this.props.item ||
                (this.props.item && this.props.item.task_type && nextProps.item.task_type !== this.props.item.task_type)
            ) {
                this.setState({
                    advertise_type: {
                        label: (
                            <div className="status_select">
                                <div>
                                    {nextProps.item &&
                                    nextProps.item.task_type &&
                                    nextProps.item.task_type === "В середине видео"
                                        ? "в середине"
                                        : nextProps.item.task_type === "В конце видео"
                                        ? "в конце"
                                        : "в начале"}
                                </div>
                            </div>
                        ),
                        value:
                            nextProps.item && nextProps.item.task_type === "в середине"
                                ? "middle"
                                : nextProps.item && nextProps.item.task_type === "в конце"
                                ? "postroll"
                                : "preroll"
                    }
                });
            }
        }
    }

    changeAdvertiseOptions = (event) => {
        this.setState({ advertise_type: event });
    };

    render() {
        const {
            item,
            handleSubmit,
            valid,
            submitting,
            backError,
            openEditDialog,
            toggleEditDialog,
            submitForm
        } = this.props;
        const { advertise_type, advertise_options } = this.state;

        if (!item) return null;
        return (
            <DialogComponent open={openEditDialog} onClose={toggleEditDialog}>
                <div className="create_task">
                    <div className="title">Изменить задание</div>
                    <form className="form_task" onSubmit={handleSubmit((data) => submitForm(data, advertise_type))}>
                        <div className="label_box">
                            <span>Рекламная ссылка</span>
                            <Field
                                name="ad_link"
                                component={renderField}
                                placeholder="http://www.example.com"
                                autoComplete="off"
                                type="text"
                                className="link-textarea"
                            />
                            {backError && backError.ad_link && <span className="error">{backError.ad_link}</span>}
                        </div>
                        {(item.task_type === "В начале видео" ||
                            item.task_type === "В середине видео" ||
                            item.task_type === "В конце видео") && (
                            <div className="label_box">
                                <span>Реклама в видео</span>
                                <FormControl className="select_wrapper">
                                    <SelectComponent
                                        value={advertise_type}
                                        options={advertise_options}
                                        change={this.changeAdvertiseOptions}
                                        isClearable="false"
                                        isSearchable={false}
                                    />
                                </FormControl>
                            </div>
                        )}
                        <div className="label_box">
                            <span>Описание задания</span>
                            <Field
                                name="task_text"
                                component={renderTextarea}
                                placeholder="Опишите пожелания и требования к рекламе"
                                autoComplete="off"
                            />
                            {backError && backError.task_text && <span className="error">{backError.task_text}</span>}
                        </div>
                        <div className="value_info">
                            <span>Бюджет</span>
                            <Field
                                name="budget"
                                component={renderField}
                                placeholder="0"
                                autoComplete="off"
                                type="text"
                            />
                            {backError && backError.budget && <span className="error">{backError.budget}</span>}
                            <p>руб.</p>
                        </div>
                        {/*<div className="col col-checkbox first_block">*/}
                        {/*    <div className="checkbox">*/}
                        {/*        <label>*/}
                        {/*            <Field*/}
                        {/*                name="preview"*/}
                        {/*                component={RenderCheckField}*/}
                        {/*                type="checkbox"*/}
                        {/*                className="green_checkbox"*/}
                        {/*            />*/}
                        {/*            <span />*/}
                        {/*            Обязательный предпросмотр перед публикацией*/}
                        {/*        </label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="col col-checkbox ">
                            <div className="checkbox">
                                <label>
                                    <Field
                                        name="public_view"
                                        component={RenderCheckField}
                                        type="checkbox"
                                        className="green_checkbox"
                                    />
                                    <span />
                                    Сделать задание публичным (задание будут видеть все блогеры, которые смогут
                                    отправлять заявки на выполнение задания)
                                </label>
                            </div>
                        </div>
                        <div className="btn_wrapper">
                            <button
                                className="cancel_btn"
                                onClick={(e) => {
                                    e.preventDefault();
                                    toggleEditDialog();
                                }}
                            >
                                Назад
                            </button>
                            <button className="green_btn" disabled={submitting || !valid}>
                                Сохранить
                            </button>
                        </div>
                    </form>
                </div>
            </DialogComponent>
        );
    }
}

const validate = (values) => {
    const errors = {};
    if (!values.budget) {
        errors.budget = "Обязательное поле";
    } else if (!values.budget.toString().match(regExpNumber)) {
        errors.budget = "Некорректное значение";
    }
    if (!values.task_text) {
        errors.task_text = "Обязательное поле";
    }
    return errors;
};

EditTaskDialog = reduxForm({
    form: "EditTaskDialog",
    validate,
    enableReinitialize: true
})(EditTaskDialog);

function mapStateToProps(state, props) {
    return {
        initialValues: {
            ad_link: props.item && props.item.ad_link ? props.item.ad_link : "",
            task_text: props.item && props.item.task_text ? props.item.task_text : "",
            budget: props.item && props.item.budget ? props.item.budget : "",
            preview: props.item && props.item.preview,
            public_view: props.item && props.item.public_view
        }
    };
}

export default connect(mapStateToProps)(EditTaskDialog);
