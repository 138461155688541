import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";

import Loader from "../../HelperComponents/Loader/Loader";
import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";
import renderField_mail from "../../HelperComponents/RenderField/renderField_mail";
import RenderField_pass from "../../HelperComponents/RenderField/RenderField_pass";

import { postLogin } from "../../../actions/authActions";

class SignIn extends Component {
    state = {
        loading: false
    };

    componentDidMount() {
        const { history } = this.props;
        if (localStorage.token) {
            history.push("/main");
        }
        // localStorage.clear();
        // sessionStorage.clear();
        this.props.auth.error_auth = {};
    }

    submitForm = (data) => {
        const { postLogin, history } = this.props;
        this.setState({ loading: true });
        let obj = { ...data };
        setTimeout(() => {
            postLogin(obj).then((res) => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    localStorage.token = res.payload.data.token;
                    history.push("/main");
                } else {
                    this.setState({ loading: false });
                }
            });
        }, 500);
    };

    render() {
        const {
            handleSubmit,
            submitting,
            pristine,
            valid,
            auth: { error_auth }
        } = this.props;
        const { loading } = this.state;

        return (
            <TransitionedBlock>
                <div className="authentication-form">
                    <div className="title_form">Вход в аккаунт</div>
                    <div className="authentication-content">
                        <form className="form_auth" onSubmit={handleSubmit(this.submitForm)}>
                            <Field
                                name="email"
                                className="phone-input"
                                type="text"
                                component={renderField_mail}
                                placeholder="Электронный адрес"
                                autoComplete="off"
                            />
                            <Field
                                name="password"
                                className="phone-input"
                                type="password"
                                component={RenderField_pass}
                                placeholder="Пароль"
                                autoComplete="off"
                            />
                            <Link className="forgot_pass" to="/auth/password-recovery/first-step">
                                Забыли пароль?
                            </Link>
                            <button className="green_btn_big" type="submit" disabled={submitting || pristine || !valid}>
                                {loading ? <Loader height={20} width={2} margin="2px" color={"#FFF"} /> : "Войти"}
                            </button>
                            {error_auth && Object.keys(error_auth).length !== 0 && (
                                <div className="back_error">
                                    <span>{error_auth[Object.keys(error_auth)[0]]}</span>
                                </div>
                            )}
                        </form>
                    </div>
                    <div className="info_aut">
                        <span>Еще нет аккаунта?</span>
                        <Link to={`/auth/sign-up`}>Зарегистрироваться</Link>
                    </div>
                    <div className="public_pages_links">
                        <Link to="/public/public-offer">Правила платформы</Link>
                        <Link to="/public/privacy-policy">Политика конфиденциальности</Link>
                    </div>
                </div>
            </TransitionedBlock>
        );
    }
}

export const validate = (values) => {
    const errors = {};
    if (!values.email) {
        errors.email = "Обязательное поле";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
        errors.email = "Неправильный email";
    }
    if (!values.password) {
        errors.password = "Обязательное поле";
    }
    return errors;
};

SignIn = reduxForm({
    form: "SignIn",
    validate
})(SignIn);

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            postLogin
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
