import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import GoogleLogin from "react-google-login";
import { withRouter } from "react-router";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";

import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent";
import SnackBar from "../HelperComponents/SnackBar/SnackBar";

import { getCampaigns, postCampaigns, resetErrorCampaigns } from "../../actions/campaignsActions";
import { googleAuth } from "../../actions/authActions";

import { getOption } from "../../helpers/functions";
import { dealsStatuses } from "../../helpers/constants";

import search_icon from "../../assets/image/search_icon 2.svg";
import female from "../../assets/image/female.svg";
import male from "../../assets/image/male.svg";
import add_btn from "../../assets/image/add_btn.svg";

import "./Panel.scss";

class Panel extends Component {
    state = {
        open: false,
        openNoYoutubeAccount: false,
        openSnack: false,
        gender: false,
        age_list: [
            { label: getOption("13-17"), value: "13-17" },
            { label: getOption("18-24"), value: "18-24" },
            { label: getOption("25-34"), value: "25-34" },
            { label: getOption("35-44"), value: "35-44" },
            { label: getOption("45-54"), value: "45-54" },
            { label: getOption("55-64"), value: "55-64" },
            { label: getOption("65+"), value: "65+" }
        ],
        campaign: {
            campaign_name: "",
            campaign_link: "",
            age_range: "",
            gender: "male",
            description: "",
            budget: ""
        },
        valid_data: {
            campaign_name_valid: false,
            age_range_valid: false,
            description_valid: false
        }
    };

    resetState() {
        this.setState({
            campaign: {
                campaign_name: "",
                campaign_link: "",
                age_range: "",
                gender: "male",
                description: "",
                budget: ""
            },
            valid_data: {
                campaign_name_valid: false,
                age_range_valid: false,
                description_valid: false
            }
        });
    }

    componentDidMount() {
        this.doRequest();
    }

    doRequest = () => {
        const { getCampaigns } = this.props;
        getCampaigns();
    };

    handleChangeAge = event => {
        this.setState({
            campaign: {
                ...this.state.campaign,
                age_range: event.value
            }
        });
    };

    choiceGender = value => {
        const select_gender = value ? "female" : "male";

        this.setState({
            gender: value,
            campaign: {
                ...this.state.campaign,
                gender: select_gender
            }
        });
    };

    toggleDialog = () => {
        if (this.state.open) {
            this.resetState();
            this.props.resetErrorCampaigns();
        }

        this.setState(({ open }) => ({ open: !open }));
    };

    handleInputChangeCampaign = event => {
        const target = event.target ? event.target : event;
        let value = target.value;
        const name = target.name;

        this.setState({
            campaign: {
                ...this.state.campaign,
                [name]: value
            }
        });
    };

    handleInputChangeBudget = event => {
        let value = event.target.value;

        if (value === "") {
            this.setState({
                campaign: {
                    ...this.state.campaign,
                    budget: ""
                }
            });
        } else {
            value = parseInt(value);
            if (isFinite(value)) {
                this.setState({
                    campaign: {
                        ...this.state.campaign,
                        budget: value
                    }
                });
            }
        }
    };

    sendDataCampaign = () => {
        // this.props.postCampaigns({
        //     campaign_name: "",
        //     campaign_link: "",
        //     age_range: "",
        //     gender: "",
        //     description: "",
        //     budget: ""
        // })
        const { campaign_name, campaign_link, age_range, gender, description, budget } = this.state.campaign;

        let data = {
            campaign_name: campaign_name,
            campaign_link: this.url_edit(campaign_link),
            age_range: age_range,
            gender: gender,
            description: description,
            budget: budget.length === 0 ? 0 : budget
        };

        if (this.dataChecking()) {
            this.props.postCampaigns(data).then(res => {
                if (res.payload && res.payload.status && res.payload.status === 201) {
                    this.toggleDialog();
                    this.props.history.push("/main/campaing-page/" + res.payload.data.id);
                }
            });
        }
    };

    url_edit(campaign_link) {
        if (campaign_link.length === 0) {
            return "";
        } else {
            return campaign_link.includes("http") ? campaign_link : "http://" + campaign_link;
        }
    }

    dataChecking() {
        let campaign_name_valid = false,
            age_range_valid = false,
            description_valid = false;

        campaign_name_valid = this.state.campaign.campaign_name.length === 0;
        age_range_valid = this.state.campaign.age_range.length === 0;
        description_valid = this.state.campaign.description.length === 0;

        this.setState({
            valid_data: {
                campaign_name_valid,
                age_range_valid,
                description_valid
            }
        });

        return !(campaign_name_valid || age_range_valid || description_valid);
    }

    showError(error_text, is_valid) {
        if (!error_text && !is_valid) {
            return null;
        } else if (is_valid) {
            error_text = "Это поле обязательно";
        }

        return (
            <div className="back_error text_back_error_pos_abs">
                <span>{error_text}</span>
            </div>
        );
    }

    addChannel = response => {
        const { googleAuth, loadData, doRequest, history } = this.props;
        let obj = { token: response.code };
        loadData();
        googleAuth(obj).then(res => {
            if (res.payload) {
                doRequest();
                history.push("/main/search-tasks/");
            } else {
                loadData();
                this.toggleNoYoutubeAccountSnack();
            }
        });
    };

    toggleSnack = () => {
        this.setState({ openSnack: true });
        setTimeout(() => {
            this.setState({ openSnack: false });
        }, 4000);
    };

    toggleNoYoutubeAccountSnack = () => {
        this.setState({ openNoYoutubeAccount: true });
        this.timeout = setTimeout(() => {
            this.setState({ openNoYoutubeAccount: false });
        }, 4000);
    };

    render() {
        const { open, age_list, gender, openSnack } = this.state;
        const {
            campaign: { campaign_name, campaign_link, description, budget },
            openNoYoutubeAccount
        } = this.state;
        const {
            campaigns: { list_campaigns, error_campaigns },
            bloggerInterface,
            channel
        } = this.props;
        const {
            valid_data: { campaign_name_valid, age_range_valid, description_valid }
        } = this.state;

        const responseGoogle = response => {
            if (response.error) {
                this.toggleSnack();
            } else {
                this.addChannel(response);
            }
        };
        return (
            <div className="panel_wrapper">
                <div className="panel">
                    <div className="search_panel">
                        <Link to="/main">
                            <img src={search_icon} alt="search_icon" />
                            {bloggerInterface === "blogger" ? "Поиск заданий" : "Поиск по блогерам"}
                        </Link>
                    </div>
                    <div className="add_company">
                        {bloggerInterface === "blogger" ? (
                            <Fragment>
                                <div className="add">
                                    <span>YouTube-каналы</span>
                                    <div>
                                        <GoogleLogin
                                            clientId="783014202261-tros84e62de1i1i4qic94jc1g5o06qdj.apps.googleusercontent.com"
                                            scope="https://www.googleapis.com/auth/yt-analytics.readonly https://www.googleapis.com/auth/youtubepartner-channel-audit https://www.googleapis.com/auth/youtube.readonly"
                                            render={renderProps => (
                                                <img onClick={renderProps.onClick} src={add_btn} alt="add_btn" />
                                            )}
                                            buttonText="Войти с помощью Google"
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            cookiePolicy={"single_host_origin"}
                                            accessType="offline"
                                            prompt="consent"
                                            responseType="code"
                                            redirectUri="https://imast.4-com.pro/main"
                                        />
                                    </div>
                                </div>

                                {channel && (
                                    <div className="info_company">
                                        {channel.map((el, id) => (
                                            <Link
                                                to={`${this.props.match.url}/my-page/${el.id}`}
                                                className="company"
                                                key={id}
                                            >
                                                <div>{el.channel_name}</div>
                                            </Link>
                                        ))}
                                    </div>
                                )}
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className="add">
                                    <span>Кампании</span>
                                    <div onClick={this.toggleDialog}>
                                        <img src={add_btn} alt="add_btn" />
                                    </div>
                                </div>
                                <div className="info_company">
                                    {list_campaigns.length !== 0
                                        ? list_campaigns.map(item => {
                                              return (
                                                  <Link
                                                      key={item.id}
                                                      to={`/main/campaing-page/${item.id}`}
                                                      className="company"
                                                  >
                                                      <span className="green_status"></span>
                                                      <div>{item.campaign_name}</div>
                                                  </Link>
                                              );
                                          })
                                        : null}
                                </div>
                            </Fragment>
                        )}
                    </div>
                    <Link
                        to="/main/deals-page?redirect=true"
                        className="deals"
                        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                    >
                        Сделки
                    </Link>
                    <div className="block_wrapper_status">
                        {dealsStatuses.map(({ label, value, color }) => (
                            <Link
                                to={`/main/deals-page?status=${value}&redirect=true`}
                                key={value}
                                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                            >
                                <div className="status_bg" style={{ backgroundColor: color }} />
                                <span>{label}</span>
                            </Link>
                        ))}
                    </div>
                    {/*<Link to="" className="support">*/}
                    {/*Тех. поддержка*/}
                    {/*</Link>*/}
                    {/*<Link to="" className="reference">*/}
                    {/*Справка*/}
                    {/*</Link>*/}
                    <div className="info_wrapper">
                        {/*<Link to="">Правила системы</Link>*/}
                        <Link to="/main/public-offer" onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
                            Публичная оферта
                        </Link>
                        <Link to="/main/faq" onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
                            FAQ
                        </Link>
                        {/*<Link to="">*/}
                        {/*Политика <br />*/}
                        {/*конфиденциальности*/}
                        {/*</Link>*/}
                    </div>
                    <SnackBar open={openSnack} noClosingIcon classes="error">
                        Упс! Что-то пошло не так.
                    </SnackBar>
                    <SnackBar open={openNoYoutubeAccount} noClosingIcon classes="error">
                        Вы не можете использовать данный Youtube-аккаунт
                    </SnackBar>
                </div>
                <DialogComponent open={open} onClose={this.toggleDialog}>
                    <div className="add_company_dialog">
                        <div className="title">Создать кампанию</div>
                        <form className="add_company_form" onSubmit={this.submitForm}>
                            <div className="col pos_rel">
                                <label>Название кампании</label>
                                <input
                                    type="text"
                                    placeholder="Введите название кампании"
                                    value={campaign_name}
                                    name="campaign_name"
                                    onChange={this.handleInputChangeCampaign}
                                />
                                {this.showError(error_campaigns.campaign_name, campaign_name_valid)}
                            </div>
                            <div className="col pos_rel">
                                <label>Ссылка на сайт или YouTube-канал</label>
                                <input
                                    type="text"
                                    placeholder="http://"
                                    value={campaign_link}
                                    name="campaign_link"
                                    onChange={this.handleInputChangeCampaign}
                                />
                                {this.showError(error_campaigns.campaign_link)}
                            </div>
                            <div className="audience">Демография</div>
                            <div className="audience_block">
                                <div className="floor">
                                    <span>Пол</span>
                                    <div>
                                        <button
                                            className={clsx(!gender && "active")}
                                            onClick={e => {
                                                e.preventDefault();
                                                this.choiceGender(false);
                                            }}
                                        >
                                            <img src={male} alt="male" />
                                        </button>
                                        <button
                                            className={clsx(gender && "active")}
                                            onClick={e => {
                                                e.preventDefault();
                                                this.choiceGender(true);
                                            }}
                                        >
                                            <img src={female} alt="female" />
                                        </button>
                                    </div>
                                </div>
                                <div className="age">
                                    <span className="text_age">Возраст</span>
                                    <FormControl className="select_wrapper">
                                        <SelectComponent
                                            options={age_list}
                                            change={this.handleChangeAge}
                                            isClearable="false"
                                            isSearchable={false}
                                            placeholder="Выберите"
                                        />
                                        {this.showError(error_campaigns.age_range, age_range_valid)}
                                    </FormControl>
                                </div>
                            </div>
                            <div className="text_info">
                                <p>Цель кампании</p>
                                <textarea
                                    placeholder="Опишите цель кампании"
                                    value={description}
                                    name="description"
                                    onChange={this.handleInputChangeCampaign}
                                />
                                {this.showError(error_campaigns.description, description_valid)}
                            </div>
                            <div className="value_info">
                                <span className="bydget_text">Бюджет</span>
                                <input
                                    type="text"
                                    placeholder="0"
                                    value={budget}
                                    name="budget"
                                    onChange={this.handleInputChangeBudget}
                                />
                                <p>руб.</p>
                                {this.showError(error_campaigns.budget)}
                            </div>
                            <div className="btn_wrapper">
                                <button
                                    className="cancel_btn"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.toggleDialog();
                                    }}
                                >
                                    Отмена
                                </button>
                                <button
                                    className="green_btn"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.sendDataCampaign();
                                    }}
                                >
                                    Создать
                                </button>
                            </div>
                        </form>
                    </div>
                </DialogComponent>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        campaigns: state.campaigns
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getCampaigns,
            postCampaigns,
            resetErrorCampaigns,
            googleAuth
        },
        dispatch
    );
}

const PanelWithRouter = withRouter(Panel);
export default connect(mapStateToProps, mapDispatchToProps)(PanelWithRouter);
