import * as types from "../actions/constants";

const INITIAL_STATE = {
    error: ""
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GOOGLE_AUTH_FAIL:
            return {
                ...state,
                error: action.error.response.data.error
            };
        default:
            return state;
    }
}
