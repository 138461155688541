import * as types from "./constants.jsx";

export function getBloggers(query) {
    return {
        type: types.GET_BLOGGERS,
        payload: {
            client: "default",
            request: {
                url: `/channel/list/${query.length && query.length !== 0 ? "?" : ""}${query.join("&")}`,
                method: "get"
            }
        }
    };
}

export function getBloggerHeader(id) {
    return {
        type: types.PATCH_BLOGGER_HEADER,
        payload: {
            client: "default",
            request: {
                url: `/channel/${id}/`,
                method: "get"
            }
        }
    };
}

export function getDealBlogger() {
    return {
        type: types.GET_DEAL_BLOGGER,
        payload: {
            client: "default",
            request: {
                url: `/blogger/channel/list/`,
                method: "get"
            }
        }
    };
}

// export function getBloggerPriceList(id) {
//     return {
//         type: types.GET_BLOGGER_PRICELIST,
//         payload: {
//             client: "default",
//             request: {
//                 url: `/blogger/${id}/price-list/`,
//                 method: "get"
//             }
//         }
//     };
// }

export function getBloggerPriceList(id) {
    return {
        type: types.GET_BLOGGER_PRICELIST,
        payload: {
            client: "default",
            request: {
                url: `/channel/${id}/price-list/`,
                method: "get"
            }
        }
    };
}

export function getCurrentBlogger(id) {
    return {
        type: types.GET_CURRENT_BLOGGER,
        payload: {
            client: "default",
            request: {
                url: `/channel/${id}/audience/`,
                method: "get"
            }
        }
    };
}

export function getBloggersStatistics(id) {
    return {
        type: types.GET_BLOGGERS_STATISTICS,
        payload: {
            client: "default",
            request: {
                url: `/channel/${id}/statistics/`,
                method: "get"
            }
        }
    };
}

export function getBloggersAbout(id) {
    return {
        type: types.GET_BLOGGERS_ABOUT,
        payload: {
            client: "default",
            request: {
                url: `/channel/${id ? id + "/" : ""}about/`,
                method: "GET"
            }
        }
    };
}

export function patchBloggersAbout(data) {
    return {
        type: types.PATCH_BLOGGERS_ABOUT,
        payload: {
            client: "default",
            request: {
                url: `/blogger/about/`,
                method: "PATCH",
                data
            }
        }
    };
}

export function deleteBloggerAction() {
    return {
        type: types.DELETE_BLOGGER,
        payload: {
            client: "default",
            request: {
                url: `/channel-delete/`,
                method: "delete"
            }
        }
    };
}

export function postBloggerDealCreate(data) {
    return {
        type: types.POST_BLOGGER_DEAL_CREATE,
        payload: {
            client: "default",
            request: {
                url: `/blogger/deal-create/`,
                method: "post",
                data
            }
        }
    };
}

export function getPriceToInbox(id) {
    return {
        type: types.GET_PRICE_TO_INBOX,
        payload: {
            client: "default",
            request: {
                url: `/blogger/${id}/specify-the-price/`,
                method: "get"
            }
        }
    };
}

export function dispatchEmptyPriceList(id) {
    return {
        type: types.DISPATCH_EMPTY_PRICELIST,
        id
    };
}

export function resetBloggers() {
    return {
        type: types.RESET_BLOGGERS
    };
}

export function deleteBlogerChannel(id) {
    return {
        type: types.DELETE_BLOGER_CHANNEL,
        payload: {
            client: "default",
            request: {
                url: `/channel-delete/${id}/`,
                method: "delete"
            }
        }
    };
}
