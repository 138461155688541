import * as types from "../actions/constants";

const INITIAL_STATE = {
    tasks: [],
    nextPage: null,
    campaign_info: [],
    deals_error: {},
    dealBlogger: []
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_ALL_TASKS_SUCCESS:
            return {
                ...state,
                tasks: [...state.tasks, ...action.payload.data.results],
                nextPage: action.payload.data.next
            };
        case types.GET_CAMPAIGN_INFO_SUCCESS:
            return {
                ...state,
                campaign_info: action.payload.data
            };

        case types.GET_DEAL_BLOGGER_SUCCESS:
            return {
                ...state,
                dealBlogger: action.payload.data.map(el => ({
                    value: el.id,
                    label: el.channel_name
                }))
            };

        case types.POST_BLOGGER_DEAL_CREATE_FAIL:
            return { ...state, deals_error: action.error.response.data };
        case types.RESET_STORE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
