import React from "react";
import { Bar } from "react-chartjs-2";

const BarChart = ({ dataMale, dataFemale, min, max }) => {
    return (
        <Bar
            data={{
                labels: [
                    "13 - 17 лет",
                    "18 - 24 лет",
                    "25 - 34 лет",
                    "35 - 44 лет",
                    "45 - 54 лет",
                    "55 - 64 лет",
                    "65 лет и старше"
                ],
                datasets: [
                    {
                        label: "МУЖЧИНЫ",
                        backgroundColor: "#1A74DE",
                        data: dataMale
                    },
                    {
                        label: "ЖЕНЩИНЫ",
                        backgroundColor: "#EFA2FF",
                        data: dataFemale
                    }
                ]
            }}
            options={{
                scales: {
                    scaleLabel: {
                        fontFamily: "GothaProReg,sans-serif",
                        fontSize: 10
                    },
                    xAxes: [
                        {
                            stacked: true,
                            ticks: {
                                fontFamily: "GothaProReg,sans-serif",
                                fontColor: "#312D33",
                                fontSize: 10
                            }
                        }
                    ],
                    yAxes: [
                        {
                            stacked: true,
                            ticks: {
                                fontFamily: "GothaProReg,sans-serif",
                                fontColor: "#312D33",
                                fontSize: 10,
                                min: min,
                                max: max,
                                callback: function (value) {
                                    if (value > 100 || value < -100) return null;
                                    return Math.abs(value) + "%";
                                }
                            }
                        }
                    ]
                },
                maintainAspectRatio: false,
                responsive: true,
                legend: {
                    position: "bottom",
                    labels: {
                        fontFamily: "GothaProReg,sans-serif",
                        fontSize: 14,
                        padding: 30
                    }
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            let label = data.datasets[tooltipItem.datasetIndex].label || "";

                            if (label) {
                                label += ": ";
                            }
                            label += Math.round(Math.abs(tooltipItem.yLabel) * 100) / 100;
                            return label + "%";
                        }
                    },
                    titleFontFamily: "GothaProReg,sans-serif",
                    bodyFontFamily: "GothaProReg,sans-serif",
                    backgroundColor: "#FFF",
                    titleFontSize: 14,
                    titleFontColor: "#1A1A1A",
                    bodyFontColor: "#1A1A1A",
                    bodyFontSize: 14,
                    displayColors: false
                },
                plugins: {
                    labels: false
                }
            }}
        />
    );
};

export default BarChart;
