//////////////////////////////auth actions//////////////////////////////

export const REGISTER = "REGISTER";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const POST_ACTIVATE_ACCOUNT = "POST_ACTIVATE_ACCOUNT";

export const LOGIN = "LOGIN";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const RESET_PASSWORD = "RESET_PASSWORD";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const GOOGLE_AUTH = "GOOGLE_AUTH";
export const GOOGLE_AUTH_FAIL = "GOOGLE_AUTH_FAIL";

///////////////////////////////////////////////////////////////////////////

//////////////////////////////app actions//////////////////////////////

export const LOADING = "LOADING";
export const BUTTON_LOADING = "BUTTON_LOADING";

export const SNACK_TEXTS_CLEARING = "SNACK_TEXTS_CLEARING";

export const ERROR_SNACK_OPEN = "ERROR_SNACK_OPEN";
export const ERROR_SNACK_CLOSE = "ERROR_SNACK_CLOSE";

export const SUCCESS_SNACK_OPEN = "SUCCESS_SNACK_OPEN";
export const SUCCESS_SNACK_CLOSE = "SUCCESS_SNACK_CLOSE";

export const OPEN_PAYMENT_DETAILS = "OPEN_PAYMENT_DETAILS";

export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const GET_ALL_NOTIFICATIONS_SUCCESS = "GET_ALL_NOTIFICATIONS_SUCCESS";

export const GET_OFFER_PAGE = "GET_OFFER_PAGE";
export const GET_OFFER_PAGE_SUCCESS = "GET_OFFER_PAGE_SUCCESS";

export const GET_PRIVACY_POLICY_PAGE = "GET_PRIVACY_POLICY_PAGE";
export const GET_PRIVACY_POLICY_PAGE_SUCCESS = "GET_PRIVACY_POLICY_PAGE_SUCCESS";

export const GET_FAQ_PAGE = "GET_FAQ_PAGE";
export const GET_FAQ_PAGE_SUCCESS = "GET_FAQ_PAGE_SUCCESS";

export const FILTER_NOTIFICATIONS = "FILTER_NOTIFICATIONS";
export const DELETE_ALL_NOTIFICATIONS = "DELETE_ALL_NOTIFICATIONS";
export const DELETE_ALL_NOTIFICATIONS_SUCCESS = "DELETE_ALL_NOTIFICATIONS_SUCCESS";

export const DELETE_SPECIFIC_NOTIFICATION = "DELETE_SPECIFIC_NOTIFICATION";
export const DELETE_SPECIFIC_NOTIFICATION_SUCCESS = "DELETE_SPECIFIC_NOTIFICATION_SUCCESS";

export const OFFER_CHECK = "OFFER_CHECK";
export const OFFER_CHECK_SUCCESS = "OFFER_CHECK_SUCCESS";

///////////////////////////////////////////////////////////////////////////

//////////////////////////////profile actions//////////////////////////////

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const PATCH_PROFILE = "PATCH_PROFILE";
export const PATCH_PROFILE_SUCCESS = "PATCH_PROFILE_SUCCESS";
export const PATCH_PROFILE_FAIL = "PATCH_PROFILE_FAIL";

export const PATCH_PAYMENT_INFORMATION = "PATCH_PAYMENT_INFORMATION";
export const PATCH_PAYMENT_INFORMATION_SUCCESS = "PATCH_PAYMENT_INFORMATION_SUCCESS";
export const PATCH_PAYMENT_INFORMATION_FAIL = "PATCH_PAYMENT_INFORMATION_FAIL";

export const PATCH_PROFILE_CHANGE_PASSWORD = "PATCH_PROFILE_CHANGE_PASSWORD";
export const PATCH_PROFILE_CHANGE_PASSWORD_FAIL = "PATCH_PROFILE_CHANGE_PASSWORD_FAIL";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const POST_ADVERTISER_TYPE = "POST_ADVERTISER_TYPE";
export const GET_ADVERTISER_TYPE = "GET_ADVERTISER_TYPE";

///////////////////////////////////////////////////////////////////////////

//////////////////////////////campaigns actions//////////////////////////////

export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS";
export const GET_CAMPAIGNS_FAIL = "GET_CAMPAIGNS_FAIL";

export const POST_CAMPAIGNS = "POST_CAMPAIGNS";
export const POST_CAMPAIGNS_SUCCESS = "POST_CAMPAIGNS_SUCCESS";
export const POST_CAMPAIGNS_FAIL = "POST_CAMPAIGNS_FAIL";

export const RESET_CAMPAIGNS_ERROR = "RESET_CAMPAIGNS_ERROR";

///////////////////////////////////////////////////////////////////////////

//////////////////////////campaignInnerPage actions////////////////////////
export const GET_CERTAIN_CAMPAIGN = "GET_CERTAIN_CAMPAIGN";
export const GET_CERTAIN_CAMPAIGN_SUCCESS = "GET_CERTAIN_CAMPAIGN_SUCCESS";

export const GET_TASKS = "GET_TASKS";
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS";

export const GET_CAMPAIGN_DEALS = "GET_CAMPAIGN_DEALS";
export const GET_CAMPAIGN_DEALS_SUCCESS = "GET_CAMPAIGN_DEALS_SUCCESS";

export const GET_CAMPAIGN_TRANSACTIONS = "GET_CAMPAIGN_TRANSACTIONS";
export const GET_CAMPAIGN_TRANSACTIONS_SUCCESS = "GET_CAMPAIGN_TRANSACTIONS_SUCCESS";

export const POST_TASK = "POST_TASK";
export const POST_TASK_FAIL = "POST_TASK_FAIL";

export const PATCH_TASK = "PATCH_TASK";
export const PATCH_TASK_FAIL = "PATCH_TASK_FAIL";

export const DELETE_TASK = "DELETE_TASK";

export const PATCH_CAMPAIGN_STATUS = "PATCH_CAMPAIGN_STATUS";

export const DELETE_CERTAIN_CAMPAIGN = "DELETE_CERTAIN_CAMPAIGN";

export const GET_BLOGGERS = "GET_BLOGGERS";
export const GET_BLOGGERS_SUCCESS = "GET_BLOGGERS_SUCCESS";

export const GET_BLOGGER_PRICELIST = "GET_BLOGGER_PRICELIST";
export const GET_BLOGGER_PRICELIST_SUCCESS = "GET_BLOGGER_PRICELIST_SUCCESS";
export const GET_BLOGGER_PRICELIST_FAIL = "GET_BLOGGER_PRICELIST_FAIL";

export const PATCH_BLOGGER_PRICELIST = "PATCH_BLOGGER_PRICELIST";
export const PATCH_BLOGGER_PRICELIST_SUCCESS = "PATCH_BLOGGER_PRICELIST_SUCCESS";
export const PATCH_BLOGGER_PRICELIST_FAIL = "PATCH_BLOGGER_PRICELIST_FAIL";

export const GET_CURRENT_BLOGGER = "GET_CURRENT_BLOGGER";
export const GET_CURRENT_BLOGGER_SUCCESS = "GET_CURRENT_BLOGGER_SUCCESS";

export const GET_BLOGGERS_STATISTICS = "GET_BLOGGERS_STATISTICS";
export const GET_BLOGGERS_STATISTICS_SUCCESS = "GET_BLOGGERS_STATISTICS_SUCCESS";

export const GET_BLOGGERS_ABOUT = "GET_BLOGGERS_ABOUT";
export const GET_BLOGGERS_ABOUT_SUCCESS = "GET_BLOGGERS_ABOUT_SUCCESS";
export const GET_BLOGGERS_ABOUT_FAIL = "GET_BLOGGERS_ABOUT_FAIL";

export const PATCH_BLOGGERS_ABOUT = "PATCH_BLOGGERS_ABOUT";

export const PATCH_BLOGGER_HEADER = "PATCH_BLOGGER_HEADER";
export const PATCH_BLOGGER_HEADER_FAIL = "PATCH_BLOGGER_HEADER_FAIL";

export const GET_DEAL_BLOGGER = "GET_DEAL_BLOGGER";
export const GET_DEAL_BLOGGER_SUCCESS = "GET_DEAL_BLOGGER_SUCCESS";
export const GET_DEAL_BLOGGER_FAIL = "GET_DEAL_BLOGGER_FAIL";

export const DELETE_BLOGGER = "DELETE_BLOGGER";

export const POST_BLOGGER_DEAL_CREATE = "POST_BLOGGER_DEAL_CREATE";
export const POST_BLOGGER_DEAL_CREATE_SUCCESS = "POST_BLOGGER_DEAL_CREATE_SUCCESS";
export const POST_BLOGGER_DEAL_CREATE_FAIL = "POST_BLOGGER_DEAL_CREATE_FAIL";

export const GET_PRICE_TO_INBOX = "GET_PRICE_TO_INBOX";
export const GET_PRICE_TO_INBOX_SUCCESS = "GET_PRICE_TO_INBOX_SUCCESS";

export const DISPATCH_EMPTY_PRICELIST = "DISPATCH_EMPTY_PRICELIST";
export const RESET_BLOGGERS = "RESET_BLOGGERS";

export const DELETE_BLOGER_CHANNEL = "DELETE_BLOGER_CHANNEL";

///////////////////////////////////////////////////////////////////////////

export const GET_REVIEW = "GET_REVIEW";
export const GET_REVIEW_SUCCESS = "GET_REVIEW_SUCCESS";
export const GET_REVIEW_FAIL = "GET_REVIEW_FAIL";

///////////////////////////////////////////////////////////////////////////

export const GET_TASK_BY_TYPE = "GET_TASK_BY_TYPE";
export const GET_TASK_BY_TYPE_SUCCESS = "GET_TASK_BY_TYPE_SUCCESS";

export const POST_DEAL_CREATE = "POST_DEAL_CREATE";
export const POST_DEAL_CREATE_FAIL = "POST_DEAL_CREATE_FAIL";
export const RESET_ERROR = "RESET_ERROR";

//////////////////////////////////tasks_search/////////////////////////////////////////

export const GET_ALL_TASKS = "GET_ALL_TASKS";
export const GET_ALL_TASKS_SUCCESS = "GET_ALL_TASKS_SUCCESS";

export const GET_CAMPAIGN_INFO = "GET_CAMPAIGN_INFO";
export const GET_CAMPAIGN_INFO_SUCCESS = "GET_CAMPAIGN_INFO_SUCCESS";

export const RESET_STORE = "RESET_STORE";

//////////////////////////////////deals constants/////////////////////////////////////////

export const GET_DEALS = "GET_DEALS";
export const GET_DEALS_SUCCESS = "GET_DEALS_SUCCESS";
export const GET_DEALS_FAIL = "GET_DEALS_FAIL";

export const GET_CURRENT_DEAL = "GET_CURRENT_DEAL";
export const GET_CURRENT_DEAL_SUCCESS = "GET_CURRENT_DEAL_SUCCESS";
export const GET_CURRENT_DEAL_FAIL = "GET_CURRENT_DEAL_FAIL";

export const PATCH_CURRENT_DEAL = "PATCH_CURRENT_DEAL";
export const PATCH_CURRENT_DEAL_SUCCESS = "PATCH_CURRENT_DEAL_SUCCESS";
export const PATCH_CURRENT_DEAL_FAIL = "PATCH_CURRENT_DEAL_FAIL";

export const PATCH_DEAL_WITH_VIDEO = "PATCH_DEAL_WITH_VIDEO";
export const PATCH_DEAL_WITH_VIDEO_SUCCESS = "PATCH_DEAL_WITH_VIDEO_SUCCESS";
export const PATCH_DEAL_WITH_VIDEO_FAIL = "PATCH_DEAL_WITH_VIDEO_FAIL";

export const PATCH_DEAL_CONDITIONS = "PATCH_DEAL_CONDITIONS";
export const PATCH_DEAL_CONDITIONS_SUCCESS = "PATCH_DEAL_CONDITIONS_SUCCESS";
export const PATCH_DEAL_CONDITIONS_FAIL = "PATCH_DEAL_CONDITIONS_FAIL";

export const PATCH_DEAL_ARBITRATION = "PATCH_DEAL_ARBITRATION";
export const PATCH_DEAL_ARBITRATION_SUCCESS = "PATCH_DEAL_ARBITRATION_SUCCESS";
export const PATCH_DEAL_ARBITRATION_FAIL = "PATCH_DEAL_ARBITRATION_FAIL";

export const POST_MESSAGE = "POST_MESSAGE";
export const POST_MESSAGE_SUCCESS = "POST_MESSAGE_SUCCESS";
export const POST_MESSAGE_FAIL = "POST_MESSAGE_FAIL";

export const POST_REVIEW = "POST_REVIEW";
export const POST_REVIEW_SUCCESS = "POST_REVIEW_SUCCESS";
export const POST_REVIEW_FAIL = "POST_REVIEW_FAIL";

export const CLEAR_CURRENT_DEAL = "CLEAR_CURRENT_DEAL";
export const RESET_DEAL_ERRORS = "RESET_DEAL_ERRORS";

//////////////////////////////////finance constants/////////////////////////////////////////

export const GET_BALANCE_GENERAL_INFO = "GET_BALANCE_GENERAL_INFO";
export const GET_BALANCE_GENERAL_INFO_SUCCESS = "GET_BALANCE_GENERAL_INFO_SUCCESS";
export const GET_BALANCE_GENERAL_INFO_FAIL = "GET_BALANCE_GENERAL_INFO_FAIL";

export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAIL = "GET_TRANSACTIONS_FAIL";

export const POST_WITHDRAWAL_INFO = "POST_WITHDRAWAL_INFO";
export const POST_WITHDRAWAL_INFO_SUCCESS = "POST_WITHDRAWAL_INFO_SUCCESS";
export const POST_WITHDRAWAL_INFO_FAIL = "POST_WITHDRAWAL_INFO_FAIL";
