import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../HelperComponents/ContentLoader/ContentLoader";

import { getOfferPage } from "../../actions/appActions";

import "./PublicOffer.scss";

class PublicOffer extends Component {
    state = {
        loading: true
    };

    componentDidMount() {
        const { getOfferPage } = this.props;
        getOfferPage().then(() => {
            this.setState({ loading: false });
        });
    }

    render() {
        const { offerPage } = this.props;
        const { loading } = this.state;
        if (loading)
            return (
                <div className="loader">
                    <Loader height={65} width={5} margin="15px" color={"#5ce6cd"} />
                </div>
            );
        return (
            <div className="content_block blogger_wrapper">
                <div className="public_offer" dangerouslySetInnerHTML={{ __html: offerPage }} />
            </div>
        );
    }
}

function mapStateToProps({ app }) {
    return {
        offerPage: app.offerPage
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getOfferPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicOffer);
