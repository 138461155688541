import React, { Component, Fragment } from "react";
import { connect, reset } from "react-redux";
import { bindActionCreators } from "redux";
import FormControl from "@material-ui/core/FormControl";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";

import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent";
import Loader from "../HelperComponents/ContentLoader/ContentLoader";

import { getTasksSearch, getCampaignInfo, resetStore } from "../../actions/tasksSearchActions";
import { getOption, numberWithSpaces } from "../../helpers/functions";
import { postBloggerDealCreate, getDealBlogger } from "../../actions/bloggerAction";

import arrow from "../../assets/image/up_arrow.png";
import no_tasks from "../../assets/image/no_tasks.png";
import MaleIcon from "../../assets/image/male.svg";
import FemaleIcon from "../../assets/image/female_small.svg";

import Snack from "../HelperComponents/Snack/Snack";
import { closeErrorSnack } from "../../actions/appActions";
import "./SearchTasks.scss";
import { Field, reduxForm } from "redux-form";
import { renderField } from "../CampaingPage/CampaingPage";
import help from "../../assets/image/help.svg";
import actions from "redux-form/lib/actions";

class SearchTasks extends Component {
    state = {
        loading: true,
        showFull: [],
        open: false,
        openRequestDialog: false,
        openSnackErr: false,
        openSnackErrDeal: false,
        currentTask: undefined,
        advertising: { label: getOption("Все типы рекламы"), value: "" },
        selectBlogger: "",
        amountBlogger: 0,
        date_info: {
            label: (
                <div className="status_select">
                    <div className="up">
                        <img src={arrow} alt="arrow" />
                    </div>
                    <div>ДАТЕ</div>
                </div>
            ),
            value: "date"
        },
        advertising_list: [
            { label: getOption("Все типы рекламы"), value: "" },
            { label: getOption("В начале видео"), value: "preroll" },
            { label: getOption("В середине видео"), value: "middle" },
            { label: getOption("В конце видео"), value: "postroll" },
            { label: getOption("Интеграция в видео"), value: "video_integration" },
            { label: getOption("Ссылка в сообществе канала"), value: "society_link" },
            { label: getOption("Заказной ролик"), value: "customized_video" },
            { label: getOption("Реклама в YouTube-истории"), value: "stories_ad" }
        ],
        date_list: [
            {
                label: (
                    <div className="status_select">
                        <div className="up">
                            <img src={arrow} alt="arrow" />
                        </div>
                        <div>ДАТЕ</div>
                    </div>
                ),
                value: "date"
            },
            {
                label: (
                    <div className="status_select">
                        <div className="down">
                            <img src={arrow} alt="arrow" />
                        </div>
                        <div>ДАТЕ</div>
                    </div>
                ),
                value: "-date"
            }
        ]
    };

    componentDidMount() {
        this.doRequest();
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    doRequest = () => {
        const { getTasksSearch, resetStore, googleAuth_err, getDealBlogger } = this.props;
        const { advertising, date_info } = this.state;
        this.setState({ loading: true });
        resetStore();
        let data = [];
        if (advertising.value) {
            data.push(`task_type=${advertising.value}`);
        }
        if (date_info.value) {
            data.push(`date=${date_info.value}`);
        }
        getTasksSearch(data).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ loading: false });
            }
        });
        if (googleAuth_err) {
            this.setState({ openSnackErr: true });
            setTimeout(() => {
                this.setState({ openSnackErr: false });
            }, 4000);
        }
        getDealBlogger().then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ selectBlogger: res.payload.data && res.payload.data[0] && res.payload.data[0].id });
                this.setState({ amountBlogger: res.payload.data.length });
            }
        });
    };

    handleLazyLoad = () => {
        const { getTasksSearch, nextPage } = this.props;
        const { advertising, date_info } = this.state;
        let data = [];
        if (advertising.value) {
            data.push(`task_type=${advertising.value}`);
        }
        if (date_info.value) {
            data.push(`date=${date_info.value}`);
        }
        if (!!nextPage) {
            const match = nextPage.match(/page=(\d+)/);
            let page = null;
            if (match) {
                page = match[1];
            }
            data.push(`page=${page}`);
        }
        getTasksSearch(data);
    };

    toggleText = id => {
        if (this.state.showFull.includes(id)) {
            this.setState({
                showFull: this.state.showFull.filter(el => el !== id)
            });
        } else {
            this.setState(({ showFull }) => ({
                showFull: [...showFull, id]
            }));
        }
    };

    handleChange = name => event => {
        this.setState({ [name]: event });
        this.timeout = setTimeout(() => {
            this.doRequest();
        }, 100);
    };

    handleChangeDeal = e => {
        this.setState({ selectBlogger: e.value });
        console.log(this.state.selectBlogger);
    };

    toggleDialog = id => {
        const { getCampaignInfo } = this.props;
        if (!!id) {
            getCampaignInfo(id).then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    this.setState(({ open }) => ({
                        open: !open
                    }));
                }
            });
        } else {
            this.setState(({ open }) => ({
                open: !open
            }));
        }
    };

    handleRequest = currentTask => this.setState({ currentTask, openRequestDialog: true });

    submitRequest = async data => {
        const { postBloggerDealCreate, reset } = this.props;
        const { currentTask, selectBlogger } = this.state;
        let additionalParams = {
            task_id: currentTask,
            youtube_channel_id: selectBlogger
        };

        if (data.sum) additionalParams.sum = Number(data.sum);
        if (data.guarantor) additionalParams.guarantor = Number(data.guarantor);

        await postBloggerDealCreate(additionalParams).then(res => {
            this.setState({ currentTask: undefined, openRequestDialog: false });
            reset("SearchTasks");
            if (res.error) {
                this.setState({ openSnackErrDeal: true });
                setTimeout(() => {
                    this.setState({ openSnackErrDeal: false });
                }, 4000);
            }
        });
    };

    render() {
        const {
            tasks,
            nextPage,
            campaign_info,
            buttonLoading,
            googleAuth_err,
            deals_error,
            handleSubmit,
            dealBlogger,
            reset
        } = this.props;
        const {
            advertising,
            date_info,
            date_list,
            advertising_list,
            loading,
            showFull,
            open,
            openRequestDialog,
            openSnackErr,
            openSnackErrDeal
        } = this.state;
        return (
            <div className="content_block_page deals_page_wrapper">
                <h1>Поиск заданий</h1>
                <div className="deals_page_panel">
                    <div className="left_block">
                        <FormControl className="select_wrapper">
                            <SelectComponent
                                value={advertising}
                                options={advertising_list}
                                change={this.handleChange("advertising")}
                                isClearable="false"
                                isSearchable={false}
                            />
                        </FormControl>
                    </div>
                    <div className="right_block">
                        <div className="descriptions">Сортировать по:</div>

                        <FormControl className="select_wrapper">
                            <SelectComponent
                                value={date_info}
                                options={date_list}
                                change={this.handleChange("date_info")}
                                isClearable="false"
                                isSearchable={false}
                            />
                        </FormControl>
                    </div>
                </div>
                {loading ? (
                    <Loader height={65} width={5} margin="15px" color={"#5ce6cd"} />
                ) : tasks.length === 0 ? (
                    <div className="no_deals block_wrapper_bg">
                        <div>
                            <img src={no_tasks} alt="no_tasks" />
                        </div>
                        <div className="descriptions">Пока нет ни одного задания</div>
                    </div>
                ) : (
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.handleLazyLoad}
                        hasMore={!!nextPage}
                        loader={
                            <div className="lazy_loader_wrapper" key={0}>
                                <Loader height={55} width={5} margin="15px" color={"#5ce6cd"} />
                            </div>
                        }
                    >
                        <div className="search_tasks_wrapper">
                            {tasks.map(item => (
                                <div className="tasks_wrapper" key={item.id}>
                                    <div className="tasks">
                                        <div className="info_title">
                                            <span>
                                                {item.company_name && `${item.company_name} • `}
                                                {item.campaign_name}
                                            </span>
                                            <div>
                                                {item.campaign_link && (
                                                    <Fragment>
                                                        <a
                                                            href={item.campaign_link}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {item.campaign_link}
                                                        </a>
                                                        <span />
                                                    </Fragment>
                                                )}
                                                <div
                                                    className="about_campaign"
                                                    onClick={() => this.toggleDialog(item.campaign_id)}
                                                >
                                                    О кампании
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data">{moment(item.date).format("DD.MM.YYYY")}</div>
                                    </div>
                                    {item.ad_link && (
                                        <div className="sponsored_info">
                                            <div>Рекламная ссылка</div>
                                            <a href={item.ad_link} target="_blank" rel="noopener noreferrer">
                                                {item.ad_link}
                                            </a>
                                        </div>
                                    )}
                                    {item.task_text && (
                                        <div className="descriptions_projects">
                                            <div className="title">Описание задания</div>
                                            <div
                                                className={
                                                    !showFull.includes(item.id) && item.task_text.length > 1000
                                                        ? "text"
                                                        : "text full_height"
                                                }
                                            >
                                                {item.task_text}
                                            </div>
                                            {item.task_text.length > 1000 && (
                                                <button onClick={() => this.toggleText(item.id)}>
                                                    {showFull.includes(item.id) ? (
                                                        <Fragment>
                                                            <span className="down" />
                                                            показать полностью
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>
                                                            <span className="up" />
                                                            скрыть
                                                        </Fragment>
                                                    )}
                                                </button>
                                            )}
                                        </div>
                                    )}
                                    <div className="tasks_wrapper_btn">
                                        <div className="descriptions">{item.task_type}</div>
                                        <div className="price_info">
                                            <div>{numberWithSpaces(item.budget)} руб.</div>
                                            <button
                                                className="border_btn_green"
                                                onClick={() => this.handleRequest(item.id)}
                                            >
                                                Подать заявку
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <Snack
                                open={openSnackErr}
                                handleSnack={closeErrorSnack}
                                message={googleAuth_err}
                                type="error"
                            />
                            <Snack
                                open={openSnackErrDeal}
                                handleSnack={closeErrorSnack}
                                message={"Это один и тот же пользователь"}
                                type="error"
                            />
                        </div>
                    </InfiniteScroll>
                )}
                <DialogComponent open={open} onClose={() => this.toggleDialog("")}>
                    <div className="dg_campaign_info_wrapper">
                        <div className="dg_campaign_info_title">{campaign_info.campaign_name}</div>
                        <div className="dg_campaign_info_label">Демография</div>
                        <div className="dg_campaign_info_text">
                            <img src={campaign_info.gender === "male" ? MaleIcon : FemaleIcon} alt="gender" />
                            {campaign_info.age_range} лет
                        </div>
                        <div className="dg_campaign_info_label">Цель кампании </div>
                        <div className="dg_campaign_info_text">{campaign_info.description}</div>
                    </div>
                </DialogComponent>
                <DialogComponent
                    open={openRequestDialog}
                    onClose={() => {
                        this.setState({ openRequestDialog: false });
                        this.props.reset("SearchTasks");
                    }}
                >
                    <div className="default_dialog">
                        <form className="block_tasks_bloger search" onSubmit={handleSubmit(this.submitRequest)}>
                            <div className="title">Подать заявку</div>
                            {this.state.amountBlogger > 1 && (
                                <div className="bock_select_forms">
                                    <div className="description">
                                        Выберите канал, в котором хотите выполнить данное задание.
                                    </div>

                                    <FormControl className="blogers_select_wrapper">
                                        <SelectComponent
                                            options={dealBlogger}
                                            change={e => this.handleChangeDeal(e)}
                                            isClearable="false"
                                            isSearchable={false}
                                            placeholder={"Выберите канал"}
                                        />
                                    </FormControl>
                                </div>
                            )}
                            <div className="description">
                                Вы уверены, что хотите подать заявку на выполнение данного задания? При желании Вы
                                можете предложить рекламодателю свои условия сделки.
                            </div>
                            <div className="block_info_forms">
                                <div className="price">
                                    <div>Сумма</div>
                                    <Field name="sum" component={renderField} type="number" max="999999999" />
                                    <span>руб.</span>
                                    {deals_error && deals_error.sum && (
                                        <span className="back_error ">{deals_error.sum}</span>
                                    )}
                                </div>
                                <div className="price">
                                    <div>
                                        Гарант
                                        <div className="box_tooltip">
                                            <img src={help} alt="help" />
                                            <div className="tooltip">
                                                Минимально гарантированное число просмотров за указанный бюджет на 7-й
                                                день после публикации контента
                                            </div>
                                        </div>
                                    </div>
                                    <Field
                                        name="guarantor"
                                        component={renderField}
                                        type="number"
                                        max="999999999"
                                        className="guarantor"
                                    />
                                    {deals_error && deals_error.guarantor && (
                                        <span className="back_error ">{deals_error.guarantor}</span>
                                    )}
                                </div>
                            </div>
                            <div className="btn_wrapper">
                                <button
                                    className="cancel_btn"
                                    type="button"
                                    onClick={() => {
                                        this.setState({ openRequestDialog: false });
                                        this.props.reset("SearchTasks");
                                    }}
                                >
                                    Отмена
                                </button>
                                <button className="border_btn_green" type="submit">
                                    {buttonLoading ? (
                                        <Loader height={20} width={2} margin="2px" color={"#FFF"} />
                                    ) : (
                                        "Подтвердить"
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </DialogComponent>
            </div>
        );
    }
}

SearchTasks = reduxForm({
    form: "SearchTasks"
})(SearchTasks);

const mapStateToProps = state => ({
    tasks: state.tasks_search.tasks,
    nextPage: state.tasks_search.nextPage,
    campaign_info: state.tasks_search.campaign_info,
    deals_error: state.tasks_search.deals_error,
    buttonLoading: state.app.buttonLoading,
    googleAuth_err: state.googleAuth_err.error,
    dealBlogger: state.tasks_search.dealBlogger
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getTasksSearch,
            getCampaignInfo,
            resetStore,
            postBloggerDealCreate,
            getDealBlogger
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SearchTasks);
