import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import moment from "moment";

import { getDeals } from "../../actions/dealsActions";

import { dealsStatuses, tasksStatuses } from "../../helpers/constants";
import { getOption, getSortOption, numberWithSpaces } from "../../helpers/functions";
import Pagination from "../HelperComponents/Pagination/Pagination";
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import Loader from "../HelperComponents/ContentLoader/ContentLoader";

import arbitrage from "../../assets/image/arbitrage.svg";
import no_deals from "../../assets/image/no_deals.png";

import "./DealsPage.scss";

class DealsPage extends Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(props.location.search),
            statusInUrl = params.get("status") || "",
            taskTypeInUrl = params.get("task_type") || "";
        const statusIsPresented = dealsStatuses.find(el => el.value === statusInUrl),
            taskTypeIsPresented = tasksStatuses.find(el => el.value === taskTypeInUrl);
        const status = {
                label: (
                    <div className="status_select">
                        {statusIsPresented && <span style={{ backgroundColor: statusIsPresented.color }} />}
                        <div>{statusIsPresented ? statusIsPresented.label : "Все статусы"}</div>
                    </div>
                ),
                value: statusInUrl
            },
            advertising = {
                label: getOption(taskTypeIsPresented ? taskTypeIsPresented.label : "Все типы рекламы"),
                value: taskTypeInUrl
            };
        this.state = {
            activePage: 1,
            status,
            advertising,
            date_info: { label: getSortOption("ДАТЕ", "down"), value: "-creation_date" },
            projects_list: [
                { label: getOption("Все статусы"), value: "" },
                ...dealsStatuses.map(({ label, value, color }) => {
                    return {
                        label: (
                            <div className="status_select">
                                <span style={{ backgroundColor: color }} />
                                <div>{label}</div>
                            </div>
                        ),
                        value: value
                    };
                })
            ],
            advertising_list: [
                { label: getOption("Все типы рекламы"), value: "" },
                ...tasksStatuses.map(({ label, value }) => {
                    return { label: getOption(label), value: value };
                })
            ],
            date_list: [
                { label: getSortOption("ДАТЕ", "up"), value: "creation_date" },
                { label: getSortOption("ДАТЕ", "down"), value: "-creation_date" }
            ]
        };
    }

    componentDidMount() {
        const { loading, next, count } = this.props.dealsList;
        const params = new URLSearchParams(this.props.location.search);
        if (loading || !!params.get("redirect")) {
            this.fetchDeals(1);
            this.handleUrl();
        } else {
            let activePage = Math.ceil(count / 10);
            if (next) {
                activePage = new URLSearchParams(next.split("deals/")[1]).get("page");
                activePage = activePage - 1;
            }
            this.setState({ activePage });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevState.advertising.value !== this.state.advertising.value ||
            prevState.status.value !== this.state.status.value ||
            prevState.date_info.value !== this.state.date_info.value
        ) {
            this.fetchDeals(1);
            this.setState({ activePage: 1 });
            this.handleUrl();
        }
        if (prevProps.location.search !== this.props.location.search) {
            const params = new URLSearchParams(this.props.location.search);
            if (params.get("redirect")) {
                const statusInUrl = params.get("status") || "",
                    statusIsPresented = dealsStatuses.find(el => el.value === statusInUrl);
                this.setState({
                    status: {
                        label: (
                            <div className="status_select">
                                {statusIsPresented && <span style={{ backgroundColor: statusIsPresented.color }} />}
                                <div>{statusIsPresented ? statusIsPresented.label : "Все статусы"}</div>
                            </div>
                        ),
                        value: params.get("status") || ""
                    }
                });
                this.timeout = setTimeout(() => {
                    this.handleUrl();
                }, 0);
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    fetchDeals = activePage => {
        const { getDeals } = this.props;
        const { advertising, date_info, status } = this.state;
        let arr = [];
        arr.push(`page=${activePage}`);
        arr.push(`page_size=10`);
        arr.push(`sort=${date_info.value}`);
        advertising.value && arr.push(`task_type=${advertising.value}`);
        status.value && arr.push(`status=${status.value}`);
        getDeals(arr);
    };

    handleUrl = () => {
        const { history } = this.props;
        const { advertising, status } = this.state;
        let pathName = `/main/deals-page`;
        advertising.value && (pathName = `${pathName}?task_type=${advertising.value}`);
        status.value && (pathName = `${pathName}${advertising.value ? "&" : "?"}status=${status.value}`);
        history.push(pathName);
    };

    handleChange = name => event => this.setState({ [name]: event });

    changePage = async activePage => {
        await this.fetchDeals(activePage);
        this.setState({ activePage });
    };

    getArbitrationTag = () => {
        return (
            <div className="arbitrage_icon">
                <img src={arbitrage} alt="arbitrage" className="arbitrage_icon" />
                <div className="tooltip">На арбитраже</div>
            </div>
        );
    };

    render() {
        const {
            dealsList: { loading, count, results },
            history
        } = this.props;
        const { status, advertising, date_info, date_list, projects_list, advertising_list, activePage } = this.state;
        return (
            <TransitionedBlock>
                <div className="content_block_page deals_page_wrapper">
                    <h1>Сделки</h1>
                    <div className="deals_page_panel">
                        <div className="left_block">
                            <FormControl className="select_wrapper">
                                <SelectComponent
                                    value={status}
                                    options={projects_list}
                                    change={this.handleChange("status")}
                                    isClearable="false"
                                    isSearchable={false}
                                />
                            </FormControl>
                            <FormControl className="select_wrapper">
                                <SelectComponent
                                    value={advertising}
                                    options={advertising_list}
                                    change={this.handleChange("advertising")}
                                    isClearable="false"
                                    isSearchable={false}
                                />
                            </FormControl>
                        </div>
                        <div className="right_block">
                            <div className="descriptions">Сортировать по:</div>
                            <FormControl className="select_wrapper">
                                <SelectComponent
                                    value={date_info}
                                    options={date_list}
                                    change={this.handleChange("date_info")}
                                    isClearable="false"
                                    isSearchable={false}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className={clsx("block_wrapper_bg", count === 0 && "no_deals")}>
                        {loading ? (
                            <div className="flex-center">
                                <Loader height={65} width={5} margin="15px" color={"#5ce6cd"} />
                            </div>
                        ) : count === 0 ? (
                            <Fragment>
                                <div>
                                    <img src={no_deals} alt="no_deals" />
                                </div>
                                <div className="descriptions">Пока нет ни одной сделки</div>
                            </Fragment>
                        ) : (
                            <div className="deals_table">
                                <div className="table_container transactions_columns">
                                    <div className="table_header">
                                        <div className="table_row">
                                            <div className="row_item">ID</div>
                                            <div className="row_item">Кампания</div>
                                            <div className="row_item">Блогер</div>
                                            <div className="row_item">Статус</div>
                                            <div className="row_item">Тип рекламы</div>
                                            <div className="row_item">Сумма</div>
                                            <div className="row_item">Дата/время</div>
                                        </div>
                                    </div>
                                    <div className="table_body">
                                        {results.map(
                                            ({
                                                channel_name,
                                                budget,
                                                campaign,
                                                creation_date,
                                                id,
                                                img,
                                                status,
                                                arbitration,
                                                task_type
                                            }) => {
                                                const dealStatus = dealsStatuses.find(el => el.value === status) || {
                                                    label: "Проверка гаранта",
                                                    value: "guarantor_check",
                                                    color: "#6AC798",
                                                    arbitration: true
                                                };
                                                const taskType = tasksStatuses.find(el => el.value === task_type);
                                                return (
                                                    <div
                                                        className="table_row"
                                                        key={`deal_${id}`}
                                                        onClick={() => {
                                                            history.push(`/main/deals-inner/${id}`);
                                                            window.scrollTo({ top: 0, behavior: "smooth" });
                                                        }}
                                                    >
                                                        <div className="row_item">{id}</div>
                                                        <div className="row_item"><span className="text_ellipsis">{campaign}</span></div>
                                                        <div className="row_item">
                                                            {img && (
                                                                <img
                                                                    src={img}
                                                                    alt="no_deals"
                                                                    className="rounded_image"
                                                                />
                                                            )}
                                                            {channel_name || "—"}
                                                        </div>
                                                        <div className="row_item status">
                                                            <span style={{ backgroundColor: dealStatus.color }} />
                                                            {dealStatus.label
                                                                .replace("нные", "на")
                                                                .replace("дают", "дает")}
                                                            {arbitration && this.getArbitrationTag()}
                                                        </div>
                                                        <div className="row_item">{taskType.label}</div>
                                                        <div className="row_item">
                                                            {budget ? `${numberWithSpaces(budget)} руб.` : "-"}
                                                        </div>
                                                        <div className="row_item">
                                                            {moment(creation_date).format("DD.MM.YYYY HH:mm")}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {count > 10 && (
                            <div className="pagination_block">
                                <Pagination
                                    active={activePage - 1}
                                    pageCount={Math.ceil(count / 10)}
                                    onChange={e => {
                                        window.scrollTo({ top: 0, behavior: "smooth" });
                                        this.changePage(e.selected + 1);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </TransitionedBlock>
        );
    }
}

const mapStateToProps = ({ deals }) => {
    return {
        dealsList: deals.dealsList
    };
};
const mapDispatchToProps = {
    getDeals
};

export default connect(mapStateToProps, mapDispatchToProps)(DealsPage);
