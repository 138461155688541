import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import { dealsStatuses } from "../../../helpers/constants";

class HistoryBlock extends Component {
    renderLine = (currentStatus, previousStatus, user) => {
        const {
            profile_data: { first_name, last_name }
        } = this.props;
        const you = `${first_name} ${last_name}` === user;
        const name = you ? "Вы" : user;
        let dealFlowText = "";
        if ((currentStatus.value === previousStatus.value) && currentStatus.value !== 'awaiting_verification') {
            if (currentStatus.value === "in_review") {
                dealFlowText = "Создается сделка со статусом «На рассмотрении».";
            } else {
                dealFlowText = "Создается сделка со статусом «Утверждение условий».";
            }
        } else if (currentStatus.value === "arbitration") {
            dealFlowText = "Статус сделки не изменен.";
        } else {
            dealFlowText = `Статус сделки изменен с «${currentStatus.value === 'awaiting_verification' && previousStatus.value === 'awaiting_verification' ? '«В процессе»' :  previousStatus.label}» на «${currentStatus.label}».`;
        }
        if (currentStatus.value === "in_review") {
            return (
                <span>
                    <p>{name}</p> {you ? "подали" : "подает"} заявку. {dealFlowText}
                </span>
            );
        } else if (currentStatus.value === "denied") {
            return (
                <span>
                    <p>{name}</p> переводит{you ? "е" : ""} сделку со статуса «На рассмотрении» в «Отменена».
                </span>
            );
        } else if (previousStatus.value === "in_review" && currentStatus.value === "conditions_approval") {
            return (
                <span>
                    <p>{name}</p> отправляет{you ? "е" : ""} условия сделки. {dealFlowText}
                </span>
            );
        } else if (previousStatus.value === "conditions_approval" && currentStatus.value === "conditions_approval") {
            return <span>{dealFlowText}</span>;
        } else if (previousStatus.value === "conditions_approval" && currentStatus.value === "awaiting_payment") {
            return (
                <span>
                    <p>{name}</p> {you ? "приняли" : "принимает"} условия сделки. {dealFlowText}
                </span>
            );
        } else if (previousStatus.value === "awaiting_payment" && currentStatus.value === "in_progress") {
            return (
                <span>
                    <p>{name}</p> {you ? "подтвердили" : "подтверждает"} оплату. {dealFlowText}
                </span>
            );
        } else if ( currentStatus.value === "awaiting_verification") {
            return (
                <span>
                    <p>{name}</p> {you ? "выполнили" : "выполняет"} задание. {dealFlowText}
                </span>
            );
        } else if (previousStatus.value === "awaiting_verification" && currentStatus.value === "guarantor_check") {
            return (
                <span>
                    <p>{name}</p> {you ? "подтвердили" : "подтверждает"} выполнение задания. {dealFlowText}
                </span>
            );
        } else if (previousStatus.value === "awaiting_verification" && currentStatus.value === "in_progress") {
            return (
                <span>
                    <p>{name}</p> {you ? "отправили" : "отправляет"} задание на доработку. {dealFlowText}
                </span>
            );
        } else if (previousStatus.value === "guarantor_check" && currentStatus.value === "arbitration") {
            return (
                <span>
                    <p>{name}</p> {you ? "открыли" : "открывает"} арбитраж. {dealFlowText}
                </span>
            );
        } else if (
            (previousStatus.value === "guarantor_check" || previousStatus.value === "arbitration") &&
            currentStatus.value === "done"
        ) {
            return (
                <span>
                    <p>{name}</p> {you ? "подтвердили" : "подтверждает"} гарант. {dealFlowText}
                </span>
            );
        }
    };

    render() {
        const { history } = this.props;
        return (
            <div className="deal_history">
                <div className="title">История сделки</div>
                <div className="status_history_wrapper">
                    {history.map(({ current_type, date, previous_type, user }, key) => {
                        const previousStatus = dealsStatuses.find((el) => el.value === previous_type) || {
                            label: "Проверка гаранта",
                            value: "arbitration",
                            color: "#6AC798"
                        };
                        const currentStatus = dealsStatuses.find((el) => el.value === current_type) || {
                            label: "Проверка гаранта",
                            value: "arbitration",
                            color: "#6AC798"
                        };
                        return (
                            <div className="status_history" key={`history_item_${key}`}>
                                <div className="info_status" style={{ border: `2px solid ${currentStatus.color}` }} />
                                <div className="text">
                                    <div>{moment(date).format("DD.MM.YYYY HH:mm")}</div>
                                    {this.renderLine(currentStatus, previousStatus, user)}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        profile_data: state.profile.profile_data
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryBlock);
