import React from "react";
import { Link } from "react-router-dom";

import TransitionedBlock from "../../../HelperComponents/TransitionedBlock/TransitionedBlock";

import lock_large from "../../../../assets/image/lock_large.png";

const FourthStep = () => (
    <TransitionedBlock>
        <div className="authentication-form">
            <div className="title_form">Восстановление пароля</div>
            <div className="authentication-content">
                <div className="form_auth fourth_step">
                    <div className="text_info">
                        Ваш пароль успешно восстановлен! Теперь Вы можете войти в свою учетную запись.
                    </div>
                    <div className="box_icon_aut">
                        <img src={lock_large} alt="lock_large" />
                    </div>
                    <Link className="green_btn_big" to={`/auth`}>
                        Войти
                    </Link>
                </div>
            </div>
            <div className="info_aut"></div>
        </div>
    </TransitionedBlock>
);

export default FourthStep;
