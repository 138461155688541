import React, { Fragment } from "react";
import { Field } from "redux-form";
import RenderCheckField from "../components/HelperComponents/RenderField/RenderCheckField/RenderCheckField";
import arrow from "../assets/image/up_arrow.png";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export const configureSentry = ({ dsn, dev, prod }) => {
    const host = window.location.host;
    let environment;
    if (host.includes(dev)) environment = "development";
    if (host.includes(prod)) environment = "production";

    if (!host.includes("localhost")) {
        return Sentry.init({
            dsn,
            autoSessionTracking: true,
            integrations: [new Integrations.BrowserTracing()],
            tracesSampleRate: 1.0,
            environment
        });
    }
};

export function getOption(label) {
    return (
        <div className="status_select">
            <div>{label}</div>
        </div>
    );
}

export function getSortOption(label, by) {
    return (
        <div className="status_select">
            <div className={by}>
                <img src={arrow} alt="arrow" />
            </div>
            <div>{label}</div>
        </div>
    );
}

export function getPrices(blogger) {
    return (
        <div className="info-price">
            {blogger.price_list && blogger.price_list.video_integration && (
                <div className="price">{numberWithSpaces(blogger.price_list.video_integration)} руб.</div>
            )}
            <div className="tooltip">
                {blogger.price_list.society_link && (
                    <div>
                        <div>Ссылка в сообществе канала</div>
                        <span>{numberWithSpaces(blogger.price_list.society_link)} руб.</span>
                    </div>
                )}
                {blogger.price_list.customized_video && (
                    <div>
                        <div>Заказной ролик</div>
                        <span>{numberWithSpaces(blogger.price_list.customized_video)} руб.</span>
                    </div>
                )}
                {blogger.price_list.stories_ad && (
                    <div>
                        <div>Реклама в YouTube-истории</div>
                        <span>{numberWithSpaces(blogger.price_list.stories_ad)} руб.</span>
                    </div>
                )}
                {(blogger.price_list.middle || blogger.price_list.postroll || blogger.price_list.preroll) && (
                    <div className="title">Реклама в видео:</div>
                )}
                {blogger.price_list.preroll && (
                    <div>
                        <div className="light">- в начале («преролл»)</div>
                        <span>{numberWithSpaces(blogger.price_list.preroll)} руб.</span>
                    </div>
                )}
                {blogger.price_list.middle && (
                    <div>
                        <div className="light">- в середине</div>
                        <span>{numberWithSpaces(blogger.price_list.middle)} руб.</span>
                    </div>
                )}
                {blogger.price_list.postroll && (
                    <div>
                        <div className="light">- в конце («постролл»)</div>
                        <span>{numberWithSpaces(blogger.price_list.postroll)} руб.</span>
                    </div>
                )}
            </div>
        </div>
    );
}

export function splitMaleData(arr) {
    let dataMale = [];
    if (arr.length === 0) {
        dataMale.push(0);
    } else if (arr.length === 1) {
        if (arr[0][1] === "male") {
            dataMale.push(Math.abs(arr[0][2]));
        }
    } else {
        if (arr[0][1] === "male") {
            dataMale.push(Math.abs(arr[0][2]));
        }
        if (arr[1][1] === "male") {
            dataMale.push(Math.abs(arr[1][2]));
        }
    }
    return dataMale;
}

export function splitFemaleData(arr) {
    let dataFemale = [];
    if (arr.length === 0) {
        dataFemale.push(0);
    } else if (arr.length === 1) {
        if (arr[0][1] === "female") {
            dataFemale.push(-Math.abs(arr[0][2]));
        }
    } else {
        if (arr[0][1] === "female") {
            dataFemale.push(-Math.abs(arr[0][2]));
        }
        if (arr[1][1] === "female") {
            dataFemale.push(-Math.abs(arr[1][2]));
        }
    }
    return dataFemale;
}

export function renderCountriesList() {
    return (
        <Fragment>
            <div className="filter_labels">
                <label>
                    <Field name="RU" component={RenderCheckField} type="checkbox" className="inp-checkbox" />
                    <span />
                    Россия
                </label>
            </div>
            <div className="filter_labels">
                <label>
                    <Field name="UA" component={RenderCheckField} type="checkbox" className="inp-checkbox" />
                    <span />
                    Украина
                </label>
            </div>
            <div className="filter_labels">
                <label>
                    <Field name="BY" component={RenderCheckField} type="checkbox" className="inp-checkbox" />
                    <span />
                    Беларусь
                </label>
            </div>
            <div className="filter_labels">
                <label>
                    <Field name="KZ" component={RenderCheckField} type="checkbox" className="inp-checkbox" />
                    <span />
                    Казахстан
                </label>
            </div>
            <div className="filter_labels">
                <label>
                    <Field name="US" component={RenderCheckField} type="checkbox" className="inp-checkbox" />
                    <span />
                    США
                </label>
            </div>
        </Fragment>
    );
}

export function renderAgeRadio() {
    return (
        <div className="filter_choices">
            <label>
                <Field name="age" component="input" type="radio" value="" />
                Все
            </label>
            <label>
                <Field name="age" component="input" type="radio" value="13-17" />
                13 - 17 лет
            </label>
            <label>
                <Field name="age" component="input" type="radio" value="18-24" />
                18 - 24 лет
            </label>
            <label>
                <Field name="age" component="input" type="radio" value="25-34" />
                25 - 34 лет
            </label>
            <label>
                <Field name="age" component="input" type="radio" value="35-44" />
                35 - 44 лет
            </label>
            <label>
                <Field name="age" component="input" type="radio" value="45-54" />
                45 - 54 лет
            </label>
            <label>
                <Field name="age" component="input" type="radio" value="55-64" />
                55 - 64 лет
            </label>
            <label>
                <Field name="age" component="input" type="radio" value="65-" />
                65 лет и старше
            </label>
        </div>
    );
}

export function renderActivityRadio() {
    return (
        <div className="filter_choices">
            <label>
                <Field name="activity" component="input" type="radio" value="" />
                Любой
            </label>
            <label>
                <Field name="activity" component="input" type="radio" value="1" />
                Высокий
            </label>
            <label>
                <Field name="activity" component="input" type="radio" value="2" />
                Средний
            </label>
            <label>
                <Field name="activity" component="input" type="radio" value="3" />
                Низкий
            </label>
        </div>
    );
}

export function renderInvolvementRadio() {
    return (
        <div className="filter_choices">
            <label>
                <Field name="involvement" component="input" type="radio" value="" />
                Любой
            </label>
            <label>
                <Field name="involvement" component="input" type="radio" value="1" />
                Высокий
            </label>
            <label>
                <Field name="involvement" component="input" type="radio" value="2" />
                Средний
            </label>
            <label>
                <Field name="involvement" component="input" type="radio" value="3" />
                Низкий
            </label>
        </div>
    );
}

export function renderApprobationRadio() {
    return (
        <div className="filter_choices">
            <label>
                <Field name="approbation" component="input" type="radio" value="" />
                Любой
            </label>
            <label>
                <Field name="approbation" component="input" type="radio" value="1" />
                Высокий
            </label>
            <label>
                <Field name="approbation" component="input" type="radio" value="2" />
                Средний
            </label>
            <label>
                <Field name="approbation" component="input" type="radio" value="3" />
                Низкий
            </label>
        </div>
    );
}

export function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const joinQueries = (arr) => `${arr.length && arr.length !== 0 ? "?" : ""}${arr.join("&")}`;

export const getTransactionType = (type, deal_id) => {
    switch (type) {
        case "bank_refill":
            return "Пополнение через банковскую карту";
        case "bank_withdrawal":
            return "Вывод через банковскую карту";
        case "deal":
            if (deal_id) {
                return `по сделке #${deal_id}`;
            } else {
                return `по сделке`;
            }
        default:
            return "-";
    }
};

export const getTransaction = (type) => {
    switch (type) {
        case "refill":
            return "Пополнение";
        case "payment":
            return "Оплата";
        case "refund":
            return "Возврат";
        case "reserved":
            return "Зарезервировано";
        case "reserved_refund":
            return "Возврат из резерва";
        case "withdrawal":
            return "Вывод";
        default:
            return "-";
    }
};

export const getTransactionTextStyle = (type) => {
    if (type === "refill" || type === "refund" || type === "reserved_refund") {
        return "text_green";
    } else {
        return "text_red";
    }
};

export function splitByCommas(data) {
    return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
