import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import deleteIcon from "../../assets/image/delete.svg";
import AboutChannel from "../../components/Blogger/AboutChannel/AboutChannel";
import AboutChannelBlogger from "../../components/Blogger/AboutChannelBlogger/AboutChannelBlogger";
import LectureHall from "../../components/Blogger/LectureHall/LectureHall";
import Pricelist from "../../components/Blogger/Pricelist/Pricelist";
import Reviews from "../../components/Blogger/Reviews/Reviews";
import Statistics from "../../components/Blogger/Statistics/Statistics";
import delete_icon from "../../assets/image/delete_large.png";
import back_arrow from "../../assets/image/back_arrow.png";
import youtube_icon from "../../assets/image/youtube_icon.png";
import delete_blogger from "../../assets/image/delete.svg";
import delete_large from "../../assets/image/delete_large.svg";
import {
    deleteBloggerAction,
    getBloggerPriceList,
    getBloggersAbout,
    getBloggerHeader,
    deleteBlogerChannel
} from "../../actions/bloggerAction";
import { getProfile } from "../../actions/profileActions";
import { numberWithSpaces } from "../../helpers/functions";

import "./Blogger.scss";

class Blogger extends Component {
    state = {
        loading: true,
        tab: "0",
        price_list: {},
        blogger_about: {},
        blogger_statistics: {},
        blogger_header: {},
        open: false,
        openDeleteDialog: false
    };

    componentDidMount() {
        this.doRequest();
    }

    doRequest = () => {
        const {
            match: {
                params: { id }
            },
            match,
            getBloggerPriceList,
            getBloggersAbout,
            getBloggerHeader
        } = this.props;
        // const bloggerInterface = match.url.includes("my-page");

        this.setState({ loading: true });
        getBloggerPriceList(id, "price-list/").then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ price_list: res.payload.data });
                getBloggersAbout(id).then(res => {
                    if (res.payload && res.payload.status && res.payload.status === 200) {
                        this.setState({ blogger_about: res.payload.data });
                        getBloggerHeader(id).then(res => {
                            if (res.payload && res.payload.status && res.payload.status === 200) {
                                this.setState({ loading: false, blogger_header: res.payload.data });
                            }
                        });
                    }
                });
            }
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.doRequest();
        }
    }

    changeTab = tab => {
        this.setState({ tab });
    };

    toggleDialog = () => {
        this.setState(({ open }) => ({
            open: !open
        }));
    };

    toggleDeleteDialog = () => {
        this.setState(({ openDeleteDialog }) => ({
            openDeleteDialog: !openDeleteDialog
        }));
    };

    deleteCampaign = () => {
        const {
            history,
            deleteBlogerChannel,
            getProfile,
            match: { params }
        } = this.props;
        deleteBlogerChannel(params.id).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 204) {
                getProfile();
                this.setState(({ openDeleteDialog }) => ({
                    openDeleteDialog: !openDeleteDialog
                }));
                history.push("/main");
            }
        });
    };

    deleteBlogger = () => {
        const { deleteBloggerAction, getProfile, history } = this.props;
        deleteBloggerAction().then(res => {
            getProfile();
            this.toggleDialog();
            history.push("/main/add-channel/");
        });
    };

    render() {
        const { match, my_id } = this.props;
        const { open, tab, loading, price_list, blogger_header, openDeleteDialog } = this.state;
        const bloggerInterface = match.url.includes("my-page");
        if (loading) return null;

        return (
            <div className="content_block_page blogger_wrapper">
                {!bloggerInterface && (
                    <div className="go_back">
                        <Link to="/main" className="">
                            <img src={back_arrow} alt="back_arrow" />
                            Назад
                        </Link>
                    </div>
                )}
                <div className="blogger_info">
                    <div className="blogger_info_name">
                        <div className="box_icon">
                            <img src={blogger_header.img} alt="" />
                        </div>
                        <div className="info">
                            <div>{blogger_header.name}</div>
                            <a href={blogger_header.link} className="">
                                <img src={youtube_icon} alt="youtube_icon" />
                                Перейти на канал
                            </a>
                        </div>
                        {+my_id === +match.params.id && bloggerInterface ? (
                            <button className="delete_blogger" onClick={this.toggleDialog}>
                                <img src={delete_blogger} alt="delete_blogger" />
                            </button>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="menu_compaing">
                        <button className="delete_btn" onClick={this.toggleDeleteDialog}>
                            <img src={deleteIcon} alt="deleteIcon" />
                        </button>
                    </div>
                </div>
                <div className="info_wrapper">
                    <div>
                        <span>{numberWithSpaces(blogger_header.subscribers)}</span>
                        <p>Подписчиков</p>
                    </div>
                    <div>
                        <span>{numberWithSpaces(blogger_header.avg_views)}</span>
                        <p>Просмотров на 1 видео</p>
                    </div>
                </div>
                <div className="tab_blogger">
                    <button className={tab === "0" ? "active" : ""} onClick={() => this.changeTab("0")}>
                        Прайс-лист
                    </button>
                    <button className={tab === "1" ? "active" : ""} onClick={() => this.changeTab("1")}>
                        О канале
                    </button>
                    <button className={tab === "2" ? "active" : ""} onClick={() => this.changeTab("2")}>
                        Отзывы
                    </button>
                    <button className={tab === "3" ? "active" : ""} onClick={() => this.changeTab("3")}>
                        Аудитория
                    </button>
                    <button className={tab === "4" ? "active" : ""} onClick={() => this.changeTab("4")}>
                        Статистика
                    </button>
                </div>
                {tab === "0" && (
                    <Pricelist channelId={match.params.id} bloggerInterface={bloggerInterface} price_list={price_list} match={this.props.match} />
                )}
                {!bloggerInterface
                    ? tab === "1" && <AboutChannel aboutTabContent={this.state.blogger_about} />
                    : tab === "1" && (
                          <AboutChannelBlogger id={match.params.id} aboutTabContent={this.state.blogger_about} />
                      )}
                {tab === "2" && <Reviews id={this.props.match.params.id} />}
                {tab === "3" && <LectureHall id={match.params.id} />}
                {tab === "4" && <Statistics id={match.params.id} />}

                <DialogComponent open={open} onClose={this.toggleDialog}>
                    <div className="delete_blogger_dialog">
                        <img src={delete_large} alt="delete_large" />
                        <span>Вы уверены, что хотите удалить канал?</span>
                        <div className="btn_wrapper">
                            <button className="cancel_btn" onClick={this.toggleDialog}>
                                Нет
                            </button>
                            <button className="red_btn" onClick={this.deleteBlogger}>
                                Да
                            </button>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={openDeleteDialog} onClose={this.toggleDeleteDialog}>
                    <div className="delete_deal">
                        <div className="box_icon">
                            <img src={delete_icon} alt="cancel_icon" />
                        </div>
                        <div className="title">Вы уверены, что хотите удалить канал?</div>
                        <hr />
                        <div className="btn_wrapper">
                            <button className="cancel_btn" onClick={this.toggleDeleteDialog}>
                                Нет
                            </button>
                            <button className="red_btn" onClick={this.deleteCampaign}>
                                Да
                            </button>
                        </div>
                    </div>
                </DialogComponent>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        my_id: state.profile.id
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getBloggerPriceList,
            deleteBloggerAction,
            getBloggersAbout,
            getBloggerHeader,
            getProfile,
            deleteBlogerChannel
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Blogger);
