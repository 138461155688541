import { takeEvery } from "redux-saga/effects";
// import { getProfile } from "../actions/profileActions";
import {
    disableButtonLoader,
    disableLoader,
    enableButtonLoader,
    enableLoader,
    openErrorSnack,
    openSuccessSnack
} from "./index";

export function* handleDealsActions() {
    // yield takeEvery("PATCH_PROFILE_SUCCESS", fetchProfile);
}

// function* fetchProfile() {
//     yield put(getProfile());
// }

export function* handleDealsLoader() {
    yield takeEvery("POST_BLOGGER_DEAL_CREATE", enableButtonLoader);
    yield takeEvery("POST_BLOGGER_DEAL_CREATE_SUCCESS", disableButtonLoader);
    yield takeEvery("POST_BLOGGER_DEAL_CREATE_FAIL", disableButtonLoader);

    yield takeEvery("GET_DEALS", enableLoader);
    yield takeEvery("GET_DEALS_SUCCESS", disableLoader);
    yield takeEvery("GET_DEALS_FAIL", disableLoader);

    yield takeEvery("POST_MESSAGE", enableButtonLoader);
    yield takeEvery("POST_MESSAGE_SUCCESS", disableButtonLoader);
    yield takeEvery("POST_MESSAGE_FAIL", disableButtonLoader);

    yield takeEvery("POST_REVIEW", enableButtonLoader);
    yield takeEvery("POST_REVIEW_SUCCESS", disableButtonLoader);
    yield takeEvery("POST_REVIEW_FAIL", disableButtonLoader);

    yield takeEvery("PATCH_CURRENT_DEAL", enableButtonLoader);
    yield takeEvery("PATCH_CURRENT_DEAL_SUCCESS", disableButtonLoader);
    yield takeEvery("PATCH_CURRENT_DEAL_FAIL", disableButtonLoader);

    yield takeEvery("PATCH_DEAL_WITH_VIDEO", enableButtonLoader);
    yield takeEvery("PATCH_DEAL_WITH_VIDEO_SUCCESS", disableButtonLoader);
    yield takeEvery("PATCH_DEAL_WITH_VIDEO_FAIL", disableButtonLoader);

    yield takeEvery("PATCH_DEAL_CONDITIONS", enableButtonLoader);
    yield takeEvery("PATCH_DEAL_CONDITIONS_SUCCESS", disableButtonLoader);
    yield takeEvery("PATCH_DEAL_CONDITIONS_FAIL", disableButtonLoader);

    yield takeEvery("PATCH_DEAL_ARBITRATION", enableButtonLoader);
    yield takeEvery("PATCH_DEAL_ARBITRATION_SUCCESS", disableButtonLoader);
    yield takeEvery("PATCH_DEAL_ARBITRATION_FAIL", disableButtonLoader);

    yield takeEvery("POST_WITHDRAWAL_INFO", enableButtonLoader);
    yield takeEvery("POST_WITHDRAWAL_INFO_SUCCESS", disableButtonLoader);
    yield takeEvery("POST_WITHDRAWAL_INFO_FAIL", disableButtonLoader);
}

export function* handleDealsSnacks() {
    yield takeEvery("PATCH_CURRENT_DEAL_FAIL", openErrorSnack);
    yield takeEvery("POST_BLOGGER_DEAL_CREATE_SUCCESS", () => openSuccessSnack("Сделка успешно создана"));
    yield takeEvery("POST_WITHDRAWAL_INFO_FAIL", openErrorSnack);
}
