import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import clsx from "clsx";
import { Field, reduxForm } from "redux-form";

import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import { numberWithSpaces } from "../../../helpers/functions";
import { renderField } from "../../CampaingPage/CampaingPage";
import Calendar from "../../HelperComponents/Calendar/Calendar";
import { regExpNumber } from "../../../helpers/validation";
import Loader from "../../HelperComponents/Loader/Loader";
import AcceptDealForm from "./AcceptDealForm";
import ConfirmDealForm from "./ConfirmDealForm";
import DocumentsList from "../../HelperComponents/DocumentsList/DocumentsList";

import {
    getCurrentDeal,
    patchCurrentDeal,
    patchDealArbitration,
    patchDealConditions,
    patchDealWithVideo,
    resetDealErrors
} from "../../../actions/dealsActions";

import success_icon from "../../../assets/image/success_icon.svg";
import cancel_icon from "../../../assets/image/cancel_icon.png";
import Shape from "../../../assets/image/Shape.svg";
import help from "../../../assets/image/help.svg";

import "./StepperBlock.scss";
import ArbitrationForm from "./ArbitrationForm";

class StepperBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openCancelDialog: false,
            openApproveDialog: false,
            openAcceptDialog: false,
            openConfirmDialog: false,
            openCompletionDialog: false,
            openReworkDialog: false,
            openGuarantorDialog: false,
            openArbitrationDialog: false,
            isDisabled: false,
            date: null,
            documentsReviewed: false,
            steps: [
                { label: "На рассмотрении", value: "in_review", color: "#BD10E0", classes: "waiting" },
                { label: "Утверждение условий", value: "conditions_approval", color: "#74E0F5", classes: "inactive" },
                { label: "Ожидает оплаты", value: "awaiting_payment", color: "#417505", classes: "inactive" },
                { label: "В процессе", value: "in_progress", color: "#4A90E2", classes: "inactive" },
                { label: "Ожидает проверки", value: "awaiting_verification", color: "#F5A623", classes: "inactive" },
                { label: "Проверка гаранта", value: "guarantor_check", color: "#6AC798", classes: "inactive" },
                { label: "Завершена", value: "done", color: "#5CE6CD", classes: "inactive" }
            ]
        };
    }

    toggleDialog = (type) => {
        switch (type) {
            case "cancel_dialog":
                this.setState(({ openCancelDialog }) => ({
                    openCancelDialog: !openCancelDialog
                }));
                break;
            case "approve_dialog":
                this.setState(({ openApproveDialog }) => ({
                    openApproveDialog: !openApproveDialog
                }));
                break;
            case "accept_dialog":
                this.setState(({ openAcceptDialog }) => ({
                    openAcceptDialog: !openAcceptDialog
                }));
                break;
            case "confirm_dialog":
                this.props.resetDealErrors();
                this.setState(({ openConfirmDialog }) => ({
                    openConfirmDialog: !openConfirmDialog
                }));
                break;
            case "completion_dialog":
                this.props.resetDealErrors();
                this.setState(({ openCompletionDialog }) => ({
                    openCompletionDialog: !openCompletionDialog
                }));
                break;
            case "rework_dialog":
                this.props.resetDealErrors();
                this.setState(({ openReworkDialog }) => ({
                    openReworkDialog: !openReworkDialog
                }));
                break;
            case "guarantor_dialog":
                this.setState(({ openGuarantorDialog }) => ({
                    openGuarantorDialog: !openGuarantorDialog
                }));
                break;
            case "arbitration_dialog":
                this.setState(({ openArbitrationDialog }) => ({
                    openArbitrationDialog: !openArbitrationDialog
                }));
                break;
            default:
                break;
        }
    };

    handleDateChange = (date) => {
        function formatDate(date) {
            let d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        }

        if (date !== null) {
            if (date.toString() !== "Invalid Date") {
                this.setState({ date: formatDate(date), isDisabled: false });
            } else {
                this.setState({ date: formatDate(date), isDisabled: true });
            }
        } else {
            this.setState({ date: null, isDisabled: false });
        }
    };

    cancelDeal = async () => {
        const { patchCurrentDeal, dealId } = this.props;
        await patchCurrentDeal(dealId, { status: "denied" });
        this.toggleDialog("cancel_dialog");
    };

    approveDeal = async (data) => {
        const { patchDealConditions, getCurrentDeal, dealId, deadline } = this.props;
        const { date } = this.state;
        const { guarantor, sum } = data;
        await patchDealConditions(dealId, { deadline: date || deadline, guarantor, sum });
        getCurrentDeal(dealId);
        this.toggleDialog("approve_dialog");
    };

    acceptDeal = async () => {
        const { patchCurrentDeal, dealId } = this.props;
        await patchCurrentDeal(dealId, { status: "awaiting_payment" });
        this.toggleDialog("accept_dialog");
    };

    confirmDeal = async (data) => {
        const { getCurrentDeal, patchDealWithVideo, dealId } = this.props;
        const res = await patchDealWithVideo(dealId, data);
        if (res.payload) {
            getCurrentDeal(dealId);
            this.toggleDialog("confirm_dialog");
        }
    };

    confirmCompletionDeal = async () => {
        const { patchCurrentDeal, dealId } = this.props;
        await patchCurrentDeal(dealId, { status: "guarantor_check" });
        this.toggleDialog("completion_dialog");
    };

    reworkDeal = async () => {
        const { patchCurrentDeal, dealId } = this.props;
        await patchCurrentDeal(dealId, { status: "in_progress" });
        this.toggleDialog("rework_dialog");
    };

    arbitrateDeal = async (data) => {
        const { patchDealArbitration, getCurrentDeal, dealId } = this.props;
        await patchDealArbitration(dealId, data);
        getCurrentDeal(dealId);
        this.toggleDialog("arbitration_dialog");
    };

    finishDeal = async () => {
        const { patchCurrentDeal, dealId } = this.props;
        await patchCurrentDeal(dealId, { status: "done" });
        this.toggleDialog("guarantor_dialog");
    };

    renderStepper = () => {
        const {
            task: { task_type },
            status,
            complete_date,
            creation_date
        } = this.props;
        let steps = [...this.state.steps];
        switch (status) {
            case "Отменен":
                steps = steps.filter((el) => el.value !== "in_review");
                steps.unshift({ label: "Сделка отменена", value: "denied", classes: "error" });
                break;
            case "Утверждение условий":
                steps[0]["classes"] = "active";
                steps[1]["classes"] = "waiting";
                break;
            case "Ожидают оплаты":
                for (let i = 0; i < 2; i++) {
                    steps[i]["classes"] = "active";
                }
                steps[2]["classes"] = "waiting";
                break;
            case "В процессе":
                for (let i = 0; i < 3; i++) {
                    steps[i]["classes"] = "active";
                }
                steps[3]["classes"] = "waiting";
                steps[4]["classes"] = "inactive";
                break;
            case "Ожидают проверки":
                for (let i = 0; i < 4; i++) {
                    steps[i]["classes"] = "active";
                }
                steps[4]["classes"] = "waiting";
                steps[5]["classes"] = "inactive";
                break;
            case "Проверка гаранта":
                for (let i = 0; i < 5; i++) {
                    steps[i]["classes"] = "active";
                }
                steps[5]["classes"] = "waiting";
                break;
            case "Арбитраж":
                for (let i = 0; i < 5; i++) {
                    steps[i]["classes"] = "active";
                }
                steps[5]["classes"] = "waiting";
                break;
            case "Завершен":
                for (let i = 0; i <= 6; i++) {
                    steps[i]["classes"] = "active";
                }
                steps[6]["classes"] = "completed";
                break;
            default:
                break;
        }
        return (
            <div className="stepper">
                <div className="date">{moment(creation_date).format("DD.MM.YYYY")}</div>
                {steps
                    .filter((element) => element.value !== task_type)
                    .map(({ value, label, classes }) => (
                        <div className={clsx("step", classes)} key={value}>
                            <img src={success_icon} alt="ok_icon" />
                            <span>{label}</span>
                        </div>
                    ))}
                {complete_date && <div className="date">{moment(complete_date).format("DD.MM.YYYY")}</div>}
            </div>
        );
    };

    renderButtons = () => {
        const {
            status,
            arbitration,
            guarantor_check_start_date,
            bloggerInterface,
            rework_count,
            awaiting_verification_start_date
        } = this.props;
        const today = moment(new Date());
        const guarantorBeginningDay = moment(guarantor_check_start_date);
        const dealBeginDate = moment(awaiting_verification_start_date);
        const verificationIsAvailable =
            !!awaiting_verification_start_date && Math.abs(today.diff(dealBeginDate, "hours")) >= 72;
        const arbitrationIsAvailable =
            !!guarantor_check_start_date && Math.abs(today.diff(guarantorBeginningDay, "days")) >= 7;
        switch (status) {
            case "На рассмотрении":
                return (
                    <div className="btn_wrapper">
                        {!bloggerInterface && (
                            <button className="border_btn_green" onClick={() => this.toggleDialog("approve_dialog")}>
                                Согласовать
                            </button>
                        )}
                        <button onClick={() => this.toggleDialog("cancel_dialog")} className="red_btn">
                            Отменить
                        </button>
                    </div>
                );
            case "Утверждение условий":
                return (
                    <div className="btn_wrapper">
                        {bloggerInterface && (
                            <button className="border_btn_green" onClick={() => this.toggleDialog("accept_dialog")}>
                                Принять
                            </button>
                        )}
                        <button onClick={() => this.toggleDialog("cancel_dialog")} className="red_btn">
                            Отменить
                        </button>
                    </div>
                );
            case "В процессе":
                return (
                    <div className="btn_wrapper">
                        {bloggerInterface && (
                            <button className="border_btn_green" onClick={() => this.toggleDialog("confirm_dialog")}>
                                На проверку
                            </button>
                        )}
                    </div>
                );
            case "Ожидают проверки":
                return (
                    <div className="btn_wrapper">
                        {!bloggerInterface && (
                            <Fragment>
                                <button
                                    className="border_btn_green"
                                    onClick={() => this.toggleDialog("completion_dialog")}
                                >
                                    Утвердить
                                </button>
                                {rework_count < 2 ? (
                                    <button onClick={() => this.toggleDialog("rework_dialog")} className="cancel_btn">
                                        На доработку
                                    </button>
                                ) : arbitration ? (
                                    <button disabled className="cancel_btn">
                                        На арбитраже
                                    </button>
                                ) : (
                                    <button
                                        onClick={() => this.toggleDialog("arbitration_dialog")}
                                        className="cancel_btn"
                                    >
                                        Арбитраж
                                    </button>
                                )}
                            </Fragment>
                        )}
                        {bloggerInterface && verificationIsAvailable && (
                            <Fragment>
                                <button
                                    className="border_btn_green"
                                    onClick={() => this.toggleDialog("completion_dialog")}
                                >
                                    Утвердить
                                </button>
                            </Fragment>
                        )}
                    </div>
                );
            case "Проверка гаранта":
                return (
                    <div className="btn_wrapper">
                        {!bloggerInterface && (
                            <button className="border_btn_green" onClick={() => this.toggleDialog("guarantor_dialog")}>
                                Утвердить
                            </button>
                        )}
                        {!bloggerInterface && arbitrationIsAvailable && !arbitration && (
                            <button onClick={() => this.toggleDialog("arbitration_dialog")} className="cancel_btn">
                                Арбитраж
                            </button>
                        )}
                        {arbitration && (
                            <button disabled className="cancel_btn">
                                На арбитраже
                            </button>
                        )}
                    </div>
                );
            case "Арбитраж":
                return (
                    <div className="btn_wrapper">
                        {!bloggerInterface && verificationIsAvailable && (
                            <button className="border_btn_green" onClick={() => this.toggleDialog("guarantor_dialog")}>
                                Утвердить
                            </button>
                        )}
                        <button disabled className="cancel_btn">
                            На арбитраже
                        </button>
                    </div>
                );
            default:
                return <div className="btn_wrapper without_buttons" />;
        }
    };

    render() {
        const {
            task: { task_type },
            sum,
            deadline,
            guarantor,
            buttonLoading,
            handleSubmit,
            valid,
            rework_count,
            deal: { payment_ib_file, act_ib_file, payment_im_file, act_im_file }
        } = this.props;
        const {
            openCancelDialog,
            openApproveDialog,
            openAcceptDialog,
            openConfirmDialog,
            openCompletionDialog,
            openReworkDialog,
            openGuarantorDialog,
            openArbitrationDialog,
            isDisabled,
            date,
            documentsReviewed
        } = this.state;
        return (
            <div className="stepper_block">
                <div className="panel_stepper">
                    <div className="text">
                        <span>{task_type}</span>
                        {sum && <p>{numberWithSpaces(sum)} руб.</p>}
                    </div>
                    <div className="btn_block">
                        {!!guarantor && (
                            <Fragment>
                                <div className="deadline">
                                    <span className="guarantor">Гарант</span>
                                    <div className="box_tooltip">
                                        <img src={help} alt="help" />
                                        <div className="tooltip">
                                            Минимально гарантированное число просмотров за указанный бюджет на 7-й день
                                            после публикации контента
                                        </div>
                                    </div>
                                    <p>{guarantor}</p>
                                </div>
                                <div className="separator" />
                            </Fragment>
                        )}
                        {!!deadline && (
                            <div className="deadline">
                                <span>
                                    <img src={Shape} alt="Shape" /> Дедлайн
                                </span>
                                <p>{moment(deadline).format("DD.MM.YYYY")}</p>
                            </div>
                        )}
                    </div>
                </div>
                {this.renderStepper()}
                {this.renderButtons()}
                <DialogComponent open={openCancelDialog} onClose={() => this.toggleDialog("cancel_dialog")}>
                    <div className="stepper_dialog">
                        <div className="box_icon">
                            <img src={cancel_icon} alt="cancel_icon" />
                        </div>
                        <div className="title">Вы уверены, что хотите отменить сделку?</div>
                        <div className="btn_wrapper">
                            <button className="cancel_btn" onClick={() => this.toggleDialog("cancel_dialog")}>
                                Нет
                            </button>
                            <button className="border_btn_green" onClick={this.cancelDeal}>
                                {buttonLoading ? (
                                    <Loader height={20} width={2} margin="2px" color={"#FFF"} />
                                ) : (
                                    "Отправить"
                                )}
                            </button>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={openApproveDialog} onClose={() => this.toggleDialog("approve_dialog")}>
                    <div className="stepper_dialog">
                        <div className="big_title">Согласовать условия</div>
                        <div className="description">
                            Убедитесь, что все условия указаны правильно или отредактируйте их, если нужно
                        </div>
                        <form className="block_info_blogger" onSubmit={handleSubmit(this.approveDeal)}>
                            <div className="block_info_forms">
                                <div className="price">
                                    <div>Сумма</div>
                                    <Field name="sum" component={renderField} type="number" max="999999999" />
                                    <span>руб.</span>
                                </div>
                                <div className="price">
                                    <div>Дедлайн</div>
                                    <Calendar handleDateChange={this.handleDateChange} defaultValue={deadline} />
                                </div>
                                <div className="price">
                                    <div>
                                        Гарант
                                        <div className="box_tooltip">
                                            <img src={help} alt="help" />
                                            <div className="tooltip">
                                                Минимально гарантированное число просмотров за указанный бюджет на 7-й
                                                день после публикации контента
                                            </div>
                                        </div>
                                    </div>
                                    <Field
                                        name="guarantor"
                                        component={renderField}
                                        type="number"
                                        max="999999999"
                                        className="guarantor"
                                    />
                                </div>
                            </div>
                            <div className="btn_wrapper">
                                <button
                                    className="cancel_btn"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.toggleDialog("approve_dialog");
                                    }}
                                >
                                    Отмена
                                </button>
                                <button
                                    className="border_btn_green"
                                    disabled={isDisabled || !valid || !moment(date || deadline).isAfter(new Date())}
                                >
                                    {buttonLoading ? (
                                        <Loader height={20} width={2} margin="2px" color={"#FFF"} />
                                    ) : (
                                        "Отправить"
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </DialogComponent>
                <DialogComponent open={openAcceptDialog} onClose={() => this.toggleDialog("accept_dialog")}>
                    <div className="stepper_dialog">
                        <div className="big_title">Принять условия</div>
                        <div className="description">Уверены, что хотите принять условия?</div>
                        <AcceptDealForm
                            toggleDialog={this.toggleDialog}
                            acceptDeal={this.acceptDeal}
                            sum={sum}
                            guarantor={guarantor}
                            deadline={deadline}
                        />
                    </div>
                </DialogComponent>
                <DialogComponent open={openConfirmDialog} onClose={() => this.toggleDialog("confirm_dialog")}>
                    <div className="stepper_dialog">
                        <div className="big_title">Подтвердить выполнение</div>
                        <div className="description">
                            Вставьте ссылку и подтвердите, что задание выполнено и готово к проверке
                        </div>
                        <ConfirmDealForm toggleDialog={this.toggleDialog} confirmDeal={this.confirmDeal} />
                    </div>
                </DialogComponent>
                <DialogComponent open={openCompletionDialog} onClose={() => this.toggleDialog("completion_dialog")}>
                    <div className="stepper_dialog">
                        <div className="big_title">Подтвердить выполнение</div>
                        <div className="description">Подтвердите, что задание выполнено и прошло проверку</div>
                        <div className="btn_wrapper">
                            <button className="cancel_btn" onClick={() => this.toggleDialog("completion_dialog")}>
                                Нет
                            </button>
                            <button className="border_btn_green" onClick={this.confirmCompletionDeal}>
                                {buttonLoading ? (
                                    <Loader height={20} width={2} margin="2px" color={"#FFF"} />
                                ) : (
                                    "Подтвердить"
                                )}
                            </button>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={openReworkDialog} onClose={() => this.toggleDialog("rework_dialog")}>
                    <div className="stepper_dialog">
                        <div className="big_title">Подтвердить доработку</div>
                        {rework_count === 0 ? (
                            <div className="description">
                                Подтвердите, что задание не прошло проверку и нуждается в доработке. Вы можете отправить
                                задание на доработку не более 2 раз.
                            </div>
                        ) : (
                            <div className="description">
                                Подтвердите, что задание не прошло проверку и нуждается в доработке. Это последний раз,
                                когда Вы можете отправить задание на доработку.
                            </div>
                        )}
                        <div className="btn_wrapper">
                            <button className="cancel_btn" onClick={() => this.toggleDialog("rework_dialog")}>
                                Нет
                            </button>
                            <button className="border_btn_green" onClick={this.reworkDeal}>
                                {buttonLoading ? (
                                    <Loader height={20} width={2} margin="2px" color={"#FFF"} />
                                ) : (
                                    "Подтвердить"
                                )}
                            </button>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={openGuarantorDialog} onClose={() => this.toggleDialog("guarantor_dialog")}>
                    <div className="stepper_dialog">
                        <div className="big_title">Подтвердить гарант</div>
                        <div className="description">
                            Подтвердите, что задание прошло проверку гаранта. После этого действия сделка будет
                            завершена.
                        </div>
                        <DocumentsList
                            documents={[
                                {
                                    file: payment_ib_file,
                                    name: payment_ib_file
                                        ? payment_ib_file.split("/")[payment_ib_file.split("/").length - 1]
                                        : "Счёт на оплату ib"
                                },
                                {
                                    file: act_ib_file,
                                    name: act_ib_file
                                        ? act_ib_file.split("/")[act_ib_file.split("/").length - 1]
                                        : "Акт об оказании услуг ib"
                                },
                                {
                                    file: payment_im_file,
                                    name: payment_im_file
                                        ? payment_im_file.split("/")[payment_im_file.split("/").length - 1]
                                        : "Счёт на оплату im"
                                },
                                {
                                    file: act_im_file,
                                    name: act_im_file
                                        ? act_im_file.split("/")[act_im_file.split("/").length - 1]
                                        : "Акт об оказании услуг im"
                                }
                            ]}
                            handleCheck={(documentsReviewed) => this.setState({ documentsReviewed })}
                            checked={documentsReviewed}
                        />
                        <div className="btn_wrapper">
                            <button className="cancel_btn" onClick={() => this.toggleDialog("guarantor_dialog")}>
                                Нет
                            </button>
                            <button
                                className="border_btn_green"
                                onClick={this.finishDeal}
                                disabled={!documentsReviewed}
                            >
                                {buttonLoading ? (
                                    <Loader height={20} width={2} margin="2px" color={"#FFF"} />
                                ) : (
                                    "Подтвердить"
                                )}
                            </button>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={openArbitrationDialog} onClose={() => this.toggleDialog("arbitration_dialog")}>
                    <div className="stepper_dialog">
                        <div className="big_title">Арбитраж</div>
                        <div className="description">Опишите проблему службе поддержки</div>
                        <ArbitrationForm arbitrateDeal={this.arbitrateDeal} toggleDialog={this.toggleDialog} />
                    </div>
                </DialogComponent>
            </div>
        );
    }
}

export const validate = (values) => {
    const errors = {};
    if (!values.sum) {
        errors.sum = "Обязательное поле";
    } else if (values.sum && !values.sum.toString().match(regExpNumber)) {
        errors.sum = "Некорректное значение";
    }
    if (!values.guarantor) {
        errors.guarantor = "Обязательное поле";
    } else if (values.guarantor && !values.guarantor.toString().match(regExpNumber)) {
        errors.guarantor = "Некорректное значение";
    }
    return errors;
};

StepperBlock = reduxForm({
    form: "StepperBlockForm",
    validate
})(StepperBlock);

function mapStateToProps(state, props) {
    return {
        buttonLoading: state.app.buttonLoading,
        deal: state.deals.deal,
        initialValues: {
            sum: props.sum || "",
            guarantor: props.guarantor || ""
        }
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getCurrentDeal,
            patchCurrentDeal,
            patchDealConditions,
            patchDealArbitration,
            patchDealWithVideo,
            resetDealErrors
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(StepperBlock);
