import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { renderTextarea } from "../../CampaingPage/CampaingPage";
import Loader from "../../HelperComponents/Loader/Loader";

class ArbitrationForm extends Component {
    render() {
        const { toggleDialog, arbitrateDeal, handleSubmit, buttonLoading, valid } = this.props;
        return (
            <form className="block_info_blogger" onSubmit={handleSubmit(arbitrateDeal)}>
                <div className="form_video_wrapper">
                    <div className="video_field">
                        <Field
                            name="support_message"
                            component={renderTextarea}
                            placeholder="Сообщение"
                            type="textarea"
                            className="textarea"
                        />
                    </div>
                </div>
                <div className="btn_wrapper">
                    <button
                        className="cancel_btn"
                        onClick={(e) => {
                            e.preventDefault();
                            toggleDialog("arbitration_dialog");
                        }}
                    >
                        Отмена
                    </button>
                    <button className="border_btn_green" disabled={!valid}>
                        {buttonLoading ? <Loader height={20} width={2} margin="2px" color={"#FFF"} /> : "Отправить"}
                    </button>
                </div>
            </form>
        );
    }
}

export const validate = (values) => {
    const errors = {};
    if (!values.support_message) {
        errors.support_message = "Обязательное поле";
    }
    return errors;
};

ArbitrationForm = reduxForm({
    form: "ArbitrationForm",
    validate
})(ArbitrationForm);

function mapStateToProps({ app }) {
    return {
        buttonLoading: app.buttonLoading
    };
}

export default connect(mapStateToProps)(ArbitrationForm);
