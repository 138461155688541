import * as types from "./constants.jsx";

export function getCertainCampaign(id) {
    return {
        type: types.GET_CERTAIN_CAMPAIGN,
        payload: {
            client: "default",
            request: {
                url: `/campaign/${id}/`,
                method: "get"
            }
        }
    };
}

export function getTasks(id) {
    return {
        type: types.GET_TASKS,
        payload: {
            client: "default",
            request: {
                url: `/campaign-info/${id}/`,
                method: "get"
            }
        }
    };
}

export function getDeals(id) {
    return {
        type: types.GET_CAMPAIGN_DEALS,
        payload: {
            client: "default",
            request: {
                url: `/campaign-info/${id}/`,
                method: "get"
            }
        }
    };
}

export function getTransactions(id) {
    return {
        type: types.GET_CAMPAIGN_TRANSACTIONS,
        payload: {
            client: "default",
            request: {
                url: `/campaign-info/${id}/`,
                method: "get"
            }
        }
    };
}

export function postTask(data) {
    return {
        type: types.POST_TASK,
        payload: {
            client: "default",
            request: {
                url: `/campaign/create-task/`,
                method: "post",
                data
            }
        }
    };
}

export function patchTask(id, data) {
    return {
        type: types.PATCH_TASK,
        payload: {
            client: "default",
            request: {
                url: `/campaign/task/${id}/`,
                method: "patch",
                data
            }
        }
    };
}

export function deleteTask(id) {
    return {
        type: types.DELETE_TASK,
        payload: {
            client: "default",
            request: {
                url: `/campaign/task/${id}/`,
                method: "delete"
            }
        }
    };
}

export function patchCampaignStatus(id, data) {
    return {
        type: types.PATCH_CAMPAIGN_STATUS,
        payload: {
            client: "default",
            request: {
                url: `/campaign/${id}/`,
                method: "patch",
                data
            }
        }
    };
}

export function deleteCertainCampaign(id) {
    return {
        type: types.DELETE_CERTAIN_CAMPAIGN,
        payload: {
            client: "default",
            request: {
                url: `/campaign/${id}/`,
                method: "delete"
            }
        }
    };
}
