import * as types from "./constants.jsx";

export function patchPricelist(data, channelId) {
    return {
        type: types.PATCH_BLOGGER_PRICELIST,
        payload: {
            client: "default",
            request: {
                url: `/price-list/channel/${channelId}/`,
                method: "PATCH",
                data
            }
        }
    };
}
