import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { getCurrentDeal, clearDealInfo } from "../../actions/dealsActions";
import Loader from "../HelperComponents/ContentLoader/ContentLoader";
import FeedbackBlock from "./FeedbackBlock/FeedbackBlock";
import StepperBlock from "./StepperBlock/StepperBlock";
import PromotionalBlock from "./PromotionalBlock/PromotionalBlock";
import TaskBlock from "./TaskBlock/TaskBlock";
import DealInfoBlock from "./DealInfoBlock/DealInfoBlock";
import ChatBlock from "./ChatBlock/ChatBlock";
import HistoryBlock from "./HistoryBlock/HistoryBlock";
import DocumentsBlock from "./DocumentsBlock/DocumentsBlock";

import back_arrow_grey from "../../assets/image/back_arrow_grey.png";

import "./DealsInnerPage.scss";

class DealsInnerPpage extends Component {
    constructor(props) {
        super(props);
        const dealId = props.match.params.id;
        this.state = {
            dealId
        };
    }

    componentDidMount() {
        const { getCurrentDeal } = this.props;
        const { dealId } = this.state;
        getCurrentDeal(dealId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.props.clearDealInfo();
            this.props.getCurrentDeal(this.props.match.params.id).then(() => {
                this.setState({ dealId: this.props.match.params.id });
            });
        }
    }

    componentWillUnmount() {
        const { clearDealInfo } = this.props;
        clearDealInfo();
    }

    render() {
        const { dealId } = this.state;
        const {
            loading,
            advertiser,
            advertiser_link,
            channel_name,
            blogger_id,
            guarantor,
            channel_link,
            channel_image,
            chat,
            deadline,
            sum,
            history,
            owner,
            review,
            rework_count,
            status,
            arbitration,
            task,
            video_resource_service,
            video_url,
            video_data,
            complete_date,
            guarantor_check_start_date,
            awaiting_verification_start_date,
            creation_date,
            act_ib_file,
            act_im_file,
            payment_ib_file,
            payment_im_file
        } = this.props.deal;
        const { id } = this.props.profile_data;
        const bloggerInterface = blogger_id === id;
        console.log(channel_image);
        return (
            <div className="deals_inner_page content_block_page">
                <div className="btn_back">
                    <Link to="/main/deals-page" className="back_link">
                        <img src={back_arrow_grey} alt="back_arrow_grey" />
                        Назад
                    </Link>
                </div>
                {loading ? (
                    <div className="flex-center">
                        <Loader height={65} width={5} margin="15px" color={"#5ce6cd"} />
                    </div>
                ) : (
                    <Fragment>
                        <DealInfoBlock
                            advertiser={advertiser}
                            advertiser_link={advertiser_link}
                            channel_name={channel_name}
                            blogger_channel={channel_link}
                            owner={owner}
                            bloggerInterface={bloggerInterface}
                            channel_image={channel_image}
                        />
                        <StepperBlock
                            task={task}
                            sum={sum}
                            deadline={deadline}
                            guarantor={guarantor}
                            status={status}
                            arbitration={arbitration}
                            loading={loading}
                            complete_date={complete_date}
                            guarantor_check_start_date={guarantor_check_start_date}
                            dealId={dealId}
                            bloggerInterface={bloggerInterface}
                            rework_count={rework_count}
                            awaiting_verification_start_date={awaiting_verification_start_date}
                            creation_date={creation_date}
                        />
                        {act_ib_file && (
                            <DocumentsBlock
                                documents={[
                                    {
                                        file: act_im_file,
                                        name: act_im_file
                                            ? act_im_file.split("/")[act_im_file.split("/").length - 1]
                                            : "Акт об оказании услуг im"
                                    },
                                    {
                                        file: act_ib_file,
                                        name: act_ib_file
                                            ? act_ib_file.split("/")[act_ib_file.split("/").length - 1]
                                            : "Акт об оказании услуг ib"
                                    },
                                    {
                                        file: payment_im_file,
                                        name: payment_im_file
                                            ? payment_im_file.split("/")[payment_im_file.split("/").length - 1]
                                            : "Счёт на оплату im"
                                    },
                                    {
                                        file: payment_ib_file,
                                        name: payment_ib_file
                                            ? payment_ib_file.split("/")[payment_ib_file.split("/").length - 1]
                                            : "Счёт на оплату ib"
                                    }
                                ]}
                            />
                        )}
                        {status === "Завершен" && (!bloggerInterface || (bloggerInterface && review.comment)) && (
                            <FeedbackBlock review={review} deal={dealId} />
                        )}
                        {video_resource_service && (
                            <PromotionalBlock
                                video_resource_service={video_resource_service}
                                video_url={video_url}
                                video_data={video_data}
                                sum={sum}
                            />
                        )}
                        <TaskBlock task={task} />
                        <ChatBlock chat={chat} dealId={dealId} />
                        {history.length > 0 && <HistoryBlock history={history} />}
                    </Fragment>
                )}
            </div>
        );
    }
}

function mapStateToProps({ deals, profile }) {
    return {
        deal: deals.deal,
        profile_data: profile.profile_data
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getCurrentDeal,
            clearDealInfo
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(DealsInnerPpage);
