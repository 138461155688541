import React from "react";
import "./RenderField.scss";

const RenderField_pass = ({ input, placeholder, type, id, meta: { touched, error, warning }, handleInput }) => (
    <label
        className={
            touched && error
                ? input.value !== ""
                    ? "block-input-pass error_border value"
                    : "error_border block-input-pass"
                : input.value !== ""
                ? "block-input-pass value"
                : "block-input-pass"
        }
    >
        <input {...input} placeholder={placeholder} id={id} type={type} autoComplete="off" onKeyDown={handleInput} />
        <span className="error">{error}</span>
    </label>
);

export default RenderField_pass;
