import React from "react";
import { css } from "@emotion/core";
import ScaleLoader from "react-spinners/ScaleLoader";

const override = css`
    display: block;
    margin: 0 auto;
`;

class Loader extends React.Component {
    render() {
        return <ScaleLoader css={override} sizeUnit={"px"} loading={true} radius={0} {...this.props} />;
    }
}

export default Loader;
