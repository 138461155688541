import * as types from "../actions/constants";

const INITIAL_STATE = {
    tasks: [],
    deals_error: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_TASK_BY_TYPE_SUCCESS:
            return { ...state, tasks: action.payload.data };
        case types.POST_DEAL_CREATE_FAIL:
            return { ...state, deals_error: action.error.response.data };
        case types.RESET_ERROR:
            return { ...state, deals_error: action.deals_error };
        default:
            return state;
    }
}
