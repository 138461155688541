import React from "react";
import AreaChart from "highcharts-react-official";
import * as Highcharts from "highcharts";

const LineChart = ({ data }) => {
    console.log(data);
    let datesArray = [];
    data.map(el => datesArray.push(new Date(el[0].concat("-01")).toLocaleDateString("ru-RU").slice(1)));
    const options = {
        title: {
            text: null
        },
        tooltip: {
            headerFormat: "",
            pointFormat: `{point.category}<br/> <b>{point.y}</b><br/>`,
            borderWidth: 0,
            style: {
                fontWeight: "normal",
                fontFamily: "GothaProReg,sans-serif",
                fontSize: 14,
                fontColor: "#1A1A1A"
            }
        },
        plotOptions: {
            area: {
                marker: {
                    enabled: false,
                    symbol: "circle",
                    radius: 2,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                }
            },
            series: {
                fillColor: "rgb(92, 230, 205, 0.3)",
                lineColor: "#5CE6CD",
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            fillColor: "#41d3b8"
                        }
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        chart: {
            type: "areaspline"
        },
        navigation: {
            menuItemStyle: {
                fontWeight: "normal",
                fontFamily: "GothaProReg,sans-serif",
                fontSize: 14,
                fontColor: "#1A1A1A"
            }
        },
        xAxis: {
            categories: datesArray
        },
        yAxis: {
            title: {
                text: ""
            }
        },
        series: [
            {
                showInLegend: false,
                data: data,
                dataLabels: {
                    style: {
                        fontWeight: "normal",
                        fontFamily: "GothaProReg,sans-serif",
                        fontSize: 14,
                        fontColor: "#1A1A1A"
                    }
                }
            }
        ]
    };
    return <AreaChart highcharts={Highcharts} options={options} />;
};

export default LineChart;
