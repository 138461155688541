import * as types from "./constants.jsx";

export function getTaskByType(data) {
    return {
        type: types.GET_TASK_BY_TYPE,
        payload: {
            client: "default",
            request: {
                url: `/task-by-type/?type=${data}`,
                method: "get"
            }
        }
    };
}
