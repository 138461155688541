import * as types from "./constants.jsx";
import { joinQueries } from "../helpers/functions";

export function getBalanceGeneralInfo(arr) {
    return {
        type: types.GET_BALANCE_GENERAL_INFO,
        payload: {
            client: "default",
            request: {
                url: `/promoter/transactions/general-info/`,
                method: "get"
            }
        }
    };
}

export function getTransactions(arr) {
    return {
        type: types.GET_TRANSACTIONS,
        payload: {
            client: "default",
            request: {
                url: `/promoter/transactions/${joinQueries(arr)}`,
                method: "get"
            }
        }
    };
}

export function postWithdrawal(data) {
    return {
        type: types.POST_WITHDRAWAL_INFO,
        payload: {
            client: "default",
            request: {
                url: `/promoter/withdrawal/`,
                method: "post",
                data
            }
        }
    };
}
