import * as types from "../actions/constants";

const INITIAL_STATE = {
    review_list: {},
    error: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_REVIEW_SUCCESS:
            return { ...state, review_list: action.payload.data };
        case types.GET_REVIEW_FAIL:
            return { ...state, error: action.error.response.data };
        default:
            return state;
    }
}
