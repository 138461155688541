import * as types from "./constants.jsx";

export function getTasksSearch(data) {
    return {
        type: types.GET_ALL_TASKS,
        payload: {
            client: "default",
            request: {
                url: `/tasks-search/${data.length && data.length !== 0 ? "?" : ""}${data.join("&")}`,
                method: "get"
            }
        }
    };
}
export function getCampaignInfo(id) {
    return {
        type: types.GET_CAMPAIGN_INFO,
        payload: {
            client: "default",
            request: {
                url: `/tasks-search/campaign/${id}`,
                method: "get"
            }
        }
    };
}

export function resetStore() {
    return {
        type: types.RESET_STORE
    };
}
