import React from "react";
import PieChart from "highcharts-react-official";
import * as Highcharts from "highcharts";

const StockChart = ({ data }) => {
    const array = data.map((el) => ({
        name: el[0],
        y: el[1]
    }));
    const options = {
        title: {
            text: null
        },
        tooltip: {
            headerFormat: "",
            pointFormat: `{point.name} <b>{point.y}%</b><br/>`,
            style: {
                fontWeight: "normal",
                fontFamily: "GothaProReg,sans-serif",
                fontSize: 14,
                fontColor: "#1A1A1A"
            }
        },
        credits: {
            enabled: false
        },
        chart: {
            type: "pie"
        },
        series: [
            {
                minPointSize: 10,
                innerSize: "70%",
                zMin: 0,
                name: "Аудитория",
                data: array,
                dataLabels: {
                    style: {
                        fontWeight: "normal",
                        fontFamily: "GothaProReg,sans-serif",
                        fontSize: 14,
                        fontColor: "#1A1A1A"
                    }
                },
                colors: ["#FF77B4", "#DFC2F2", "#B2EDC5", "#0197F5", "#FFA072", "#2CC197", "#EDEDED"]
            }
        ]
    };
    return <PieChart highcharts={Highcharts} options={options} />;
};

export default StockChart;
