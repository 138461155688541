import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import InfiniteScroll from "react-infinite-scroller";

import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import ContentLoader from "../HelperComponents/ContentLoader/ContentLoader";
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent";
import BloggersFilters from "./BloggersFilters";
import FlagIcon from "../../FlagIcon.js";
import Loader from "../HelperComponents/ContentLoader/ContentLoader";
import Rating from "../HelperComponents/Rating/Rating";
import { Field, reduxForm } from "redux-form";
import { renderField } from "../CampaingPage/CampaingPage";

import { getBloggers, resetBloggers, getPriceToInbox, dispatchEmptyPriceList } from "../../actions/bloggerAction";
import { getPrices, getSortOption, numberWithSpaces } from "../../helpers/functions";

import search_icon from "../../assets/image/search_icon 2.svg";
import help from "../../assets/image/help.svg";
import rating_high from "../../assets/image/rating_high.svg";
import rating_medium from "../../assets/image/rating_medium.svg";
import rating_low from "../../assets/image/rating_low.svg";
import rating_empty from "../../assets/image/rating_empty.svg";
import youtube_icon from "../../assets/image/youtube_icon.png";
import female from "../../assets/image/female.svg";
import male from "../../assets/image/male.svg";

import "./BloggersSearch.scss";

class BloggersSearch extends Component {
    state = {
        contentLoading: false,
        loading: true,
        filtersOpened: false,
        previousFilters: [],
        sort_value: { label: getSortOption("АКТИВНОСТИ", "up"), value: "activity" },
        sort_values_list: [
            { label: getSortOption("АКТИВНОСТИ", "up"), value: "activity" },
            { label: getSortOption("АКТИВНОСТИ", "down"), value: "-activity" },
            { label: getSortOption("ВОВЛЕЧЕННОСТИ", "up"), value: "involvement" },
            { label: getSortOption("ВОВЛЕЧЕННОСТИ", "down"), value: "-involvement" },
            { label: getSortOption("ОДОБРЕНИЮ", "up"), value: "approbation" },
            { label: getSortOption("ОДОБРЕНИЮ", "down"), value: "-approbation" },
            { label: getSortOption("ПОДПИСЧИКАМ", "up"), value: "subscribers" },
            { label: getSortOption("ПОДПИСЧИКАМ", "down"), value: "-subscribers" },
            { label: getSortOption("ПРОСМОТРАМ", "up"), value: "video_views" },
            { label: getSortOption("ПРОСМОТРАМ", "down"), value: "-video_views" },
            { label: getSortOption("КАЧЕСТВУ", "up"), value: "quality" },
            { label: getSortOption("КАЧЕСТВУ", "down"), value: "-quality" }
        ],
        hotReload: false
    };

    componentDidMount() {
        this.doRequest();
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    doRequest = (filters = [], isReset, nameSearchValue) => {
        const { getBloggers, resetBloggers } = this.props;
        const { sort_value, previousFilters } = this.state;
        !isReset && resetBloggers(); // данный ресет удалял всех предыдущих блоггеров необходимых для бесконечного скролла
        let query = [];
        query.push(`sort=${sort_value.value}`);
        if (filters !== "reset") {
            if (filters.length > 0) {
                query.push(...filters);
            } else if (previousFilters.length > 0) {
                query.push(...previousFilters);
            }
        }
        if (nameSearchValue) query.push(`name=${nameSearchValue.name}`);
        getBloggers(query).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ loading: false, contentLoading: false });
            }
        });
    };

    toggleFilters = () => {
        this.setState(({ filtersOpened }) => ({
            filtersOpened: !filtersOpened
        }));
    };

    changeSortValue = event => {
        this.setState({ sort_value: event });
        this.timeout = setTimeout(() => {
            this.doRequest();
        }, 0);
    };

    submitFilters = filters => {
        this.setState({ previousFilters: filters });
        if (filters !== "reset") this.toggleFilters();
        this.doRequest(filters);
    };

    handleLazyLoad = () => {
        const {
            bloggers: { nextPage }
        } = this.props;
        const { previousFilters } = this.state;
        let data = [...previousFilters];
        if (!!nextPage) {
            const match = nextPage.match(/page=(\d+)/);
            let page = null;
            if (match) {
                page = match[1];
            }
            data.push(`page=${page}`);
        }
        this.doRequest(data, true);
    };

    submitSearchForm = data => {
        this.setState({ contentLoading: true });
        if (data.name && data.name.length >= 2) {
            this.doRequest([], false, data);
        } else {
            this.doRequest([], false, null);
        }
    };

    handlePriceToInbox = async bloggerId => {
        const { getPriceToInbox, dispatchEmptyPriceList } = this.props;
        const { hotReload } = this.state;
        await getPriceToInbox(bloggerId);
        await dispatchEmptyPriceList(bloggerId);
        this.setState({ hotReload: !hotReload });
    };

    render() {
        const {
            bloggers: { all_bloggers, nextPage },
            handleSubmit
        } = this.props;
        const { filtersOpened, sort_value, sort_values_list, loading, contentLoading } = this.state;
        const filtersStyle = filtersOpened ? "filters_opened" : "";
        if (loading) {
            return <ContentLoader />;
        }
        return (
            <TransitionedBlock>
                <div className="content_block_page bloggers_search_wrapper">
                    <h1>Поиск по блогерам</h1>
                    <div className="block_panel_blogs">
                        <div className="options_wrapper">
                            <form className="search_blogs" onSubmit={handleSubmit(this.submitSearchForm)}>
                                <Field
                                    name="name"
                                    type="text"
                                    placeholder="Введите название канала/страницы"
                                    component={renderField}
                                />
                                <button>
                                    <img src={search_icon} alt="search_icon" />
                                </button>
                            </form>
                            <BloggersFilters
                                filtersStyle={filtersStyle}
                                toggleFilters={this.toggleFilters}
                                submitFilters={this.submitFilters}
                            />
                        </div>
                        <div>
                            <span className="sort_by">Сортировать по:</span>
                            <FormControl className="select_wrapper">
                                <SelectComponent
                                    value={sort_value}
                                    options={sort_values_list}
                                    change={this.changeSortValue}
                                    isClearable="false"
                                    isSearchable={false}
                                />
                            </FormControl>
                        </div>
                    </div>
                    {Object.keys(all_bloggers).length === 0 ? (
                        contentLoading ? (
                            <div className="lazy_loader_wrapper" key={0}>
                                <Loader height={55} width={5} margin="15px" color={"#5ce6cd"} />
                            </div>
                        ) : (
                            <div className="no_deals block_wrapper_bg">
                                По данному запросу не найдено ни одного блоггера
                            </div>
                        )
                    ) : (
                        <div className="bloggers_search_table">
                            <div className="table_container transactions_columns">
                                <div className="table_header">
                                    <div className="table_row">
                                        <div className="row_item"></div>
                                        <div className="row_item">Подписчики</div>
                                        <div className="row_item">
                                            <div className="block_info">
                                                <div>
                                                    Просмотры <br />
                                                    на 1 видео
                                                </div>
                                                <div className="block_tooltip">
                                                    <img src={help} alt="help" />
                                                    <div className="tooltip">
                                                        По последним 10 видеороликам на канале
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row_item">
                                            <div className="block_info">
                                                <div>
                                                    Стоимость <br /> рекламы
                                                </div>
                                                <div className="block_tooltip">
                                                    <img src={help} alt="help" />
                                                    <div className="tooltip_big">
                                                        Указана стоимость за интеграцию, полный прайс-лист по другим
                                                        типам рекламы указан во всплывающей подсказке
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row_item">Аудитория</div>
                                        <div className="row_item">Демография</div>
                                        <div className="row_item">
                                            <div className="block_info">
                                                <div>Вовлечённость</div>
                                                <div className="block_tooltip">
                                                    <img src={help} alt="help" />
                                                    <div className="tooltip_big">
                                                        Соотношение комментариев к количеству просмотров
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row_item">Качество</div>
                                    </div>
                                </div>
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.handleLazyLoad}
                                    hasMore={!!nextPage}
                                    threshold={50}
                                    loader={
                                        <div className="lazy_loader_wrapper" key={0}>
                                            <Loader height={55} width={5} margin="15px" color={"#5ce6cd"} />
                                        </div>
                                    }
                                >
                                    <div className="table_body">
                                        {all_bloggers.map((blogger, index) => {
                                            const dontGoAnywhere =
                                                !blogger.price_list || !blogger.price_list.hasOwnProperty("id");
                                            return (
                                                <div className="table_row" key={index}>
                                                    <div className="row_item">
                                                        <Link
                                                            className="info_blogers_wrapper"
                                                            to={
                                                                dontGoAnywhere
                                                                    ? "/main/bloggers-search"
                                                                    : `/main/blogger/${blogger.id}`
                                                            }
                                                            onClick={() =>
                                                                dontGoAnywhere && window.open(blogger.youtube_link)
                                                            }
                                                        >
                                                            <div className="box_photo">
                                                                <img src={blogger.image} alt="" />
                                                            </div>
                                                            <div className="info_blogers">
                                                                <div className="name" title={blogger.name}>
                                                                    <p>{blogger.name}</p>
                                                                    <img src={youtube_icon} alt="youtube_icon" />
                                                                    {/*<img src={instagram_icon} alt="instagram_icon"/>*/}
                                                                </div>
                                                                <div className="info">
                                                                    {blogger.categories &&
                                                                        blogger.categories.length > 0 && (
                                                                            <div className="wrapper_info">
                                                                                <div>Категория:</div>
                                                                                <span>
                                                                                    {blogger.categories.join(" / ")}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    {blogger.genres && blogger.genres.length > 0 && (
                                                                        <div className="wrapper_info">
                                                                            <div>Жанр:</div>
                                                                            <span>{blogger.genres.join(" / ")}</span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="row_item">
                                                        {numberWithSpaces(blogger.subscribers)}
                                                    </div>
                                                    <div className="row_item">
                                                        {numberWithSpaces(blogger.video_views)}
                                                    </div>
                                                    <div className="row_item">
                                                        <div>
                                                            {!blogger.price_list ? (
                                                                <button
                                                                    className="get_price"
                                                                    onClick={() =>
                                                                        this.handlePriceToInbox(blogger.blogger_id)
                                                                    }
                                                                >
                                                                    Уточнить цену
                                                                </button>
                                                            ) : !blogger.price_list.hasOwnProperty("id") ? (
                                                                <div className="get_price_with_tooltip">
                                                                    <div className="get_price not_active">
                                                                        Уточнить цену
                                                                    </div>
                                                                    <div className="tooltip">Запрос уже отправлен</div>
                                                                </div>
                                                            ) : (
                                                                getPrices(blogger)
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row_item">
                                                        <div className="center countries">
                                                            {blogger.country && (
                                                                <FlagIcon
                                                                    code={blogger.country.toLowerCase()}
                                                                    squared={true}
                                                                    size={"lg"}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row_item">
                                                        {blogger.gender && blogger.age && (
                                                            <Fragment>
                                                                {blogger.gender === "male" ? (
                                                                    <img src={male} alt="female" />
                                                                ) : (
                                                                    <img src={female} alt="male" />
                                                                )}
                                                                {blogger.age} лет
                                                            </Fragment>
                                                        )}
                                                    </div>
                                                    <div className="row_item">
                                                        <div className="center">
                                                            {blogger.gender.involment === "3" ? (
                                                                <img src={rating_high} alt="rating_high" />
                                                            ) : blogger.gender.involment === "2" ? (
                                                                <img src={rating_medium} alt="rating_medium" />
                                                            ) : blogger.gender.involment === "1" ? (
                                                                <img src={rating_low} alt="rating_low" />
                                                            ) : (
                                                                <img src={rating_empty} alt="rating_empty" />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row_item">
                                                        <Rating value={blogger.quality} />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </InfiniteScroll>
                            </div>
                        </div>
                    )}
                </div>
            </TransitionedBlock>
        );
    }
}

export const validate = values => {
    const errors = {};
    if (values.name && values.name.length < 2) {
        errors.name = "Минимум 2 символа";
    }
    return errors;
};

BloggersSearch = reduxForm({
    form: "BloggersSearch",
    validate
})(BloggersSearch);

function mapStateToProps(state) {
    return {
        bloggers: state.bloggers
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getBloggers,
            resetBloggers,
            getPriceToInbox,
            dispatchEmptyPriceList
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(BloggersSearch);
