import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PublicOffer from "../../components/PublicOffer/PublicOffer";
import PrivacyPolicy from "../../components/PublicPages/PrivacyPolicy";

import BackArrow from "../../assets/image/back_arrow.png";

import "./PublicPagesContainer.scss";

class PublicPagesContainer extends Component {
    componentDidMount() {
        console.log(this.props);
    }

    handleGoBack = () => {
        const { history } = this.props;
        const params = new URLSearchParams(history.location.search.substring(1));
        if (params.get("redirect") === "sign_up") {
            history.push("/auth/sign-up");
        } else {
            history.push("/auth");
        }
    };

    render() {
        return (
            <div>
                <div className="public_container">
                    <div className="public_header" />
                    <div className="public_go_back">
                        <div onClick={this.handleGoBack}>
                            <img src={BackArrow} alt="go back" />
                            Назад
                        </div>
                    </div>
                    <div className="public_wrapper">
                        <Switch>
                            <Route path="/public" exact render={() => <Redirect to="/public/public-offer" />} />
                            <Route path="/public/public-offer" exact component={PublicOffer} />
                            <Route path="/public/privacy-policy" exact component={PrivacyPolicy} />
                            <Route render={() => <Redirect to="/public/public-offer" />} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default PublicPagesContainer;
