import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import { multiClientMiddleware } from "redux-axios-middleware";
import api from "./actions/api";
import routes from "./routes/routes";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import { configureSentry } from "./helpers/functions";

configureSentry({
    dsn: "https://3f61ff1e83b24d49ac72c123caa04731@sentry.4-com.pro/20",
    dev: "imast.4-com.pro",
    prod: "imast.info"
});

const axiosMiddlewareOptions = {
    interceptors: {
        request: [
            (action, config) => {
                if (localStorage.token || localStorage.token_res) {
                    let token = localStorage.token ? localStorage.token : localStorage.token_res;
                    config.headers["Authorization"] = "Token " + token;
                }
                return config;
            }
        ]
    }
};

const history = createBrowserHistory();
const appRouterMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const createStoreWithMiddleware = applyMiddleware(
    multiClientMiddleware(api, axiosMiddlewareOptions),
    appRouterMiddleware,
    sagaMiddleware
)(createStore);
const store = createStoreWithMiddleware(
    rootReducer(history),
    {},
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history} children={routes} />
    </Provider>,
    document.getElementById("wrapper")
);
