import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { renderField } from "../../CampaingPage/CampaingPage";
import Loader from "../../HelperComponents/Loader/Loader";

import help from "../../../assets/image/help.svg";

class AcceptDealForm extends Component {
    render() {
        const { toggleDialog, acceptDeal, handleSubmit, buttonLoading } = this.props;
        return (
            <form className="block_info_blogger" onSubmit={handleSubmit(acceptDeal)}>
                <div className="block_info_forms">
                    <div className="price disabled">
                        <div>Сумма</div>
                        <Field name="sum" component={renderField} type="number" max="999999999" />
                        <span>руб.</span>
                    </div>
                    <div className="price disabled">
                        <div>Дедлайн</div>
                        <Field name="deadline" component={renderField} type="text" className="guarantor" />
                    </div>
                    <div className="price disabled">
                        <div>
                            Гарант
                            <div className="box_tooltip">
                                <img src={help} alt="help" />
                                <div className="tooltip">
                                    Минимально гарантированное число просмотров за указанный бюджет на 7-й день после
                                    публикации контента
                                </div>
                            </div>
                        </div>
                        <Field
                            name="guarantor"
                            component={renderField}
                            type="number"
                            max="999999999"
                            className="guarantor"
                        />
                    </div>
                </div>
                <div className="btn_wrapper">
                    <button
                        className="cancel_btn"
                        onClick={(e) => {
                            e.preventDefault();
                            toggleDialog("accept_dialog");
                        }}
                    >
                        Отмена
                    </button>
                    <button className="border_btn_green">
                        {buttonLoading ? <Loader height={20} width={2} margin="2px" color={"#FFF"} /> : "Подтвердить"}
                    </button>
                </div>
            </form>
        );
    }
}

AcceptDealForm = reduxForm({
    form: "AcceptDealForm"
})(AcceptDealForm);

function mapStateToProps(state, props) {
    return {
        buttonLoading: state.app.buttonLoading,
        initialValues: {
            sum: props.sum || "",
            guarantor: props.guarantor || "",
            deadline: props.deadline || ""
        }
    };
}

export default connect(mapStateToProps)(AcceptDealForm);
