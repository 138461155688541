import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./AboutChannel.scss";
import moment from "moment";

class AboutChannel extends Component {
    render() {
        const {
            aboutTabContent: {
                categories,
                genres,
                registration_date,
                author_content,
                last_deal,
                // non_problem,
                completed
            }
        } = this.props;
        console.log(completed);
        return (
            <div className="about_channel_wrapper">
                <div className="block_wrapper_bg">
                    <div className="title">Статистика сделок</div>
                    <div className="info">
                        <div>
                            <span>Завершенные</span>
                            <p>{completed > 0 ? completed : "-"}</p>
                        </div>
                        <div>
                            <span>Последняя сделка</span>
                            <p>{last_deal ? moment(last_deal).format("DD.MM.YYYY") : "-"}</p>
                        </div>
                        {/*<div>*/}
                        {/*    <span>Беспроблемность</span>*/}
                        {/*    <p className="text_red text_green">{non_problem}</p>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="block_wrapper_bg">
                    <div className="title">Информация о канале</div>
                    <div className="info_channel info_channel_first">
                        <div>
                            <span>Категории</span>
                            <div className="info_text">{categories.length > 0 ? categories.join(", ") : "-"}</div>
                        </div>
                        <div>
                            <span>Жанры</span>
                            <div className="info_text">{genres.length > 0 ? genres.join(", ") : "-"}</div>
                        </div>
                    </div>
                    <div className="info_channel">
                        <div>
                            <span>Авторский контент</span>
                            <div className="info_text_regular">
                                {author_content === "yes" ? "Да" : author_content === "no" ? "Нет" : "-"}
                            </div>
                        </div>
                        <div>
                            <span>Дата регистрации канала</span>
                            <div className="info_text">{moment(registration_date).format("DD.MM.YYYY")}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutChannel);
