import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DialogComponent from "../../../HelperComponents/DialogComponent/DialogComponent";
import unselected_task from "../../../../assets/image/unselected_task.svg";
import selected_task from "../../../../assets/image/selected_task.svg";
import { Field, reduxForm, SubmissionError } from "redux-form";
import Calendar from "../../../HelperComponents/Calendar/Calendar";
import Loader from "../../../HelperComponents/Loader/Loader";
import { postDealCreate } from "../../../../actions/dealCreateActions";
import { getCampaigns } from "../../../../actions/campaignsActions";
import { postTask } from "../../../../actions/campaignInnerPageActions";
import { resetError } from "../../../../actions/resetErrorActions";

import "./ApplyBlogger.scss";
import { renderField, renderTextarea } from "../../../CampaingPage/CampaingPage";
import FormControl from "@material-ui/core/FormControl";
import SelectComponent from "../../../HelperComponents/SelectComponent/SelectComponent";
import RenderCheckField from "../../../HelperComponents/RenderField/RenderCheckField/RenderCheckField";
import help from "../../../../assets/image/help.svg";

class ApplyBlogger extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: "0",
            activeElement: props.id,
            textOverflow: true,
            openText: [],
            comment: "",
            description: "",
            task_type: props.task_type,
            date: null,
            campaignId: null,
            isDisabled: false
        };
    }
    componentWillUnmount() {}

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id !== this.props.id) {
            this.setState({
                activeElement: this.props.id
            });
        }
        if (prevProps.task_type !== this.props.task_type) {
            this.setState({
                task_type: this.props.task_type
            });
        }
        if (prevProps.reRender !== this.props.reRender) {
            this.setState({
                tab: "0",
                campaignId: null
            });
            this.props.reset("ApplyBlogger");
            this.props.resetError();
        }
    }

    toggleText = id => {
        if (this.state.openText.includes(id)) {
            this.setState({
                openText: this.state.openText.filter(el => el !== id)
            });
        } else {
            this.setState({ openText: [id] });
        }
    };

    changeTab = tab => {
        const { getCampaigns } = this.props;
        this.setState({ tab, comment: "" });
        if (tab === "1") {
            getCampaigns().then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    this.setState({
                        campaigns: res.payload.data.map(item => {
                            return {
                                label: (
                                    <div className="status_select">
                                        <div>{item.campaign_name}</div>
                                    </div>
                                ),
                                value: item.id
                            };
                        })
                    });
                }
            });
        }
    };

    submitFormZero = data => {
        const { postDealCreate, bloggerId } = this.props;

        // data.blogger_id = bloggerId.id;
        data.task_id = this.state.activeElement;
        data.deadline = this.state.date;
        data.youtube_channel_id = bloggerId.id;

        if (data.deadline === "") {
            data.deadline = null;
        }
        if (Date.parse(data.deadline) < Date.now()) {
            return undefined;
        }
        postDealCreate(data).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 201) {
                this.props.toggleDialog();
            }
        });
    };
    submitFormOne = data => {
        const { postTask, postDealCreate, bloggerId, reset } = this.props;
        const { task_type, campaignId, date, comment, activeElement } = this.state;

        const task_create = {
            task_text: data.task_text,
            task_type: task_type,
            public_view: data.public_view,
            budget: data.budget,
            ad_link: data.ad_link,
            campaign: campaignId,
            preview: data.preview
        };
        const deal_create = {
            // blogger_id: bloggerId.id,
            sum: data.budget,
            deadline: date,
            msg: comment,
            task_id: activeElement,
            guarantor: data.guarantor,
            youtube_channel_id: bloggerId.id
        };

        console.log(bloggerId.id);

        return postTask(task_create).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 201) {
                deal_create.task_id = res.payload.data.id;
                postDealCreate(deal_create).then(res => {
                    if (res.payload && res.payload.status && res.payload.status === 201) {
                        reset("ApplyBlogger");
                        this.props.toggleDialog();
                    } else {
                        console.log(res.error.response.data);
                        //throw new SubmissionError({ ...res.error.response.data });
                    }
                });
            }
        });
    };

    handleClick = id => {
        this.setState({ activeElement: id });
    };

    handleDateChange = date => {
        function formatDate(date) {
            let d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        }

        if (date !== null) {
            if (date.toString() !== "Invalid Date") {
                this.setState({ date: formatDate(date), isDisabled: false });
            } else {
                this.setState({ date: formatDate(date), isDisabled: true });
            }
        } else {
            this.setState({ date: null, isDisabled: false });
        }
    };
    handleCampaignChange = event => {
        this.setState({
            campaignId: event.value
        });
    };
    commentChanger = e => {
        this.setState({ comment: e.target.value });
    };
    descriptionChanger = e => {
        this.setState({ description: e.target.value });
    };

    render() {
        const { tab, activeElement, openText, comment, campaigns, description, isDisabled } = this.state;
        const {
            open,
            handleSubmit,
            tasks,
            loading,
            deals_error,
            campaignPage: { error },
            toggleDialog
        } = this.props;
        return (
            <div className="apply_blogger">
                <DialogComponent open={open} onClose={toggleDialog}>
                    {loading ? (
                        <Loader height={26} width={2} margin="5px" color={"#5CE6CD"} />
                    ) : (
                        <Fragment>
                            <div className="apply_dialog">
                                <div className="title">Подать заявку блогеру</div>
                                <div className="tab_blogger">
                                    <button className={tab === "0" ? "active" : ""} onClick={() => this.changeTab("0")}>
                                        Выбрать задание
                                    </button>
                                    <button className={tab === "1" ? "active" : ""} onClick={() => this.changeTab("1")}>
                                        Создать новое задание
                                    </button>
                                </div>
                            </div>
                            {tab === "0" && (
                                <form className="block_info_blogger" onSubmit={handleSubmit(this.submitFormZero)}>
                                    {tasks.length > 0 ? (
                                        tasks.map(item => {
                                            return (
                                                <div
                                                    key={item.id}
                                                    className={`info_blogger ${
                                                        activeElement === item.id ? "active_wrapper" : ""
                                                    }`}
                                                    onClick={() => this.handleClick(item.id)}
                                                >
                                                    <div className="title_wrapper">
                                                        <div className="name_wrapper">
                                                            <div>{item.campaign}</div>
                                                            <span>
                                                                Обязательный предпросмотр перед публикацией, Публичное
                                                                задание
                                                            </span>
                                                        </div>
                                                        <div className="box_icon">
                                                            <img
                                                                className="no_active"
                                                                src={unselected_task}
                                                                alt="unselected_task"
                                                            />
                                                            <img
                                                                className="active"
                                                                src={selected_task}
                                                                alt="selected_task"
                                                            />
                                                        </div>
                                                    </div>
                                                    {item.ad_link ? (
                                                        <div className="advertising">
                                                            <div className="descriptions_text">Рекламная ссылка</div>
                                                            <a
                                                                href={item.ad_link}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {item.ad_link}
                                                            </a>
                                                        </div>
                                                    ) : null}
                                                    <div className="descriptions_projects">
                                                        <div className="descriptions_text">Описание задания</div>
                                                        <div
                                                            className={
                                                                !openText.includes(item.id) &&
                                                                item.task_text.length > 1000
                                                                    ? "text"
                                                                    : "text full_height"
                                                            }
                                                        >
                                                            {item.task_text}
                                                        </div>
                                                        {item.task_text.length > 1000 && (
                                                            <button onClick={() => this.toggleText(item.id)}>
                                                                {openText.includes(item.id) ? (
                                                                    <Fragment>
                                                                        <span className="up" />
                                                                        скрыть
                                                                    </Fragment>
                                                                ) : (
                                                                    <Fragment>
                                                                        <span className="down" />
                                                                        показать полностью
                                                                    </Fragment>
                                                                )}
                                                            </button>
                                                        )}
                                                    </div>
                                                    {activeElement === item.id ? (
                                                        <div className="comment_wrapper">
                                                            <div className="descriptions_text">
                                                                Комментарий к заданию
                                                            </div>
                                                            <Field
                                                                name="msg"
                                                                component={renderTextarea}
                                                                placeholder="Добавьте комментарий к заданию"
                                                                type="textarea"
                                                                className="textarea"
                                                                onChange={e => this.commentChanger(e, "msg")}
                                                                value={comment}
                                                            />
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="empty_tasks">Нет заданий</div>
                                    )}
                                    <div className="block_info_forms">
                                        <div className="price">
                                            <div>Сумма</div>
                                            <Field name="sum" component={renderField} type="number" max="999999999" />
                                            <span>руб.</span>
                                            {deals_error && deals_error.sum && (
                                                <span className="back_error ">{deals_error.sum}</span>
                                            )}
                                        </div>
                                        <div className="price">
                                            <div>Дедлайн</div>
                                            <Calendar handleDateChange={this.handleDateChange} />
                                            {deals_error && deals_error.deadline && (
                                                <span className="back_error ">{deals_error.deadline}</span>
                                            )}
                                        </div>
                                        <div className="price">
                                            <div>
                                                Гарант
                                                <div className="box_tooltip">
                                                    <img src={help} alt="help" />
                                                    <div className="tooltip">
                                                        Минимально гарантированное число просмотров за указанный бюджет
                                                        на 7-й день после публикации контента
                                                    </div>
                                                </div>
                                            </div>
                                            <Field
                                                name="guarantor"
                                                component={renderField}
                                                type="number"
                                                max="999999999"
                                                className="guarantor"
                                            />
                                            {deals_error && deals_error.guarantor && (
                                                <span className="back_error ">{deals_error.guarantor}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="buttons_wrapper">
                                        <span
                                            onClick={e => {
                                                // e.isPropagationStopped();
                                                toggleDialog();
                                            }}
                                            className="cancel_btn"
                                        >
                                            Отмена
                                        </span>
                                        <button disabled={!!isDisabled} className="green_btn">
                                            Отправить
                                        </button>
                                    </div>
                                </form>
                            )}

                            {tab === "1" && (
                                <form className="block_tasks_bloger" onSubmit={handleSubmit(this.submitFormOne)}>
                                    <div className="campaigns">
                                        <span className="text_campaigns">Кампания</span>
                                        <FormControl className="select_wrapper ">
                                            <SelectComponent
                                                options={campaigns}
                                                change={this.handleCampaignChange}
                                                isClearable="false"
                                                isSearchable={false}
                                                placeholder="Выберите кампанию"
                                            />
                                            {error && error.campaign && (
                                                <span className="back_error text_back_error_pos_abs">
                                                    {error.campaign}
                                                </span>
                                            )}
                                        </FormControl>
                                    </div>
                                    <div className="block_label">
                                        <div>Рекламная ссылка</div>
                                        <Field
                                            name="ad_link"
                                            component={renderField}
                                            placeholder="Ссылка на сайт или YouTube-канал"
                                            autoComplete="off"
                                            type="text"
                                            className="link-textarea"
                                        />
                                        {error && error.ad_link && <span className="back_error ">{error.ad_link}</span>}
                                    </div>
                                    <div className="block_label">
                                        <div>Описание задания</div>
                                        <Field
                                            name="task_text"
                                            component={renderTextarea}
                                            placeholder="Опишите пожелания и требования к рекламе"
                                            type="textarea"
                                            className="textarea"
                                            onChange={this.descriptionChanger}
                                            value={description}
                                        />
                                        {error && error.task_text && (
                                            <span className="back_error ">{error.task_text}</span>
                                        )}
                                    </div>
                                    <div className="check_box">
                                        {/*<div className="col col-checkbox first_block">*/}
                                        {/*    <div className="checkbox">*/}
                                        {/*        <label>*/}
                                        {/*            <Field*/}
                                        {/*                name="preview"*/}
                                        {/*                component={RenderCheckField}*/}
                                        {/*                type="checkbox"*/}
                                        {/*                className="green_checkbox"*/}
                                        {/*            />*/}
                                        {/*            <span />*/}
                                        {/*            Обязательный предпросмотр перед публикацией*/}
                                        {/*        </label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="col col-checkbox ">
                                            <div className="checkbox">
                                                <label>
                                                    <Field
                                                        name="public_view"
                                                        component={RenderCheckField}
                                                        type="checkbox"
                                                        className="green_checkbox"
                                                    />
                                                    <span />
                                                    Сделать задание публичным (задание будут видеть все блогеры, которые
                                                    смогут отправлять заявки на выполнение задания)
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block_label">
                                        <div>Комментарий к заданию</div>
                                        <Field
                                            name="message"
                                            component={renderTextarea}
                                            placeholder="Добавьте комментарий к заданию"
                                            type="textarea"
                                            className="textarea"
                                            onChange={e => this.commentChanger(e, "message")}
                                            value={comment}
                                        />
                                    </div>
                                    <div className="block_info_forms">
                                        <div className="price">
                                            <div>Сумма</div>
                                            <Field
                                                name="budget"
                                                component={renderField}
                                                type="number"
                                                max="999999999"
                                            />
                                            <span>руб.</span>
                                            {error && error.budget && (
                                                <span className="back_error ">{error.budget}</span>
                                            )}
                                        </div>
                                        <div className="price">
                                            <div>Дедлайн</div>
                                            <Field
                                                name="deadline"
                                                //type="date"
                                                component={Calendar}
                                                handleDateChange={this.handleDateChange}
                                            />
                                            {deals_error && deals_error.deadline && (
                                                <span className="back_error ">{deals_error.deadline}</span>
                                            )}
                                            {/* <Calendar  /> */}
                                        </div>
                                        <div className="price">
                                            <div>
                                                Гарант
                                                <div className="box_tooltip">
                                                    <img src={help} alt="help" />
                                                    <div className="tooltip">
                                                        Минимально гарантированное число просмотров за указанный бюджет
                                                        на 7-й день после публикации контента
                                                    </div>
                                                </div>
                                            </div>
                                            <Field
                                                name="guarantor"
                                                component={renderField}
                                                type="number"
                                                max="999999999"
                                                className="guarantor"
                                            />
                                            {deals_error && deals_error.guarantor && (
                                                <span className="back_error ">{deals_error.guarantor}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="buttons_wrapper">
                                        <span
                                            onClick={e => {
                                                // e.isPropagationStopped();
                                                toggleDialog();
                                            }}
                                            className="cancel_btn"
                                        >
                                            Отмена
                                        </span>
                                        <button className="green_btn">Отправить</button>
                                    </div>
                                </form>
                            )}
                        </Fragment>
                    )}
                </DialogComponent>
            </div>
        );
    }
}

const validate = values => {
    const errors = {};

    return errors;
};

ApplyBlogger = reduxForm({
    form: "ApplyBlogger",
    validate
})(ApplyBlogger);

function mapStateToProps(state) {
    return {
        deals_error: state.task_getter.deals_error,
        tasks: state.task_getter.tasks,
        campaignPage: state.campaignPage
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            postDealCreate,
            getCampaigns,
            postTask,
            resetError
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplyBlogger);
