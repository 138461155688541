import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import SignIn from "../../components/Authentication/SignIn/SignIn";
import SignUp from "../../components/Authentication/SignUp/SignUp";
import FirstStep from "../../components/Authentication/PasswordRecovery/FirstStep/FirstStep";
import SecondStep from "../../components/Authentication/PasswordRecovery/SecondStep/SecondStep";
import ThirdStep from "../../components/Authentication/PasswordRecovery/ThirdStep/ThirdStep";
import FourthStep from "../../components/Authentication/PasswordRecovery/FourthStep/FourthStep";
import NotificationCreated from "../../components/Authentication/AccountConfirmation/NotificationCreated";
import NotificationConfirmation from "../../components/Authentication/AccountConfirmation/NotificationConfirmation";

import Logo_aut from "../../assets/image/logo_aut.png";

import "./Authentication.scss";

class Authentication extends Component {
    constructor(props) {
        super(props);
        const { match } = this.props;
        this.baseUrl = match.url[match.url.length - 1] === "/" ? match.url : match.url + "/";
    }

    render() {
        const { match } = this.props;
        return (
            <div>
                <div className="authentication-display">
                    <div className="box_bg">
                        <div>
                            <img src={Logo_aut} alt="Logo_aut" />
                        </div>
                    </div>
                    <div className="form_aut">
                        <Switch>
                            <Route path={this.baseUrl} exact component={SignIn} />
                            <Route exact path={`${match.url}/sign-up`} component={SignUp} />
                            <Route exact path={`${match.url}/sign-up/complete`} component={NotificationCreated} />
                            <Route exact path={`${match.url}/confirm-email`} component={NotificationConfirmation} />
                            <Route exact path={`${match.url}/password-recovery/first-step`} component={FirstStep} />
                            <Route exact path={`${match.url}/password-recovery/second-step`} component={SecondStep} />
                            <Route path={`${match.url}/reset-password/approve/`} component={ThirdStep} />
                            <Route exact path={`${match.url}/password-recovery/fourth-step`} component={FourthStep} />
                            <Route exact path={`${match.url}/password-recovery/fourth-step`} component={FourthStep} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default Authentication;
