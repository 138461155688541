import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import { postMessage } from "../../../actions/dealsActions";
import Loader from "../../HelperComponents/Loader/Loader";

class ChatBlock extends Component {
    state = {
        textInput: ""
    };

    sendMessage = async () => {
        const { postMessage, dealId } = this.props;
        const { textInput } = this.state;
        await postMessage({ deal: dealId, msg: textInput });
        this.setState({ textInput: "" });
    };

    render() {
        const { chat, buttonLoading } = this.props;
        const { textInput } = this.state;
        return (
            <div className="chat_deals">
                <p>Чат сделки</p>
                {chat.length ? (
                    chat.map(({ msg, user, date }, key) => (
                        <div className="chat_block_info" key={`message_${key}`}>
                            <div className="chat_wrapper">
                                <span>{user}</span>
                                <p>{moment(date).format("DD.MM.YYYY HH:mm")}</p>
                            </div>
                            <div className="message">{msg}</div>
                        </div>
                    ))
                ) : (
                    <span>Пока нет сообщений в чате сделки</span>
                )}

                <div className="comment_wrapper">
                    <div className="descriptions_text">Сообщение</div>
                    <textarea
                        placeholder="Введите свое сообщение"
                        value={textInput}
                        onKeyDown={(e) => e.keyCode === 13 && textInput.length && this.sendMessage()}
                        onChange={(e) => this.setState({ textInput: e.target.value })}
                    />
                </div>
                <button className="border_btn_green" disabled={!textInput.length} onClick={this.sendMessage}>
                    {buttonLoading ? <Loader height={20} width={2} margin="2px" color={"#FFF"} /> : "Отправить"}
                </button>
            </div>
        );
    }
}

const mapStateToProps = ({ app }) => ({
    buttonLoading: app.buttonLoading
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            postMessage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatBlock);
