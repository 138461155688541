import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";

import no_deals from "../../../assets/image/no_deals.png";
import "./Deals.scss";
import moment from "moment";
import arbitrage from "../../../assets/image/arbitrage.svg";
import { dealStatuses } from "../../../helpers/constants";
import Pagination from "../../HelperComponents/Pagination/Pagination";

class Deals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1
        };
    }

    componentDidMount() {
        const { doDealsRequest } = this.props;
        doDealsRequest();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.campaign !== this.props.campaign) {
            this.props.doDealsRequest();
        }
    }

    getArbitrationTag = () => {
        return (
            <div className="arbitrage_icon">
                <img src={arbitrage} alt="arbitrage" className="arbitrage_icon" />
                <div className="tooltip">На арбитраже</div>
            </div>
        );
    };

    changePage = page => {
        this.setState({ activePage: page.selected + 1 });
    };

    render() {
        const {
            campaignPage: { deals },
            history
        } = this.props;

        const { activePage } = this.state;

        if (!deals) return null;
        return (
            <TransitionedBlock>
                <div className="deals block_wrapper_bg">
                    {deals.length === 0 ? (
                        <div className="no_tasks">
                            <div>
                                <img src={no_deals} alt="no_deals" />
                            </div>
                            <div className="descriptions">Пока нет ни одной сделки</div>
                        </div>
                    ) : (
                        <div className="deals_table">
                            <div className="table_container transactions_columns">
                                <div className="table_header">
                                    <div className="table_row">
                                        <div className="row_item">ID</div>
                                        <div className="row_item">Канал</div>
                                        <div className="row_item">Статус</div>
                                        <div className="row_item">Тип рекламы</div>
                                        <div className="row_item">Сумма</div>
                                        <div className="row_item">Дата/время</div>
                                    </div>
                                </div>
                                <div className="table_body">
                                    {deals.slice((activePage - 1) * 10, activePage * 10).map((item, idx) => {
                                        const dealStatus = dealStatuses.find(el => el.label === item.status) || {
                                            label: "Проверка гаранта",
                                            value: "guarantor_check",
                                            color: "#6AC798",
                                            arbitration: true
                                        };
                                        return (
                                            <div
                                                className="table_row"
                                                key={`deal_${item.id}`}
                                                onClick={() => {
                                                    history.push(`/main/deals-inner/${item.id}`);
                                                    window.scrollTo({ top: 0, behavior: "smooth" });
                                                }}
                                            >
                                                <div className="row_item">{item.id}</div>
                                                <div className="row_item">
                                                    {item.channel_img ? (
                                                        <img src={item.channel_img} alt="profile_picture" />
                                                    ) : null}
                                                    {item.channel_name}
                                                </div>
                                                <div className="row_item status">
                                                    <span style={{ backgroundColor: dealStatus.color }} />
                                                    {dealStatus.label.replace("нные", "на").replace("дают", "дает")}
                                                    {dealStatus.arbitration && this.getArbitrationTag()}
                                                </div>
                                                <div className="row_item">{item.type}</div>
                                                <div className="row_item">{item.amount} руб.</div>
                                                <div className="row_item">
                                                    {moment(item.creation_date).format("DD.MM.YYYY HH:mm")}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {deals.length > 10 && (
                                <div className="pagination_block">
                                    <Pagination
                                        current={activePage - 1}
                                        pageCount={Math.ceil(deals.length / 10)}
                                        onChange={this.changePage}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </TransitionedBlock>
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignPage: state.campaignPage
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Deals);
