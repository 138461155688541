import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";

import Loader from "../../HelperComponents/Loader/Loader";
import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";
import renderField_mail from "../../HelperComponents/RenderField/renderField_mail";
import renderField_pass from "../../HelperComponents/RenderField/RenderField_pass";
import SelectComponent from "../../HelperComponents/SelectComponent/SelectComponent";
import SnackBar from "../../HelperComponents/SnackBar/SnackBar";
import { getOption } from "../../../helpers/functions";

import { postRegister } from "../../../actions/authActions";

import CheckedIcon from "../../../assets/image/selected.png";
import UncheckedIcon from "../../../assets/image/unselected.png";

class SignUp extends Component {
    state = {
        role: "advertiser",
        loading: false,
        open: false,
        agreed: false,
        advertiser_type: { label: getOption("Я - юридическое лицо"), value: "UL" },
        advertiser_types: [
            { label: getOption("Я - юридическое лицо"), value: "UL" },
            { label: getOption("Я - индивидуальный предприниматель"), value: "IP" }
        ]
    };

    componentWillMount() {
        this.props.auth.error_auth = {};
    }

    changeRole = role => {
        this.setState({ role });
    };

    submitForm = data => {
        const { postRegister, history } = this.props;
        const { role, advertiser_type } = this.state;
        this.setState({ loading: true });
        let obj = { ...data, role: role };
        if (role === "advertiser") {
            obj.advertiser_type = advertiser_type.value;
        }
        setTimeout(() => {
            postRegister(obj).then(res => {
                if (res.payload && res.payload.status && res.payload.status === 201) {
                    history.push("/auth/sign-up/complete");
                } else {
                    this.setState({ loading: false });
                    this.toggleSnack();
                }
            });
        }, 500);
    };

    toggleSnack = () => {
        this.setState({ open: true });
        this.timeout = setTimeout(() => {
            this.setState({ open: false });
        }, 4000);
    };

    handleInput = () => {
        const {
            auth: { error_auth }
        } = this.props;
        if (error_auth && error_auth.length !== 0) {
            this.props.auth.error_auth = {};
        }
    };

    handleChange = name => event => this.setState({ [name]: event });

    render() {
        const {
            handleSubmit,
            submitting,
            pristine,
            valid,
            auth: { error_auth }
        } = this.props;
        const { role, loading, open, agreed, advertiser_type, advertiser_types } = this.state;

        return (
            <TransitionedBlock>
                <div className="authentication-form">
                    <div className="title_form">Регистрация</div>
                    <div className="aut_tabs">
                        <button
                            className={role === "advertiser" ? "active" : ""}
                            onClick={() => this.changeRole("advertiser")}
                        >
                            Рекламодатель
                        </button>
                        <button
                            className={role === "blogger" ? "active" : ""}
                            onClick={() => this.changeRole("blogger")}
                        >
                            Блогер
                        </button>
                    </div>
                    <div className="authentication-content">
                        <form className="form_auth" onSubmit={handleSubmit(this.submitForm)}>
                            {role === "advertiser" && (
                                <FormControl className="auth_select">
                                    <SelectComponent
                                        value={advertiser_type}
                                        options={advertiser_types}
                                        change={this.handleChange("advertiser_type")}
                                        isClearable="false"
                                        isSearchable={false}
                                    />
                                </FormControl>
                            )}
                            <Field
                                name="email"
                                type="text"
                                component={renderField_mail}
                                placeholder="Электронный адрес"
                                handleInput={this.handleInput}
                            />
                            {error_auth && error_auth.email && (
                                <span className="back_error error_sign_up">{error_auth.email}</span>
                            )}
                            <Field
                                name="password"
                                type="password"
                                component={renderField_pass}
                                placeholder="Пароль"
                                handleInput={this.handleInput}
                            />
                            {error_auth && error_auth.password && (
                                <span className="back_error error_sign_up">{error_auth.password}</span>
                            )}
                            <Field
                                name="password_check"
                                type="password"
                                component={renderField_pass}
                                placeholder="Повторите пароль"
                            />
                            <div className="read_and_agreed">
                                <img
                                    src={agreed ? CheckedIcon : UncheckedIcon}
                                    alt="agreed"
                                    onClick={() => this.setState({ agreed: !agreed })}
                                />
                                <p>
                                    Я прочитал и согласен с{" "}
                                    <Link to={"/public/public-offer?redirect=sign_up"}>Правилами платформы</Link> и{" "}
                                    <Link to={`/public/privacy-policy?redirect=sign_up`}>
                                        Политикой конфиденциальности
                                    </Link>
                                </p>
                            </div>
                            <button
                                className="green_btn_big"
                                type="submit"
                                disabled={submitting || pristine || !valid || !agreed}
                            >
                                {loading ? (
                                    <Loader height={20} width={2} margin="2px" color={"#FFF"} />
                                ) : (
                                    "Зарегистрироваться"
                                )}
                            </button>
                        </form>
                    </div>
                    <div className="info_aut">
                        <span>Уже есть аккаунт?</span>
                        <Link to={`/auth`}>Войти</Link>
                    </div>
                    <SnackBar open={open} noClosingIcon classes="error">
                        Упс! Что-то пошло не так.
                    </SnackBar>
                </div>
            </TransitionedBlock>
        );
    }
}

export const validate = values => {
    const errors = {};
    if (!values.email) {
        errors.email = "Обязательное поле";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
        errors.email = "Неправильный email";
    }
    if (!values.password) {
        errors.password = "Обязательное поле";
    } else if (values.password.length < 8) {
        errors.password = "Должно быть 8 символов или больше";
    }
    if (values.password !== values.password_check) {
        errors.password_check = "Пароли должны совпадать";
    }
    return errors;
};

SignUp = reduxForm({
    form: "SignUpForm",
    validate
})(SignUp);

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            postRegister
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
