import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";
import { postActivateAccount } from "../../../actions/authActions";

import success_large from "../../../assets/image/success_large.svg";

class NotificationConfirmation extends Component {
    constructor(props) {
        super(props);
        const { history } = this.props;
        const params = new URLSearchParams(history.location.search.substring(1));
        this.data = {
            code: params.get("code"),
            security_token: params.get("security_token"),
            user_id: +params.get("user_id")
        };
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        const { postActivateAccount } = this.props;
        postActivateAccount(this.data).then(() => this.setState({ loading: false }));
    }

    render() {
        if (this.state.loading) return null;
        return (
            <TransitionedBlock>
                <div className="authentication-form">
                    <div className="title_form">Активация аккаунта</div>
                    <div className="authentication-content">
                        <div className="form_auth fourth_step">
                            <div className="text_info">
                                Учётная запись подтверждена. Теперь Вы можете авторизоваться в системе.
                            </div>
                            <div className="box_icon_aut">
                                <img src={success_large} alt="success_large" />
                            </div>
                            <Link className="green_btn_big" to={`/auth`}>
                                Войти
                            </Link>
                        </div>
                    </div>
                    <div className="info_aut"></div>
                </div>
            </TransitionedBlock>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            postActivateAccount
        },
        dispatch
    );
}

export default connect(null, mapDispatchToProps)(NotificationConfirmation);
