import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactPlayer from "react-player/youtube";
import moment from "moment";

import { splitByCommas } from "../../../helpers/functions";

import comment from "../../../assets/image/comment.svg";
import views_icon from "../../../assets/image/views_icon.svg";
// import views_icon2 from "../../../assets/image/views_icon copy 2.svg";
// import crossing_icon from "../../../assets/image/crossing_icon.svg";
import thumbup from "../../../assets/image/thumb-up.svg";
import thumbdown from "../../../assets/image/thumb-down.svg";
import calendar_icon from "../../../assets/image/calendar_icon.svg";
import play_icon from "../../../assets/image/play_icon.svg";

import "./PromotionalBlock.scss";

class PromotionalBlock extends Component {
    render() {
        const { video_resource_service, video_url, video_data, sum } = this.props;
        return (
            <div className="promotional_block">
                <div className="title">Рекламный ролик</div>
                <div className="promotional_block_wrapper">
                    {video_resource_service === "youtube" ? (
                        <div className="block_video">
                            <ReactPlayer url={video_url} controls width="100%" height="100%" />
                        </div>
                    ) : (
                        <a href={video_url} target="_blank" rel="noopener noreferrer">
                            {video_url}
                        </a>
                    )}
                    {video_data && (
                        <div className="info_video">
                            <div className="info">
                                <div className="descriptions">{video_data.video_name}</div>
                                <div className="content_info">
                                    <div>
                                        <img src={calendar_icon} alt="calendar_icon" />{" "}
                                        {moment(video_data.published_at).format("DD.MM.YYYY")}
                                    </div>
                                    <div>
                                        <img src={play_icon} alt="play_icon" /> {splitByCommas(video_data.views)}
                                    </div>
                                    <div>
                                        <img src={comment} alt="comment" /> {video_data.comments}
                                    </div>
                                    <div>
                                        <div>
                                            <img src={thumbup} alt="thumbup" />{" "}
                                            {Math.round(
                                                (+video_data.likes / (+video_data.likes + +video_data.dislikes)) * 100
                                            )}
                                            %
                                        </div>
                                        <div className="line_vertical" />
                                        <div>
                                            <img src={thumbdown} alt="thumbdown" />{" "}
                                            {Math.round(
                                                (+video_data.dislikes / (+video_data.likes + +video_data.dislikes)) *
                                                    100
                                            )}
                                            %
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="line" />
                            <div className="statistics_wrapper">
                                <div className="statistics_block">
                                    <img src={views_icon} alt="views_icon" />
                                    <div>
                                        <span>{((sum / +video_data.views) * 1000).toFixed(2)} руб.</span>
                                        <p>за 1000 просмотров</p>
                                    </div>
                                </div>
                                {/*<div className="statistics_block">*/}
                                {/*    <img src={crossing_icon} alt="crossing_icon" />*/}
                                {/*    <div>*/}
                                {/*        <span>102 руб.</span>*/}
                                {/*        <p>за 1 переход</p>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="statistics_block">*/}
                                {/*    <img src={views_icon2} alt="views_icon2" />*/}
                                {/*    <div>*/}
                                {/*        <span>0.05%</span>*/}
                                {/*        <p>конверсия</p>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionalBlock);
