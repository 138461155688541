import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";

import "./Calendar.scss";

import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

export default function MaterialUIPickers({ handleDateChange, defaultValue = null }) {
    const [selectedDate, setSelectedDate] = React.useState(defaultValue);

    const handleDate = (date) => {
        setSelectedDate(date);
        handleDateChange(date);
    };

    return (
        <div className="datepicker">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    minDate={Date.now() + 86400000}
                    disabled={false}
                    invalidDateMessage="Неверная дата"
                    minDateMessage="Вы не можете выбрать прошедшую дату"
                    disablePast={true}
                    variant="dialog"
                    format="dd.MM.yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={selectedDate}
                    onChange={handleDate}
                    KeyboardButtonProps={{
                        "aria-label": "change date"
                    }}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
}
