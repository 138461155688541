import React from "react";

import UncheckedIcon from "../../../assets/image/unselected.png";
import CheckedIcon from "../../../assets/image/selected.png";
import PaperIcon from "../../../assets/image/paper.svg";

import "./DocumentsList.scss";

const DocumentsList = ({ documents, handleCheck, checked }) => {
    return (
        <div className="documents_wrapper">
            {documents.map(({ file, name }) => (
                <a download href={file} key={name}>
                    <img src={PaperIcon} alt="document" />
                    {decodeURIComponent(name)}
                </a>
            ))}
            <p onClick={() => handleCheck(!checked)}>
                <img src={checked ? CheckedIcon : UncheckedIcon} alt="checked" />С документами ознакомлен
            </p>
        </div>
    );
};

export default DocumentsList;
