import * as types from "../actions/constants";

export function offerCheck(data) {
    return {
        type: types.OFFER_CHECK,
        payload: {
            client: "default",
            request: {
                url: `/profile/`,
                method: "PATCH",
                data
            }
        }
    };
}

export function closeErrorSnack(data) {
    return {
        type: types.ERROR_SNACK_CLOSE
    };
}

export function closeSuccessSnack() {
    return {
        type: types.SUCCESS_SNACK_CLOSE
    };
}

export function handlePaymentDialog(data) {
    return {
        type: types.OPEN_PAYMENT_DETAILS,
        payload: data
    };
}

export function filterNotifications(id) {
    return {
        type: types.FILTER_NOTIFICATIONS,
        payload: id
    };
}

export function deleteAllNotifications() {
    return {
        type: types.DELETE_ALL_NOTIFICATIONS,
        payload: {
            client: "default",
            request: {
                url: `/notifications/`,
                method: "delete"
            }
        }
    };
}

export function deleteSpecificNotification(id) {
    return {
        type: types.DELETE_SPECIFIC_NOTIFICATION,
        payload: {
            client: "default",
            request: {
                url: `/notification/delete/${id}/`,
                method: "delete"
            }
        }
    };
}

export function getAllNotifications() {
    return {
        type: types.GET_ALL_NOTIFICATIONS,
        payload: {
            client: "default",
            request: {
                url: `/notifications/`,
                method: "get"
            }
        }
    };
}

export function getOfferPage() {
    return {
        type: types.GET_OFFER_PAGE,
        payload: {
            client: "default",
            request: {
                url: `/offer/`,
                method: "get"
            }
        }
    };
}

export function getPrivacyPolicyPage() {
    return {
        type: types.GET_PRIVACY_POLICY_PAGE,
        payload: {
            client: "default",
            request: {
                url: `/privacy-policy/`,
                method: "get"
            }
        }
    };
}

export function getFAQPage() {
    return {
        type: types.GET_FAQ_PAGE,
        payload: {
            client: "default",
            request: {
                url: `/faq/`,
                method: "get"
            }
        }
    };
}
