import React from "react";
import ReactStars from "react-stars";

// import { getRating } from "../../../helpers/functions";

import "./Rating.scss";

const Rating = ({ value }) => {
    return (
        <div className="rating_wrapper">
            <ReactStars count={5} size={15} value={value} edit={false} color1="#EFF2F4" color2="#F5A623" />
        </div>
    );
};

export default Rating;
