import * as types from "./constants.jsx";

export function postDealCreate(data) {
    return {
        type: types.POST_DEAL_CREATE,
        payload: {
            client: "default",
            request: {
                url: `/deal-create/`,
                method: "post",
                data
            }
        }
    };
}
