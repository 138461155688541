import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import TransitionedBlock from "../../../HelperComponents/TransitionedBlock/TransitionedBlock";
import Loader from "../../../HelperComponents/Loader/Loader";
import RenderField_mail from "../../../HelperComponents/RenderField/renderField_mail";

import { postResetPassword } from "../../../../actions/authActions";

class FirstStep extends Component {
    state = {
        loading: false
    };

    submitForm = (data) => {
        const { postResetPassword, history } = this.props;
        this.setState({ loading: true });
        let obj = { ...data };
        setTimeout(() => {
            postResetPassword(obj).then((res) => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    history.push("/auth/password-recovery/second-step");
                } else {
                    this.setState({ loading: false });
                }
            });
        }, 500);
    };

    render() {
        const { handleSubmit, submitting, pristine, valid } = this.props;
        const { loading } = this.state;

        return (
            <TransitionedBlock>
                <div className="authentication-form">
                    <div className="title_form">Восстановление пароля</div>
                    <div className="authentication-content">
                        <form className="form_auth" onSubmit={handleSubmit(this.submitForm)}>
                            <div className="text_info">
                                Введите электронный адрес, указанный Вами при регистрации, чтобы продолжить.
                            </div>
                            <Field
                                name="email"
                                className="phone-input"
                                type="text"
                                component={RenderField_mail}
                                placeholder="Электронный адрес"
                                autoComplete="off"
                            />
                            <button className="green_btn_big" type="submit" disabled={submitting || pristine || !valid}>
                                {loading ? <Loader height={20} width={2} margin="2px" color={"#FFF"} /> : "Продолжить"}
                            </button>
                        </form>
                    </div>
                    <div className="info_aut">
                        <span>Вспомнили пароль?</span>
                        <Link to={`/auth`}>Войти</Link>
                    </div>
                </div>
            </TransitionedBlock>
        );
    }
}

export const validate = (values) => {
    const errors = {};
    if (!values.email) {
        errors.email = "Обязательное поле";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
        errors.email = "Неправильный email";
    }
    return errors;
};

FirstStep = reduxForm({
    form: "FirstStep",
    validate
})(FirstStep);

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            postResetPassword
        },
        dispatch
    );
}

export default connect(null, mapDispatchToProps)(FirstStep);
