import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { getTransactions, getBalanceGeneralInfo, postWithdrawal } from "../../actions/financeActions";
import { getProfile } from "../../actions/profileActions";

import Pagination from "../HelperComponents/Pagination/Pagination";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import Loader from "../HelperComponents/ContentLoader/ContentLoader";
import { getTransaction, getTransactionTextStyle, getTransactionType, numberWithSpaces } from "../../helpers/functions";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";

import no_transactions from "../../assets/image/no_transactions.png";

import "./FinancePage.scss";

class FinancePage extends Component {
    state = {
        activePage: 1,
        openWithdrawalDialog: false,
        sumValue: "",
        bloggerInterface: localStorage.getItem("bloggerInterface") === "blogger",
        userData: {
            first_name: "",
            last_name: "",
            phone_number: "",
            company: "",
            email: "",
            email_notifications: false,
            payment_information: {
                payment_account: "",
                bank_code: "",
                inn: ""
            }
        }
    };

    componentDidMount() {
        const { getBalanceGeneralInfo, getProfile } = this.props;
        const { bloggerInterface } = this.state;
        getProfile().then((res) => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                const {
                    first_name,
                    last_name,
                    phone_number,
                    company,
                    email,
                    email_notifications,
                    payment_information
                } = res.payload.data;
                let paymentInformation = {
                    payment_account: this.isPropertyNull(payment_information.payment_account),
                    bank_code: this.isPropertyNull(payment_information.bank_code),
                    inn: this.isPropertyNull(payment_information.inn),
                    approved: this.isPropertyNull(payment_information.approved)
                };
                if (bloggerInterface) {
                    document.getElementById("payment_details_dialog").style.display = "none";
                } else {
                    paymentInformation = {
                        ...paymentInformation,
                        kpp: this.isPropertyNull(payment_information.kpp),
                        organisation_name: this.isPropertyNull(payment_information.organisation_name)
                    };
                }
                this.setState({
                    userData: {
                        first_name: this.isPropertyNull(first_name),
                        last_name: this.isPropertyNull(last_name),
                        phone_number: this.isPropertyNull(phone_number),
                        company: this.isPropertyNull(company),
                        email: this.isPropertyNull(email),
                        email_notifications: this.isPropertyNull(email_notifications),
                        payment_information: paymentInformation
                    }
                });
            }
        });
        getBalanceGeneralInfo().then(() => {
            this.fetchTransactions(1);
        });
    }

    isPropertyNull(value) {
        return value === null ? "" : value;
    }

    fetchTransactions = (activePage) => {
        const { getTransactions } = this.props;
        let arr = [];
        arr.push(`page=${activePage}`);
        arr.push(`page_size=10`);
        getTransactions(arr);
    };

    changePage = async (activePage) => {
        await this.fetchTransactions(activePage);
        this.setState({ activePage });
    };

    withdrawalDialog = () => {
        this.setState(({ openWithdrawalDialog }) => ({ openWithdrawalDialog: !openWithdrawalDialog }));
    };

    handlerKeyDown(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    }

    handleInputChangeSum = (e) => {
        if (!isNaN(Number(e.target.value))) {
            this.setState({ sumValue: e.target.value });
        }
    };

    sendWithdrawalData = () => {
        const { userData, sumValue } = this.state;
        const { postWithdrawal } = this.props;
        let withdrawalValue = {
            bank_account: userData.payment_information.payment_account,
            bank_code: userData.payment_information.bank_code,
            name: userData.payment_information.organisation_name,
            inn: userData.payment_information.inn,
            amount: sumValue
        };
        postWithdrawal(withdrawalValue).then((res) => {
            if (res.type === "POST_WITHDRAWAL_INFO_SUCCESS") {
                this.withdrawalDialog();
            }
        });
    };

    render() {
        const {
            generalInfo: { balance, reserved },
            transactions: { loading, count, results }
        } = this.props;
        const { activePage, openWithdrawalDialog, sumValue, userData } = this.state;

        return (
            <TransitionedBlock>
                <div className="content_block_page finance_page_wrapper">
                    <h1>Финансы</h1>
                    {loading ? (
                        <div className="flex-center">
                            <Loader height={65} width={5} margin="15px" color={"#5ce6cd"} />
                        </div>
                    ) : (
                        <Fragment>
                            <div className="finance_info">
                                <div>
                                    <div>
                                        Баланс: <p> {numberWithSpaces(balance)} </p> <span className="ru_value">₽</span>
                                    </div>
                                    <p style={{ fontSize: "12px" }}>
                                        <span className="reserved">Резерв:</span>
                                        <span className="ru_value">
                                            {numberWithSpaces(reserved)}
                                            &nbsp;₽
                                        </span>
                                    </p>
                                </div>
                                <button
                                    onClick={this.withdrawalDialog}
                                    style={
                                        userData.payment_information.approved
                                            ? { display: "block" }
                                            : { display: "none" }
                                    }
                                >
                                    вывод средств
                                </button>
                            </div>
                            {count === 0 ? (
                                <div className="no_finance block_wrapper_bg">
                                    <div>
                                        <img src={no_transactions} alt="no_transactions" />
                                    </div>
                                    <div className="descriptions">Пока нет ни одной транзакции</div>
                                </div>
                            ) : (
                                <div className="block_wrapper_bg">
                                    <div className="finance_table">
                                        <div className="table_container transactions_columns">
                                            <div className="table_header">
                                                <div className="table_row">
                                                    <div className="row_item">Дата/время</div>
                                                    <div
                                                        className="row_item"
                                                        style={{ fontFamily: "GothaProReg,sans-serif" }}
                                                    >
                                                        Сумма
                                                    </div>
                                                    <div className="row_item">Баланс до</div>
                                                    <div className="row_item">Баланс после</div>
                                                    <div className="row_item">Транзакция</div>
                                                    <div className="row_item">Тип</div>
                                                    <div className="row_item">Блогер</div>
                                                </div>
                                            </div>
                                            <div className="table_body">
                                                {results.map(
                                                    (
                                                        {
                                                            amount,
                                                            balance_after,
                                                            balance_before,
                                                            blogger,
                                                            date,
                                                            deal_id,
                                                            transaction_type,
                                                            type
                                                        },
                                                        key
                                                    ) => (
                                                        <div className="table_row" key={`transaction_${key}`}>
                                                            <div className="row_item">
                                                                <div>
                                                                    <span>{moment(date).format("DD.MM.YYYY")}</span>
                                                                    <span>{moment(date).format("HH:mm")}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row_item">
                                                                {numberWithSpaces(amount)} руб.
                                                            </div>
                                                            <div className="row_item">
                                                                {numberWithSpaces(balance_before)} руб.
                                                            </div>
                                                            <div className="row_item">
                                                                {numberWithSpaces(balance_after)} руб.
                                                            </div>
                                                            <div className="row_item">
                                                                {getTransactionType(transaction_type, deal_id)}
                                                            </div>
                                                            <div className="row_item ">
                                                                <div className={getTransactionTextStyle(type)}>
                                                                    {getTransaction(type)}
                                                                </div>
                                                            </div>
                                                            <div className="row_item">{blogger ? blogger : "-"}</div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {count > 10 && (
                                        <div className="pagination_block">
                                            <Pagination
                                                active={activePage - 1}
                                                pageCount={Math.ceil(count / 10)}
                                                onChange={(e) => {
                                                    window.scrollTo({ top: 0, behavior: "smooth" });
                                                    this.changePage(e.selected + 1);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </Fragment>
                    )}
                </div>
                <DialogComponent open={openWithdrawalDialog} onClose={this.withdrawalDialog}>
                    <div className="payment_details_dialog" id="payment_details_dialog">
                        <div className="big_title">Вывод средств</div>
                        <Fragment>
                            <div className="description">Проверьте правильность реквизитов для вывода средств.</div>
                            <div className="requisites_wrapper">
                                <div>
                                    <p>Номер счёта</p>
                                    <span>{userData.payment_information.payment_account}</span>
                                </div>
                                <div>
                                    <p>БИК банка</p>
                                    <span>{userData.payment_information.bank_code}</span>
                                </div>
                                <div>
                                    <p>КПП</p>
                                    <span>{userData.payment_information.kpp}</span>
                                </div>
                                <div>
                                    <p>Наименование получателя</p>
                                    <span>{userData.payment_information.organisation_name}</span>
                                </div>
                                <div>
                                    <p>ИНН получателя</p>
                                    <span>{userData.payment_information.inn}</span>
                                </div>
                                <form className="container-form payment_details_form finance_page_form">
                                    <div className="row">
                                        <div className="input_field">
                                            <label>Сумма</label>
                                            <input
                                                type="text"
                                                value={sumValue}
                                                placeholder="0"
                                                onChange={this.handleInputChangeSum}
                                                onKeyDown={this.handlerKeyDown}
                                            />
                                            <p>руб.</p>
                                        </div>
                                    </div>
                                    <div className="btn_wrapper">
                                        <button
                                            className="cancel_btn"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.withdrawalDialog();
                                            }}
                                        >
                                            Отмена
                                        </button>
                                        <button
                                            className="green_btn"
                                            type="submit"
                                            disabled={!sumValue}
                                            // title={isSubmitButtonDisabled ? "Для подтверждения необходимо заполнить хотя бы одно поле" : ""}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.sendWithdrawalData();
                                            }}
                                        >
                                            Вывести
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Fragment>
                    </div>
                </DialogComponent>
            </TransitionedBlock>
        );
    }
}

const mapStateToProps = ({ finance }) => {
    return {
        generalInfo: finance.generalInfo,
        transactions: finance.transactions,
        withdrawal: finance.withdrawal
    };
};
const mapDispatchToProps = {
    getBalanceGeneralInfo,
    getTransactions,
    getProfile,
    postWithdrawal
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancePage);
