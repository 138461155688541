export const isNumber = (value) => (value && isNaN(Number(value)) ? "Must be a number" : undefined);

export const isUrlValid = (value) =>
    value.match(/(https(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&/=]*)/g) == null
        ? "Enter a valid URL"
        : undefined;

const regExpIsNumber = /^[1-9]\d*$/gm;
export const regExpNumber = new RegExp(regExpIsNumber);

const regExpIsValidUrl = /^[a-zA-Z]+:\/\//;
export const regExpUrl = new RegExp(regExpIsValidUrl);
