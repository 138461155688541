import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactStars from "react-stars";
import moment from "moment";

import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import Rating from "../../HelperComponents/Rating/Rating";
import Loader from "../../HelperComponents/Loader/Loader";
import { postReview } from "../../../actions/dealsActions";

import "./FeedbackBlock.scss";

class FeedbackBlock extends Component {
    state = {
        open: false,
        ratingValue: 0,
        textInput: "",
        anonymous: false
    };

    toggleDialog = () => {
        this.setState(({ open }) => ({
            open: !open
        }));
    };

    sendFeedback = async () => {
        const { postReview, deal } = this.props;
        const { textInput, ratingValue, anonymous } = this.state;
        await postReview({ rating: ratingValue, deal, anonymous, comment: textInput });
        this.toggleDialog();
        this.setState({ textInput: "", ratingValue: 0, anonymous: false });
    };

    render() {
        const { review, buttonLoading } = this.props;
        const { open, ratingValue, textInput } = this.state;
        return (
            <div className="feedback_block">
                <div className="title">Отзыв</div>
                {review.comment ? (
                    <div className="rating_block_info">
                        <div className="rating_block">
                            <Rating value={review.rating} />
                            <p>{moment(review.date).format("DD.MM.YYYY")}</p>
                        </div>
                        <div className="descriptions">{review.comment}</div>
                    </div>
                ) : (
                    <div className="take_feedback">
                        <div>
                            Пожалуйста, оставьте отзыв о выполненной работе блогером. <br />
                            Это поможет другим рекламодателям определиться с выбором канала для размещения рекламы.
                        </div>
                        <button className="border_btn_green" onClick={this.toggleDialog}>
                            Оставить отзыв
                        </button>
                    </div>
                )}
                <DialogComponent open={open} onClose={this.toggleDialog}>
                    <div className="leave_feedback">
                        <div className="title">Оставить отзыв</div>
                        <span className="descriptions">Оценка</span>
                        <div className="rating_wrapper">
                            <ReactStars
                                count={5}
                                size={15}
                                value={ratingValue}
                                edit={true}
                                onChange={(new_rating) => this.setState({ ratingValue: new_rating })}
                                half={false}
                                color1="#EFF2F4"
                                color2="#F5A623"
                            />
                        </div>
                        <div className="commends">
                            <span>Комментарий к оценке</span>
                            <textarea
                                placeholder="Добавьте комментарий о работе блогера"
                                value={textInput}
                                onChange={(e) => this.setState({ textInput: e.target.value })}
                            />
                        </div>
                        <div className="col col-checkbox">
                            <input
                                type="checkbox"
                                id="check1"
                                className="inp-checkbox"
                                onClick={(e) => this.setState({ anonymous: e.target.checked })}
                            />
                            <label htmlFor="check1">Анонимный отзыв</label>
                        </div>
                        <div className="btn_wrapper_block">
                            <button
                                className="border_btn_green"
                                onClick={this.sendFeedback}
                                disabled={!textInput.length}
                            >
                                {buttonLoading ? (
                                    <Loader height={20} width={2} margin="2px" color={"#FFF"} />
                                ) : (
                                    "Отправить"
                                )}
                            </button>
                        </div>
                    </div>
                </DialogComponent>
            </div>
        );
    }
}

function mapStateToProps({ app }) {
    return {
        buttonLoading: app.buttonLoading
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            postReview
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackBlock);
