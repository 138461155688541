import * as types from "./constants.jsx";
import { joinQueries } from "../helpers/functions";

export function getDeals(arr) {
    return {
        type: types.GET_DEALS,
        payload: {
            client: "default",
            request: {
                url: `/campaign/deals/${joinQueries(arr)}`,
                method: "get"
            }
        }
    };
}

export function getCurrentDeal(id) {
    return {
        type: types.GET_CURRENT_DEAL,
        payload: {
            client: "default",
            request: {
                url: `/campaign/deal/${id}/`,
                method: "get"
            }
        }
    };
}

export function patchCurrentDeal(id, data) {
    return {
        type: types.PATCH_CURRENT_DEAL,
        payload: {
            client: "default",
            request: {
                url: `/campaign/deal-update/${id}/`,
                method: "patch",
                data
            }
        }
    };
}

export function patchDealWithVideo(id, data) {
    return {
        type: types.PATCH_DEAL_WITH_VIDEO,
        payload: {
            client: "default",
            request: {
                url: `/blogger/campaign/${id}/status-to-awaiting-verification/`,
                method: "patch",
                data
            }
        }
    };
}

export function patchDealConditions(id, data) {
    return {
        type: types.PATCH_DEAL_CONDITIONS,
        payload: {
            client: "default",
            request: {
                url: `/campaign/deal-update/${id}/conditions-approval/`,
                method: "patch",
                data
            }
        }
    };
}

export function patchDealArbitration(id, data) {
    return {
        type: types.PATCH_DEAL_ARBITRATION,
        payload: {
            client: "default",
            request: {
                url: `/campaign/deal-update/${id}/arbitrage/`,
                method: "patch",
                data
            }
        }
    };
}

export function postMessage(data) {
    return {
        type: types.POST_MESSAGE,
        payload: {
            client: "default",
            request: {
                url: `/campaign/deal-message/`,
                method: "post",
                data
            }
        }
    };
}

export function postReview(data) {
    return {
        type: types.POST_REVIEW,
        payload: {
            client: "default",
            request: {
                url: `/review-create/`,
                method: "post",
                data
            }
        }
    };
}

export function clearDealInfo() {
    return {
        type: types.CLEAR_CURRENT_DEAL
    };
}

export function resetDealErrors() {
    return {
        type: types.RESET_DEAL_ERRORS
    };
}
