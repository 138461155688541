import * as types from "../actions/constants";

const INITIAL_STATE = {
    campaign: {},
    tasks: [],
    deals: [],
    transactions: [],

    error: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_CERTAIN_CAMPAIGN_SUCCESS:
            return { ...state, campaign: action.payload.data };
        case types.GET_TASKS_SUCCESS:
            return { ...state, tasks: action.payload.data.tasks };
        case types.GET_CAMPAIGN_DEALS_SUCCESS:
            return { ...state, deals: action.payload.data.deals };
        case types.GET_CAMPAIGN_TRANSACTIONS_SUCCESS:
            return { ...state, transactions: action.payload.data.transactions };

        case types.POST_TASK_FAIL:
            return { ...state, error: action.error.response.data };
        case types.PATCH_TASK_FAIL:
            return { ...state, error: action.error.response.data };

        case types.RESET_ERROR:
            return { ...state, error: action.error };
        default:
            return state;
    }
}
