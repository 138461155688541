import * as types from "../actions/constants";

const INITIAL_STATE = {
    generalInfo: {},
    transactions: { loading: true },
    withdrawal: null
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_BALANCE_GENERAL_INFO_SUCCESS:
            return { ...state, generalInfo: action.payload.data };
        case types.GET_TRANSACTIONS_SUCCESS:
            return { ...state, transactions: action.payload.data };
        case types.POST_WITHDRAWAL_INFO_SUCCESS:
            return { ...state, withdrawal: action.payload.data };
        default:
            return state;
    }
}
