import * as types from "./constants.jsx";

export function getCampaigns() {
    return {
        type: types.GET_CAMPAIGNS,
        payload: {
            client: "default",
            request: {
                url: `/campaigns/`,
                method: "get"
            }
        }
    };
}

export function postCampaigns(data) {
    return {
        type: types.POST_CAMPAIGNS,
        payload: {
            client: "default",
            request: {
                url: `/campaigns/`,
                method: "post",
                data
            }
        }
    };
}

export function resetErrorCampaigns() {
    return { type: types.RESET_CAMPAIGNS_ERROR };
}
