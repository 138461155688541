import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Rating from "../../HelperComponents/Rating/Rating";
import Pagination from "../../HelperComponents/Pagination/Pagination";
import { getterReview } from "../../../actions/reviewsAction";

import "./Reviews.scss";

class Reviews extends Component {
    state = {
        activePage: 0,
        commentsPerPage: 10
    };

    componentDidMount() {
        const { getterReview, id } = this.props;
        getterReview(id, 0, this.state.commentsPerPage);
    }

    changePage = (pageNumber) => {
        const { getterReview, id } = this.props;

        getterReview(id, pageNumber.selected, this.state.commentsPerPage);
        /*this.setState({
            activePage: pageNumber.selected
        })
        console.log(this.state.activePage)*/
    };

    render() {
        const {
            reviews: { count, results }
        } = this.props;
        if (results === undefined) {
            return null;
        }

        return (
            <div className="blogger_reviews block_wrapper_bg">
                {count !== 0 ? (
                    <React.Fragment>
                        <div className="title">Отзывы рекламодателей</div>
                        <div className="assessment">
                            <span>{results.avg_rating.toFixed(1)}</span>
                            <p>Средняя оценка</p>
                        </div>
                    </React.Fragment>
                ) : (
                    <div className="no_reviews">Отзывов нет</div>
                )}

                {results.reviews.map((item, index) => {
                    let date = item.date.slice(0, 10).split("-");
                    //time = item.date.slice(11, 19);

                    return (
                        <div key={index} className="reviews">
                            <div className="descriptions">
                                <div style={item.user === "Анонимный отзыв" ? { color: "#979797" } : {}}>
                                    {item.user}
                                </div>
                                <span>
                                    {date.reverse().join(".")} {/*time*/}
                                </span>
                            </div>
                            <div className="rating_block">
                                <Rating value={item.rating} />
                                <p>{item.deal_type}</p>
                            </div>
                            <div className="text">{item.comment}</div>
                        </div>
                    );
                })}

                {count > 10 ? (
                    <Pagination
                        active={this.state.activePage}
                        pageCount={Math.ceil(count / this.state.commentsPerPage)}
                        pageItemsCount={this.state.commentsPerPage}
                        commentTotalCount={count}
                        onChange={this.changePage}
                    />
                ) : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        reviews: state.reviews.review_list
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getterReview
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
