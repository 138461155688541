import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { renderField } from "../../CampaingPage/CampaingPage";
import Loader from "../../HelperComponents/Loader/Loader";
import DocumentsList from "../../HelperComponents/DocumentsList/DocumentsList";

class ConfirmDealForm extends Component {
    state = {
        documentsReviewed: false
    };

    render() {
        const {
            toggleDialog,
            confirmDeal,
            handleSubmit,
            buttonLoading,
            dealError,
            deal: { payment_ib_file, act_ib_file }
        } = this.props;
        const { documentsReviewed } = this.state;
        return (
            <form className="block_info_blogger" onSubmit={handleSubmit(confirmDeal)}>
                <div className="form_video_wrapper">
                    <div className="radio_wrapper">
                        <label>
                            <Field name="video_resource_service" component="input" type="radio" value="youtube" />
                            YouTube
                        </label>
                        <label>
                            <Field name="video_resource_service" component="input" type="radio" value="other_service" />
                            Другой сервис
                        </label>
                    </div>
                    <div className="video_field">
                        <Field name="video_url" component={renderField} type="text" placeholder="Вставьте ссылку" />
                        {dealError && dealError.video_url && <span className="back_error">{dealError.video_url}</span>}
                    </div>
                </div>
                <DocumentsList
                    documents={[
                        {
                            file: payment_ib_file,
                            name: payment_ib_file
                                ? payment_ib_file.split("/")[payment_ib_file.split("/").length - 1]
                                : "Счёт на оплату ib"
                        },
                        {
                            file: act_ib_file,
                            name: act_ib_file
                                ? act_ib_file.split("/")[act_ib_file.split("/").length - 1]
                                : "Акт об оказании услуг ib"
                        }
                    ]}
                    handleCheck={(documentsReviewed) => this.setState({ documentsReviewed })}
                    checked={documentsReviewed}
                />
                <div className="btn_wrapper">
                    <button
                        className="cancel_btn"
                        onClick={(e) => {
                            e.preventDefault();
                            toggleDialog("confirm_dialog");
                        }}
                    >
                        Отмена
                    </button>
                    <button className="border_btn_green" disabled={!documentsReviewed}>
                        {buttonLoading ? <Loader height={20} width={2} margin="2px" color={"#FFF"} /> : "Отправить"}
                    </button>
                </div>
            </form>
        );
    }
}

ConfirmDealForm = reduxForm({
    form: "ConfirmDealForm"
})(ConfirmDealForm);

function mapStateToProps({ app, deals }) {
    return {
        buttonLoading: app.buttonLoading,
        deal: deals.deal,
        dealError: deals.dealError,
        initialValues: {
            video_resource_service: "youtube"
        }
    };
}

export default connect(mapStateToProps)(ConfirmDealForm);
