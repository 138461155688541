import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";
import Pagination from "../../HelperComponents/Pagination/Pagination";
import no_transactions from "../../../assets/image/no_transactions.png";

import "./Finance.scss";
import moment from "moment";
import {
    getTransaction,
    getTransactionTextStyle,
    getTransactionType,
    numberWithSpaces
} from "../../../helpers/functions";

class Finance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1
        };
    }

    componentDidMount() {
        const { doTransactionsRequest } = this.props;
        doTransactionsRequest();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.campaign !== this.props.campaign) {
            this.props.doTransactionsRequest();
        }
    }

    changePage = (page) => {
        this.setState({ activePage: page.selected + 1 });
    };

    render() {
        const {
            campaignPage: { transactions }
        } = this.props;
        const { activePage } = this.state;
        if (!transactions) return null;
        return (
            <TransitionedBlock>
                <div className="finance ">
                    {transactions.length === 0 ? (
                        <div className="no_finance block_wrapper_bg">
                            <div>
                                <img src={no_transactions} alt="no_transactions" />
                            </div>
                            <div className="descriptions">Пока нет ни одной транзакции</div>
                        </div>
                    ) : (
                        <div className="block_wrapper_bg">
                            {/*<div className="finance_info">*/}
                            {/*    <div>*/}
                            {/*        Основной портфель: <p> 38 000,00 </p> <span className="ru_value">₽</span>*/}
                            {/*    </div>*/}
                            {/*    <p>*/}
                            {/*        Резервный портфель: 6 000,00 <span className="ru_value">₽</span>*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                            <div className="finance_table">
                                <div className="table_container transactions_columns">
                                    <div className="table_header">
                                        <div className="table_row">
                                            <div className="row_item">Дата/время</div>
                                            <div className="row_item">Сумма</div>
                                            <div className="row_item">Баланс до</div>
                                            <div className="row_item">Баланс после</div>
                                            <div className="row_item">Транзакция</div>
                                            <div className="row_item">Тип</div>
                                            <div className="row_item">Блогер</div>
                                        </div>
                                    </div>
                                    <div className="table_body">
                                        {transactions.slice((activePage - 1) * 10, activePage * 10).map((item, idx) => (
                                            <div className="table_row" key={idx}>
                                                <div className="row_item">
                                                    <div>
                                                        <span>{moment(item.date).format("DD.MM.YYYY")}</span>
                                                        <span>{moment(item.date).format("HH:mm")}</span>
                                                    </div>
                                                </div>

                                                <div className="row_item">{numberWithSpaces(item.amount)} руб.</div>
                                                <div className="row_item">
                                                    {numberWithSpaces(item.balance_before)} руб.
                                                </div>
                                                <div className="row_item">
                                                    {numberWithSpaces(item.balance_after)} руб.
                                                </div>
                                                <div className="row_item">
                                                    {getTransactionType(item.transaction_type, item.deal_id)}
                                                </div>
                                                <div className="row_item ">
                                                    <div className={getTransactionTextStyle(item.type)}>
                                                        {getTransaction(item.type)}
                                                    </div>
                                                </div>
                                                <div className="row_item">{item.blogger ? item.blogger : "-"}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {transactions.length > 10 && (
                                <div className="pagination_block">
                                    <Pagination
                                        current={activePage - 1}
                                        pageCount={Math.ceil(transactions.length / 10)}
                                        onChange={this.changePage}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </TransitionedBlock>
        );
    }
}

function mapStateToProps(state) {
    return {
        campaignPage: state.campaignPage
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Finance);
