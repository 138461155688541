import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Loader from "../../HelperComponents/ContentLoader/ContentLoader";
import LineChart from "../../HelperComponents/LineChart/LineChart";

import { getBloggersStatistics } from "../../../actions/bloggerAction";

import "./Statistics.scss";

class Statistics extends Component {
    state = {
        loading: true,
        stat: {}
    };

    componentDidMount() {
        const { id, getBloggersStatistics } = this.props;
        getBloggersStatistics(id).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ loading: false, stat: res.payload.data });
            }
        });
    }

    render() {
        const {
            loading,
            stat: { subscribers, views }
        } = this.state;

        if (loading /* || !subscribers || subscribers.length === 0 */)
            return (
                <div className="blogger_loader">
                    <Loader height={65} width={5} margin="15px" color={"#5ce6cd"} />
                </div>
            );
        console.log(subscribers, views);
        return (
            <div className="statistics_wrapper">
                <div className="block_wrapper_bg">
                    <div className="statistics_title">Подписчики</div>
                    <div className="subscribers_chart">
                        <LineChart data={!!subscribers ? subscribers : [["", 0]]} />
                    </div>
                    <div className="statistics_title">Просмотры</div>
                    <div className="views_chart">
                        <LineChart data={!!views ? views : [["", 0]]} />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        subscribers: state.bloggers.subscribers,
        views: state.bloggers.views
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getBloggersStatistics
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
