import * as types from "../actions/constants";

const INITIAL_STATE = {
    header: {},
    error: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.PATCH_BLOGGER_HEADER:
            return { ...state, header: action.payload.data };
        case types.PATCH_BLOGGER_HEADER_FAIL:
            return { ...state, error: action.error.response.data };
        default:
            return state;
    }
}
