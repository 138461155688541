import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./TaskBlock.scss";

class TaskBlock extends Component {
    state = {
        textOverflow: true
    };

    toggleText = () => {
        this.setState(({ textOverflow }) => ({
            textOverflow: !textOverflow
        }));
    };

    render() {
        const {
            task: { ad_link, task_text }
        } = this.props;
        const { textOverflow } = this.state;
        return (
            <div className="task_block">
                <div className="info_title">
                    <div>Задание</div>
                    <p>Обязательный предпросмотр перед публикацией, Публичное задание</p>
                </div>
                {ad_link && (
                    <div className="link_advertising">
                        <div>Рекламная ссылка</div>
                        <a href={ad_link} target="_blank" rel="noopener noreferrer">
                            {ad_link}
                        </a>
                    </div>
                )}
                <div className="descriptions_projects">
                    <div className="title">Описание задания</div>
                    <div className={textOverflow && task_text.length > 1000 ? "text" : "text full_height"}>
                        {task_text}
                    </div>
                    {task_text.length > 1000 && (
                        <button onClick={this.toggleText}>
                            {textOverflow ? (
                                <Fragment>
                                    <span className="down" />
                                    показать полностью
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <span className="up" />
                                    скрыть
                                </Fragment>
                            )}
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskBlock);
