import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GoogleLogin from "react-google-login";
import { googleAuth } from "../../actions/authActions";

class TestYoutubeAPI extends Component {
    signUp = (response) => {
        const { googleAuth } = this.props;
        let obj = { token: response.code };
        googleAuth(obj).then((res) => {
            if (res.payload) {
                window.alert("Noice");
            }
        });
    };

    render() {
        const responseGoogle = (response) => {
            this.signUp(response);
        };
        return (
            <div className="content_block blogger_wrapper">
                <h1>Очень тестовая кнопка</h1>
                <br />
                <GoogleLogin
                    clientId="783014202261-tros84e62de1i1i4qic94jc1g5o06qdj.apps.googleusercontent.com"
                    scope="https://www.googleapis.com/auth/yt-analytics.readonly https://www.googleapis.com/auth/youtubepartner-channel-audit"
                    render={(renderProps) => (
                        <button onClick={renderProps.onClick} className="green_btn">
                            <span>Войти с помощью Google</span>
                        </button>
                    )}
                    buttonText="Войти с помощью Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                    accessType="offline"
                    prompt="consent"
                    responseType="code"
                    redirectUri="https://imast.4-com.pro/main/test-youtube-api"
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            googleAuth
        },
        dispatch
    );
}

export default connect(null, mapDispatchToProps)(TestYoutubeAPI);
