import React, { Fragment } from "react";
import { Field, reduxForm } from "redux-form";

import FormControl from "@material-ui/core/FormControl";
import MultiSelectComponent from "../HelperComponents/MultiSelectComponent/MultiSelectComponent";
import { start_categories } from "../Blogger/AboutChannelBlogger/AboutChannelBlogger";
import { start_genres } from "../Blogger/AboutChannelBlogger/AboutChannelBlogger";
import {
    getOption,
    renderApprobationRadio,
    renderActivityRadio,
    renderInvolvementRadio,
    renderCountriesList,
    renderAgeRadio
} from "../../helpers/functions";
import RenderCheckField from "../HelperComponents/RenderField/RenderCheckField/RenderCheckField";
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent";

import male from "../../assets/image/male.svg";
import female from "../../assets/image/female.svg";
import filters_icon from "../../assets/image/filters.svg";

import "./BloggersSearch.scss";
import "rc-slider/assets/index.css";

const Slider = require("rc-slider");
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

class BloggersFilters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            minSubscribers: 0,
            maxSubscribers: 500000,
            minViews: 0,
            maxViews: 100000,
            minPrice: 0,
            maxPrice: 100000,
            genderArray: [],
            chosen_categories: [],
            chosen_genres: [],
            categories_numbers: [],
            genres_numbers: [],
            ad_options: [
                { label: getOption("Bce"), value: "" },
                { label: getOption("В начале видео"), value: "preroll" },
                { label: getOption("В середине видео"), value: "middle" },
                { label: getOption("В конце видео"), value: "postroll" },
                { label: getOption("Интеграция в видео"), value: "video_integration" },
                { label: getOption("Ссылка в сообществе канала"), value: "society_link" },
                { label: getOption("Заказной ролик"), value: "customized_video" },
                { label: getOption("Реклама в YouTube-истории"), value: "stories_ad" }
            ],
            ad_type: { label: getOption("Bce"), value: "" }
        };
    }

    handleRange = (value, min, max) => {
        this.setState({ [min]: Number(value[0]), [max]: Number(value[1]) });
    };

    setMin = (e, name) => {
        this.setState({ [name]: Number(e.target.value) });
    };

    setMax = (e, name) => this.setState({ [name]: Number(e.target.value) });

    handleGender = (gender) => {
        const { genderArray } = this.state;
        if (genderArray.includes(gender)) {
            this.setState({ genderArray: genderArray.filter((el) => el !== gender) });
        } else {
            this.setState({ genderArray: [...genderArray, gender] });
        }
    };

    handleMultiSelection = (e, name) => {
        const categories = name === "chosen_categories" ? "categories_numbers" : "genres_numbers";
        let newArr = [];
        for (let i = 0; i < e.target.value.length; i++) {
            if (name === "chosen_categories") {
                if (start_categories.indexOf(e.target.value[i]) !== -1) {
                    newArr.push(start_categories.indexOf(e.target.value[i]) + 1);
                }
            } else {
                if (start_genres.indexOf(e.target.value[i]) !== -1) {
                    newArr.push(start_genres.indexOf(e.target.value[i]) + 1);
                }
            }
        }
        this.setState({ [name]: e.target.value, [categories]: newArr });
    };

    changeAdOptions = (event) => this.setState({ ad_type: event });

    submitForm = (data) => {
        const { submitFilters } = this.props;
        const {
            minSubscribers,
            maxSubscribers,
            genderArray,
            categories_numbers,
            genres_numbers,
            ad_type,
            minPrice,
            maxPrice,
            minViews,
            maxViews
        } = this.state;
        console.log(data);
        let filters = [];
        filters.push(`subscribers=${minSubscribers},${maxSubscribers}`);
        if (genderArray.length) filters.push(`gender=${genderArray.join(",")}`);
        if (data.age) filters.push(`age=${data.age}`);
        if (categories_numbers.length > 0) filters.push(`categories=${categories_numbers.join(",")}`);
        if (genres_numbers.length > 0) filters.push(`genres=${genres_numbers.join(",")}`);
        if (data.ru && data.en) {
            filters.push(`language=ru,en`);
        } else {
            if (data.ru) {
                filters.push(`language=ru`);
            }
            if (data.en) {
                filters.push(`language=en`);
            }
        }
        let countries = [];
        if (data.RU) countries.push("RU");
        if (data.UA) countries.push("UA");
        if (data.BY) countries.push("BY");
        if (data.KZ) countries.push("KZ");
        if (data.US) countries.push("US");
        if (countries.length > 0) filters.push(`country=${countries.join(",")}`);
        if (ad_type.value) filters.push(`ad_type=${ad_type.value}`);
        filters.push(`ad_price=${minPrice},${maxPrice}`);
        filters.push(`avg_views=${minViews},${maxViews}`);
        if (data.activity) filters.push(`activity=${data.activity}`);
        if (data.involvement) filters.push(`involvement=${data.involvement}`);
        if (data.approbation) filters.push(`approbation=${data.approbation}`);
        submitFilters(filters);
    };

    resetFilters = () => {
        const { filtersStyle, toggleFilters, submitFilters, reset } = this.props;
        if (filtersStyle === "filters_opened") toggleFilters();
        reset("BloggersFilters");
        this.setState({
            minSubscribers: 0,
            maxSubscribers: 500000,
            minViews: 0,
            maxViews: 100000,
            minPrice: 0,
            maxPrice: 100000,
            genderArray: [],
            chosen_categories: [],
            chosen_genres: [],
            categories_numbers: [],
            genres_numbers: [],
            ad_type: { label: getOption("Bce"), value: "" }
        });
        submitFilters("reset");
    };

    render() {
        const {
            minSubscribers,
            maxSubscribers,
            minViews,
            maxViews,
            minPrice,
            maxPrice,
            genderArray,
            chosen_categories,
            chosen_genres,
            ad_type,
            ad_options
        } = this.state;
        const { filtersStyle, toggleFilters, handleSubmit } = this.props;
        return (
            <Fragment>
                <button className={`filters_btn ${filtersStyle}`} onClick={toggleFilters}>
                    <span>
                        <img src={filters_icon} alt="search_icon" />
                        ФИЛЬТРЫ
                    </span>
                </button>
                <div className={`filters_wrapper ${filtersStyle}_wrapper`}>
                    <form onSubmit={handleSubmit(this.submitForm)}>
                        <div className="filters_block">
                            <div className="filter_block">
                                <div className="filters_bigger_title">Подписчики</div>
                                <div className="filters-input">
                                    <input
                                        type="number"
                                        value={minSubscribers}
                                        onChange={(e) => this.setMin(e, "minSubscribers")}
                                    />
                                    <div className="arrow" />
                                    <input
                                        type="number"
                                        value={maxSubscribers}
                                        onChange={(e) => this.setMax(e, "maxSubscribers")}
                                    />
                                </div>
                                <Range
                                    value={[minSubscribers, maxSubscribers]}
                                    min={0}
                                    max={200000000}
                                    onChange={(value) => this.handleRange(value, "minSubscribers", "maxSubscribers")}
                                />
                            </div>
                            <div className="filter_block">
                                <div className="filters_bigger_title">Просмотры на 1 видео</div>
                                <div className="filters-input">
                                    <input type="text" value={minViews} onChange={(e) => this.setMin(e, "minViews")} />
                                    <div className="arrow" />
                                    <input type="text" value={maxViews} onChange={(e) => this.setMax(e, "maxViews")} />
                                </div>
                                <Range
                                    value={[minViews, maxViews]}
                                    min={0}
                                    max={10000000}
                                    onChange={(value) => this.handleRange(value, "minViews", "maxViews")}
                                />
                            </div>
                        </div>
                        <div className="filters_block">
                            <div className="filter_block">
                                <div className="filters_bigger_title">Демография</div>
                                <div className="filters_smaller_title">Пол</div>
                                <div className="filters_gender">
                                    <button
                                        className={genderArray.includes("male") ? "active" : ""}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.handleGender("male");
                                        }}
                                    >
                                        <img src={male} alt="male" />
                                    </button>
                                    <button
                                        className={genderArray.includes("female") ? "active" : ""}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.handleGender("female");
                                        }}
                                    >
                                        <img src={female} alt="female" />
                                    </button>
                                </div>
                                <div className="filters_smaller_title">Возраст</div>
                                {renderAgeRadio()}
                            </div>
                            <div className="filter_block">
                                <div className="filters_bigger_title">Категория</div>
                                <div className="multi_div">
                                    <MultiSelectComponent
                                        item={chosen_categories}
                                        items={start_categories}
                                        handleChange={(e) => this.handleMultiSelection(e, "chosen_categories")}
                                        maxItems={3}
                                        label={"Категория"}
                                        placeholder={"Выберите категории"}
                                    />
                                    <MultiSelectComponent
                                        item={chosen_genres}
                                        items={start_genres}
                                        handleChange={(e) => this.handleMultiSelection(e, "chosen_genres")}
                                        maxItems={3}
                                        label={"Жанры"}
                                        placeholder={"Выберите жанры"}
                                    />
                                </div>
                            </div>
                            <div className="filter_block">
                                <div className="filters_bigger_title">Аудитория</div>
                                <div className="filters_smaller_title">Язык канала</div>
                                <div className="filter_labels">
                                    <div className="checkbox">
                                        <label>
                                            <Field
                                                name="ru"
                                                component={RenderCheckField}
                                                type="checkbox"
                                                className="green_checkbox"
                                            />
                                            <span />
                                            Русский
                                        </label>
                                    </div>
                                </div>
                                <div className="filter_labels">
                                    <div className="checkbox">
                                        <label>
                                            <Field
                                                name="en"
                                                component={RenderCheckField}
                                                type="checkbox"
                                                className="green_checkbox"
                                            />
                                            <span />
                                            Английский
                                        </label>
                                    </div>
                                </div>
                                <br />
                                <div className="filters_smaller_title">Страна</div>
                                {renderCountriesList()}
                            </div>
                        </div>
                        <div className="filters_block">
                            <div className="filter_block">
                                <div className="filters_bigger_title">Реклама</div>
                                <div className="filters_smaller_title">Тип рекламы</div>
                                <FormControl className="select_wrapper">
                                    <SelectComponent
                                        value={ad_type}
                                        options={ad_options}
                                        change={this.changeAdOptions}
                                        isClearable="false"
                                        isSearchable={false}
                                    />
                                </FormControl>
                                <div className="filters_smaller_title">Цена, руб</div>
                                <div className="filters-input">
                                    <input type="text" value={minPrice} onChange={(e) => this.setMin(e, "minPrice")} />
                                    <div className="arrow" />
                                    <input type="text" value={maxPrice} onChange={(e) => this.setMax(e, "maxPrice")} />
                                </div>
                                <Range
                                    value={[minPrice, maxPrice]}
                                    min={0}
                                    max={100000}
                                    onChange={(value) => this.handleRange(value, "minPrice", "maxPrice")}
                                />
                            </div>
                            <div className="filter_block">
                                <div className="filters_bigger_title">Рейтинг</div>
                                <div className="filters_smaller_title">Активность</div>
                                {renderActivityRadio()}
                            </div>
                            <div className="filter_block">
                                <br />
                                <br />
                                <div className="filters_smaller_title">Вовлеченность</div>
                                {renderInvolvementRadio()}
                            </div>
                            <div className="filter_block">
                                <br />
                                <br />
                                <div className="filters_smaller_title">Одобрение</div>
                                {renderApprobationRadio()}
                            </div>
                        </div>
                        <div className="buttons_wrapper">
                            <button
                                className="cancel_btn"
                                onClick={(e) => {
                                    e.preventDefault();
                                    toggleFilters();
                                }}
                            >
                                Отмена
                            </button>
                            <button className="green_btn">Применить</button>
                        </div>
                    </form>
                </div>
                <button className="reset_filters" onClick={this.resetFilters}>
                    СБРОСИТЬ
                </button>
            </Fragment>
        );
    }
}

BloggersFilters = reduxForm({
    form: "BloggersFilters",
    enableReinitialize: true,
    initialValues: {
        involvement: "",
        age: "",
        activity: "",
        approbation: ""
    },
    destroyOnUnmount: false
})(BloggersFilters);

export default BloggersFilters;
