import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import clsx from "clsx";

import direction from "../../../assets/image/direction.png";
import youtube_icon from "../../../assets/image/youtube_icon.png";

class DealInfoBlock extends Component {
    render() {
        const {
            advertiser,
            advertiser_link,
            channel_name,
            blogger_channel,
            owner,
            bloggerInterface,
            channel_image
        } = this.props;
        const fromLeftToRight =
            (owner === "blogger" && bloggerInterface) || (owner === "advertiser" && !bloggerInterface);
        return (
            <div className="info_blogger">
                <div className="channel" style={{ order: bloggerInterface ? 2 : 0 }}>
                    <span>{advertiser}</span>
                    {advertiser_link && (
                        <a href={advertiser_link} target="_blank" rel="noopener noreferrer">
                            {advertiser_link}
                        </a>
                    )}
                </div>
                <div className={clsx("box_arrow", fromLeftToRight && "reverse")} style={{ order: 1 }}>
                    <img src={direction} alt="direction" />
                </div>
                <div className="blogger" style={{ order: bloggerInterface ? 0 : 2 }}>
                    {channel_image && (
                        <div className="photo">
                            <img src={channel_image} alt="" />
                        </div>
                    )}
                    <div className="name">
                        <div>{channel_name}</div>
                        <a href={blogger_channel} target="_blank" rel="noopener noreferrer">
                            <img src={youtube_icon} alt="youtube_icon" />
                            Перейти на канал
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DealInfoBlock);
