import * as types from "../actions/constants";

const INITIAL_STATE = {
    list_campaigns: [],
    error_campaigns: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_CAMPAIGNS_FAIL:
            return { ...state, error_campaigns: action.error.response.data };
        case types.GET_CAMPAIGNS_SUCCESS:
            return { ...state, list_campaigns: action.payload.data };
        case types.POST_CAMPAIGNS_SUCCESS:
            return { ...state, list_campaigns: [action.payload.data, ...state.list_campaigns] };
        case types.POST_CAMPAIGNS_FAIL:
            return { ...state, error_campaigns: action.error.response.data };
        case types.RESET_CAMPAIGNS_ERROR:
            return { ...state, error_campaigns: {} };
        default:
            return state;
    }
}
