import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import AuthReducer from "./reduceAuth";
import AppReducer from "./reduceApp";
import ProfileReducer from "./reduceProfile";
import CampaignsReducer from "./reduceCampaigns";
import BloggersReducer from "./reduceBlogger";
import BloggersReducerHeader from "./reduceBloggerHeader";
import CampaignInnerPageReducer from "./reducerCampaingInnerPage";
import reduceReview from "./reduceReview";
import reduceTasksGetter from "./reduceTasksGetter";
import reduceTasksSearch from "./reduceTasksSearch";
import reduceGoogleAuth from "./reduceGoogleAuth";
import DealsReducer from "./reduceDeals";
import FinanceReducer from "./reduceFinance";

const rootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        form: formReducer,
        auth: AuthReducer,
        app: AppReducer,
        profile: ProfileReducer,
        campaigns: CampaignsReducer,
        campaignPage: CampaignInnerPageReducer,
        bloggers: BloggersReducer,
        reviews: reduceReview,
        task_getter: reduceTasksGetter,
        tasks_search: reduceTasksSearch,
        header: BloggersReducerHeader,
        deals: DealsReducer,
        finance: FinanceReducer,
        googleAuth_err: reduceGoogleAuth
    });

export default rootReducer;
