import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GoogleLogin from "react-google-login";
import { withRouter } from "react-router";

import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import SnackBar from "../HelperComponents/SnackBar/SnackBar";
import Loader from "../HelperComponents/ContentLoader/ContentLoader";

import { googleAuth } from "../../actions/authActions";
import { getProfile } from "../../actions/profileActions";

import plus_youtube from "../../assets/image/plus_youtube.png";

import "./AddChannel.scss";

class AddChannel extends Component {
    state = {
        open: false,
        openNoYoutubeAccount: false,
        loading: true
    };

    componentDidMount() {
        console.log(this.props.channel.length);
        if (this.props.channel.length > 0) {
            this.props.history.push("/main/search-tasks/");
        } else {
            this.setState({ loading: false });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    addChannel = response => {
        const { googleAuth, history, getProfile } = this.props;
        let obj = { token: response.code };
        this.setState({ loading: true });

        googleAuth(obj).then(res => {
            if (res.payload) {
                getProfile().then(() => {
                    history.push("/main/search-tasks/");
                });
            } else {
                this.setState({ loading: false });
                this.toggleNoYoutubeAccountSnack();
            }
        });
    };

    toggleSnack = () => {
        this.setState({ open: true });
        this.timeout = setTimeout(() => {
            this.setState({ open: false });
        }, 4000);
    };

    toggleNoYoutubeAccountSnack = () => {
        this.setState({ openNoYoutubeAccount: true });
        this.timeout = setTimeout(() => {
            this.setState({ openNoYoutubeAccount: false });
        }, 4000);
    };

    render() {
        const { open, loading, openNoYoutubeAccount } = this.state;
        const responseGoogle = response => {
            if (response.error) {
                this.toggleSnack();
            } else {
                this.addChannel(response);
            }
        };
        if (loading)
            return (
                <div className="loader">
                    <Loader height={65} width={5} margin="15px" color={"#5ce6cd"} />
                </div>
            );
        return (
            <TransitionedBlock>
                <div className="content_block_page add_channel_wrapper">
                    <h1>Добро пожаловать!</h1>
                    <div className="add_channel">
                        <img src={plus_youtube} alt="plus_youtube" />
                        <div className="title">YouTube</div>
                        <div className="descriptions">
                            Для начала работы с биржей необходимо <br /> добавить свой YouTube-канал на биржу
                        </div>
                        <span></span>
                        <GoogleLogin
                            clientId="783014202261-tros84e62de1i1i4qic94jc1g5o06qdj.apps.googleusercontent.com"
                            scope="https://www.googleapis.com/auth/yt-analytics.readonly https://www.googleapis.com/auth/youtubepartner-channel-audit https://www.googleapis.com/auth/youtube.readonly"
                            render={renderProps => (
                                <button onClick={renderProps.onClick} className="border_btn_green">
                                    Добавить канал
                                </button>
                            )}
                            buttonText="Войти с помощью Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={"single_host_origin"}
                            accessType="offline"
                            prompt="consent"
                            responseType="code"
                            redirectUri="https://imast.4-com.pro/main"
                        />
                    </div>
                    <SnackBar open={open} noClosingIcon classes="error">
                        Упс! Что-то пошло не так.
                    </SnackBar>
                    <SnackBar open={openNoYoutubeAccount} noClosingIcon classes="error">
                        Вы не можете использовать данный Youtube-аккаунт
                    </SnackBar>
                </div>
            </TransitionedBlock>
        );
    }
}

function mapStateToProps(state) {
    return {
        channel: state.profile.channel
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            googleAuth,
            getProfile
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddChannel));
