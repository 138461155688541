import React from "react";

import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";

import mail_large from "../../../assets/image/mail_large.svg";

const NotificationCreated = () => (
    <TransitionedBlock>
        <div className="authentication-form">
            <div className="title_form">Активация аккаунта</div>
            <div className="authentication-content">
                <div className="form_auth fourth_step">
                    <div className="text_info">
                        Спасибо за регистрацию. Пожалуйста, перейдите по ссылке в письме для подтверждения аккаунта.
                    </div>
                    <div className="box_icon_aut">
                        <img src={mail_large} alt="mail_large" />
                    </div>
                </div>
            </div>
            <div className="info_aut"></div>
        </div>
    </TransitionedBlock>
);

export default NotificationCreated;
