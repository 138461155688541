import React from "react";
import App from "../containers/App";
import { Route, Switch, Redirect } from "react-router-dom";
import Container from "../containers/Container/Container";
import Authentication from "../containers/Authentication/Authentication";
import PublicPagesContainer from "../containers/PublicPagesContainer/PublicPagesContainer";

export default (
    <App>
        <Switch>
            <Route
                path="/"
                exact
                render={() => (!!localStorage.token ? <Redirect to="/main" /> : <Redirect to="/auth" />)}
            />
            <Route path="/auth" component={Authentication} />
            <Route path="/main" component={Container} />
            <Route path="/public" component={PublicPagesContainer} />
            <Route render={() => <p>Not found</p>} />
        </Switch>
    </App>
);
