import * as types from "./constants.jsx";

export const getterReview = (id, page, commentsPerPage) => {
    return {
        type: types.GET_REVIEW,
        payload: {
            client: "default",
            request: {
                url: `/blogger/${id}/reviews/?page=${page + 1}&page_size=${commentsPerPage}`,
                method: "get"
            }
        }
    };
};
