import * as types from "../actions/constants";

const INITIAL_STATE = {
    profile_data: {
        first_name: "",
        last_name: "",
        id: null,
        email: "",
        balance: undefined,
        profilePaymentInfo: {},
        requisite: false
    },
    error_profile: {},
    error_password: {},
    channels: []
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_PROFILE_SUCCESS:
            return {
                ...state,
                profile_data: {
                    first_name: action.payload.data.first_name,
                    last_name: action.payload.data.last_name,
                    email: action.payload.data.email,
                    balance: action.payload.data.balance,
                    profilePaymentInfo: action.payload.data.payment_information,
                    requisite: action.payload.data.requisite,
                    id: action.payload.data.id,
                    acquainted_offer: action.payload.data.acquainted_offer
                },
                channel: action.payload.data.channels,
                id: action.payload.data.id
            };
        case types.GET_PROFILE_FAIL:
            return { ...state, error_profile: action.error.data };
        case types.PATCH_PROFILE_SUCCESS:
            return {
                ...state,
                profile_data: {
                    first_name: action.payload.data.first_name,
                    last_name: action.payload.data.last_name,
                    email: action.payload.data.email,
                    balance: action.payload.data.balance,
                    profilePaymentInfo: { ...state.profile_data.profilePaymentInfo }
                },
                channel: action.payload.data.channels,
                id: action.payload.data.id,
                error_profile: {}
            };
        case types.PATCH_PAYMENT_INFORMATION_SUCCESS:
            return {
                ...state,
                profile_data: {
                    ...state.profile_data,
                    profilePaymentInfo: action.payload.data.payment_information
                        ? action.payload.data.payment_information
                        : action.payload.data
                },
                error_profile: {}
            };
        case types.PATCH_PROFILE_FAIL:
            return { ...state, error_profile: action.error.response.data };
        case types.PATCH_PAYMENT_INFORMATION_FAIL:
            return { ...state, error_profile: { payment_information: action.error.response.data } };
        case types.PATCH_PROFILE_CHANGE_PASSWORD_FAIL:
            return { ...state, error_password: action.error.response.data };
        case types.RESET_PASSWORD_ERROR:
            return { ...state, error_password: {} };

        default:
            return state;
    }
}
