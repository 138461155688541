import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";

import "./Dashboard.scss";
import user_icon from "../../assets/image/user_icon.png";
import manager_icon from "../../assets/image/manager_icon.png";
import ok_icon from "../../assets/image/ok_icon.png";

class Dashboard extends Component {
    state = {
        loading: true
    };

    render() {
        return (
            <TransitionedBlock>
                <div className="content_block dashboard_wrapper">
                    <h1>Как вы хотите работать?</h1>
                    <div className="dashboard">
                        <div className="lk_block_left">
                            <div className="icon_user">
                                <img src={user_icon} alt="user_icon" />
                            </div>
                            <div className="title">Самостоятельно</div>
                            <div className="descriptions">Вы сами полностью контролируете ход рекламной кампании</div>
                            <div className="step_block">
                                <div className="step_title">Шаги к рекламе:</div>
                                <div className="info_step">
                                    <span></span>
                                    Создать кампанию
                                </div>
                                <div className="info_step">
                                    <span></span>
                                    Подобрать каналы
                                </div>
                                <div className="info_step">
                                    <span></span>
                                    Подать заявку
                                </div>
                                <div className="info_step">
                                    <span></span>
                                    Оплатить сделку
                                </div>
                                <div className="info_step_ok">
                                    <span>
                                        <img src={ok_icon} alt="ok_icon" />
                                    </span>
                                    Готово, скоро реклама будет опубликована
                                </div>
                                <button className="green_btn_big">Создать кампанию</button>
                            </div>
                        </div>
                        <div className="lk_block_right">
                            <div className="icon_user">
                                <img src={manager_icon} alt="manager_icon" />
                            </div>
                            <div className="title">С менеджером</div>
                            <div className="descriptions">
                                Наши менеджеры сами ведут рекламную кампанию, Вы лишь следите за ходом и, если
                                необходимо, вносите корректировки и пожелания
                            </div>
                            <div className="step_block">
                                <div className="step_title">Шаги к рекламе:</div>
                                <div className="info_step">
                                    <span></span>
                                    Заполнить бриф
                                </div>
                                <div className="info_step info_step_second">
                                    <span></span>
                                    <div>
                                        Пополнить баланс кампании <br />
                                        <b>(не менее 20 тыс. руб.)</b>
                                    </div>
                                </div>
                                <div className="info_step info_step_three">
                                    <span></span>
                                    Согласовать подобранные каналы
                                </div>
                                <div className="info_step_ok">
                                    <span>
                                        <img src={ok_icon} alt="ok_icon" />
                                    </span>
                                    Готово, скоро реклама будет опубликована
                                </div>
                                <button className="green_btn_big">Заполнить бриф</button>
                            </div>
                        </div>
                    </div>
                </div>
            </TransitionedBlock>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
