import React from "react";
import "./RenderField.scss";

const renderField_mail = ({ input, label, placeholder, type, meta: { touched, error, warning }, handleInput }) => (
    <label
        className={
            touched && error
                ? input.value !== ""
                    ? "block-input-mail error_border value"
                    : "error_border  error_border-custom block-input-mail"
                : input.value !== ""
                ? "block-input-mail value"
                : "block-input-mail"
        }
    >
        <input {...input} placeholder={placeholder} type={type} autoComplete="off" onClick={handleInput} />
        {touched && error ? <span className="error">{error}</span> : ""}
    </label>
);

export default renderField_mail;
