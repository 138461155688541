import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, reset } from "redux-form";
import Deals from "../../components/CampaingPage/Deals/Deals";
import Finance from "../../components/CampaingPage/Finance/Finance";
import Tasks from "../../components/CampaingPage/Tasks/Tasks";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import FormControl from "@material-ui/core/FormControl";
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent";

import {
    getCertainCampaign,
    patchCampaignStatus,
    postTask,
    deleteCertainCampaign,
    getTasks,
    getDeals,
    getTransactions
} from "../../actions/campaignInnerPageActions";
import { getCampaigns } from "../../actions/campaignsActions";
import { regExpNumber, regExpUrl } from "../../helpers/validation";

import play from "../../assets/image/play.svg";
import pause from "../../assets/image/pause.svg";
import deleteIcon from "../../assets/image/delete.svg";
import MaleIcon from "../../assets/image/male.svg";
import FemaleIcon from "../../assets/image/female_small.svg";
import delete_icon from "../../assets/image/delete_large.png";

import "./CampaingPage.scss";

class CampaingPage extends Component {
    state = {
        tab: "0",
        open: false,
        openTask: false,
        loading: true,
        textOverflow: true,
        active: undefined,
        task_type: 0,
        openDeleteDialog: false,
        openErrorDialog: false,
        advertise_options: [
            {
                label: (
                    <div className="status_select">
                        <div>в начале</div>
                    </div>
                ),
                value: "preroll"
            },
            {
                label: (
                    <div className="status_select">
                        <div>в середине</div>
                    </div>
                ),
                value: "middle"
            },
            {
                label: (
                    <div className="status_select">
                        <div>в конце</div>
                    </div>
                ),
                value: "postroll"
            }
        ],
        advertise_type: {
            label: (
                <div className="status_select">
                    <div>в начале</div>
                </div>
            ),
            value: "preroll"
        }
    };

    componentDidMount() {
        this.doRequest(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        if (!!this.props.match.params.id) {
            if (prevProps.match.params.id !== this.props.match.params.id) {
                this.doRequest(this.props.match.params.id);
            }
        }
    }

    doRequest = id => {
        const { getCertainCampaign } = this.props;
        getCertainCampaign(id).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({
                    loading: false,
                    active: res.payload.data.active
                });
            }
        });
    };

    doTasksRequest = () => {
        const {
            getTasks,
            match: { params }
        } = this.props;
        getTasks(params.id);
    };

    doDealsRequest = () => {
        const {
            getDeals,
            match: { params }
        } = this.props;
        getDeals(params.id);
    };

    doTransactionsRequest = () => {
        const {
            getTransactions,
            match: { params }
        } = this.props;
        getTransactions(params.id);
    };

    changeTab = tab => {
        this.setState({ tab });
    };

    handleStatus = () => {
        const {
            patchCampaignStatus,
            match: { params }
        } = this.props;
        const { active } = this.state;
        const data = {
            active: !active
        };
        patchCampaignStatus(params.id, data).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.doRequest(params.id);
            } else {
                this.setState({ openErrorDialog: true });
            }
        });
    };

    toggleDialog = () => {
        this.setState(({ open }) => ({
            open: !open
        }));
    };

    changeTaskType = task_type => {
        this.setState({ task_type });
    };

    toggleText = () => {
        this.setState(({ textOverflow }) => ({
            textOverflow: !textOverflow
        }));
    };

    toggleDialogTask = () => {
        this.props.campaignPage.error = {};
        this.setState(({ openTask }) => ({
            openTask: !openTask
        }));
        this.setState(({ open }) => ({
            open: !open
        }));
    };

    closeDialogTask = () => {
        this.props.campaignPage.error = {};
        this.setState(({ openTask }) => ({
            openTask: !openTask
        }));
        reset("CampaingPage");
        this.changeTaskType(0);
    };

    submitForm = values => {
        const {
            postTask,
            match: { params },
            reset
        } = this.props;
        const { advertise_type, task_type } = this.state;
        let data = values;
        if (task_type === 0) {
            data.task_type = advertise_type.value;
        } else {
            data.task_type = task_type;
        }
        data.budget = Number(data.budget);
        data.campaign = params.id;
        if (!!data.ad_link && !data.ad_link.match(regExpUrl)) {
            data.ad_link = "http://" + data.ad_link;
        }
        postTask(data).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 201) {
                reset();
                this.closeDialogTask();
                this.doTasksRequest();
                reset("CampaingPage");
                this.changeTaskType(0);
            }
        });
    };

    changeAdvertiseOptions = event => {
        this.setState({ advertise_type: event });
    };

    toggleDeleteDialog = () => {
        this.setState(({ openDeleteDialog }) => ({
            openDeleteDialog: !openDeleteDialog
        }));
    };

    deleteCampaign = () => {
        const {
            history,
            deleteCertainCampaign,
            getCampaigns,
            match: { params }
        } = this.props;
        deleteCertainCampaign(params.id).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 204) {
                getCampaigns();
                this.setState(({ openDeleteDialog }) => ({
                    openDeleteDialog: !openDeleteDialog
                }));
                history.push("/main");
            }
        });
    };

    render() {
        const {
            handleSubmit,
            submitting,
            pristine,
            valid,
            campaignPage: {
                campaign: { campaign_name, age_range, gender, description, campaign_link },
                error
            },
            history,
            reset
        } = this.props;
        const {
            loading,
            tab,
            open,
            openTask,
            textOverflow,
            active,
            task_type,
            advertise_options,
            openDeleteDialog,
            openErrorDialog,
            advertise_type
        } = this.state;

        if (loading) return null;
        console.log(this.props.campaignPage);
        return (
            <TransitionedBlock>
                <div className="content_block_page campaing_wrapper">
                    <div className="campaing_page">
                        <div className="info_users">
                            <div className="name">{campaign_name}</div>
                            <div className="status">
                                <div className="info_status">
                                    <span className={active ? "active" : "paused"} />
                                    {active ? "Активная" : "Приостановлена"}
                                </div>
                                <a href={campaign_link} target="_blank" rel="noopener noreferrer">
                                    {campaign_link}
                                </a>
                            </div>
                        </div>
                        <div className="menu_compaing">
                            <button className="activated" onClick={this.handleStatus}>
                                {active ? <img src={pause} alt="pause" /> : <img src={play} alt="play" />}
                            </button>
                            <button className="delete_btn" onClick={this.toggleDeleteDialog}>
                                <img src={deleteIcon} alt="deleteIcon" />
                            </button>
                            <button className="border_btn_green" onClick={this.toggleDialog}>
                                + Создать задание
                            </button>
                        </div>
                    </div>
                    <div className="descriptions_projects">
                        <div className="title">Демография</div>
                        <div className="age_range">
                            <img src={gender === "male" ? MaleIcon : FemaleIcon} alt="gender" />
                            {age_range} лет
                        </div>
                        <div className="title">Цель кампании</div>
                        <div className={textOverflow && description.length > 1000 ? "text" : "text full_height"}>
                            {description}
                        </div>
                        {description.length > 1000 && (
                            <button onClick={this.toggleText}>
                                {textOverflow ? (
                                    <Fragment>
                                        <span className="down" />
                                        показать полностью
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <span className="up" />
                                        скрыть
                                    </Fragment>
                                )}
                            </button>
                        )}
                    </div>
                    <div className="tab_blogger">
                        <button className={tab === "0" ? "active" : ""} onClick={() => this.changeTab("0")}>
                            Задания
                        </button>
                        <button className={tab === "1" ? "active" : ""} onClick={() => this.changeTab("1")}>
                            Сделки
                        </button>
                        <button className={tab === "2" ? "active" : ""} onClick={() => this.changeTab("2")}>
                            Финансы
                        </button>
                    </div>
                    {tab === "0" && <Tasks campaign={campaign_name} doTasksRequest={this.doTasksRequest} />}
                    {tab === "1" && (
                        <Deals history={history} campaign={campaign_name} doDealsRequest={this.doDealsRequest} />
                    )}
                    {tab === "2" && (
                        <Finance campaign={campaign_name} doTransactionsRequest={this.doTransactionsRequest} />
                    )}
                    <DialogComponent
                        open={open}
                        onClose={() => {
                            this.toggleDialog();
                            reset("CampaingPage");
                            this.changeTaskType(0);
                        }}
                    >
                        <div className="add_task">
                            <div className="title">Создать задание</div>
                            <div className="descriptions">Вид рекламы</div>
                            <div className="advertisement">
                                <div className={task_type === 0 ? "active" : ""} onClick={() => this.changeTaskType(0)}>
                                    <div className="info_title">Реклама в видео</div>
                                    <div className="info_descriptions">
                                        Реклама будет размещена перед основным роликом (10-25 сек. видеоряд + озвучка
                                        блогером).
                                    </div>
                                </div>
                                <div
                                    className={task_type === "video_integration" ? "active" : ""}
                                    onClick={() => this.changeTaskType("video_integration")}
                                >
                                    <div className="info_title">Интеграция в видео</div>
                                    <div className="info_descriptions">
                                        Блогер интегрирует рекламу в видео. Будет ненавязчивой и незаметна для зрителей.
                                    </div>
                                </div>
                                <div
                                    className={task_type === "society_link" ? "active" : ""}
                                    onClick={() => this.changeTaskType("society_link")}
                                >
                                    <div className="info_title">Ссылка в сообществе канала</div>
                                    <div className="info_descriptions">
                                        Ваша ссылка будет отображаться во вкладке канала "Сообщество».
                                    </div>
                                </div>
                                <div
                                    className={task_type === "customized_video" ? "active" : ""}
                                    onClick={() => this.changeTaskType("customized_video")}
                                >
                                    <div className="info_title">Заказной ролик</div>
                                    <div className="info_descriptions">
                                        Блогером будет снят ролик полностью посвящёный рекламируемому продукту.
                                    </div>
                                </div>
                                <div
                                    className={task_type === "stories_ad" ? "active" : ""}
                                    onClick={() => this.changeTaskType("stories_ad")}
                                >
                                    <div className="info_title">
                                        Реклама в <br /> YouTube-истории
                                    </div>
                                    <div className="info_descriptions">
                                        Реклама будет размещена в YouTube-истории канала.
                                    </div>
                                </div>
                            </div>
                            <div className="btn_wrapper">
                                <button
                                    className="cancel_btn"
                                    onClick={() => {
                                        this.toggleDialog();
                                        reset("CampaingPage");
                                        this.changeTaskType(0);
                                    }}
                                >
                                    Отмена
                                </button>
                                <button className="green_btn" onClick={this.toggleDialogTask}>
                                    Продолжить
                                </button>
                            </div>
                        </div>
                    </DialogComponent>
                    <DialogComponent
                        open={openTask}
                        onClose={() => {
                            this.closeDialogTask();
                            reset("CampaingPage");
                            this.changeTaskType(0);
                        }}
                    >
                        <div className="create_task">
                            <div className="title">Создать задание</div>
                            <form className="form_task" onSubmit={handleSubmit(this.submitForm)}>
                                <div className="label_box">
                                    <span>Рекламная ссылка</span>
                                    <Field
                                        name="ad_link"
                                        component={renderField}
                                        placeholder="http://www.example.com"
                                        autoComplete="off"
                                        type="text"
                                        className="link-textarea"
                                    />
                                    {error && error.ad_link && <span className="error">{error.ad_link}</span>}
                                </div>
                                {task_type === 0 && (
                                    <div className="label_box">
                                        <span>Реклама в видео</span>
                                        <FormControl className="select_wrapper">
                                            <SelectComponent
                                                value={advertise_type}
                                                options={advertise_options}
                                                change={this.changeAdvertiseOptions}
                                                isClearable="false"
                                                isSearchable={false}
                                            />
                                        </FormControl>
                                    </div>
                                )}
                                <div className="label_box">
                                    <span>Описание задания</span>
                                    <Field
                                        name="task_text"
                                        component={renderTextarea}
                                        placeholder="Опишите пожелания и требования к рекламе"
                                        autoComplete="off"
                                    />
                                    {valid && error && error.task_text && (
                                        <span className="error">{error.task_text}</span>
                                    )}
                                </div>
                                <div className="value_info">
                                    <span>Бюджет</span>
                                    <Field
                                        name="budget"
                                        component={renderField}
                                        placeholder="0"
                                        autoComplete="off"
                                        type="text"
                                    />
                                    {valid && error && error.budget && <span className="error">{error.budget}</span>}
                                    <p>руб.</p>
                                </div>
                                {/*<div className="col col-checkbox first_block">*/}
                                {/*    <Field*/}
                                {/*        name="preview"*/}
                                {/*        id="check1"*/}
                                {/*        component="input"*/}
                                {/*        type="checkbox"*/}
                                {/*        className="inp-checkbox"*/}
                                {/*    />*/}
                                {/*    <label htmlFor="check1">Обязательный предпросмотр перед публикацией</label>*/}
                                {/*</div>*/}
                                <div className="col col-checkbox ">
                                    <Field
                                        name="public_view"
                                        id="check2"
                                        component="input"
                                        type="checkbox"
                                        className="inp-checkbox"
                                    />
                                    <label htmlFor="check2">
                                        Сделать задание публичным (задание будут видеть все блогеры, которые смогут
                                        отправлять заявки на выполнение задания)
                                    </label>
                                </div>
                                <div className="btn_wrapper">
                                    <button
                                        className="cancel_btn"
                                        onClick={e => {
                                            e.preventDefault();
                                            this.toggleDialogTask();
                                        }}
                                    >
                                        Назад
                                    </button>
                                    <button className="green_btn" disabled={submitting || pristine || !valid}>
                                        Создать
                                    </button>
                                </div>
                            </form>
                        </div>
                    </DialogComponent>
                    <DialogComponent open={openDeleteDialog} onClose={this.toggleDeleteDialog}>
                        <div className="delete_deal">
                            <div className="box_icon">
                                <img src={delete_icon} alt="cancel_icon" />
                            </div>
                            <div className="title">Вы уверены, что хотите удалить кампанию?</div>
                            <hr />
                            <div className="btn_wrapper">
                                <button className="cancel_btn" onClick={this.toggleDeleteDialog}>
                                    Нет
                                </button>
                                <button className="red_btn" onClick={this.deleteCampaign}>
                                    Да
                                </button>
                            </div>
                        </div>
                    </DialogComponent>
                    <DialogComponent open={openErrorDialog} onClose={() => this.setState({ openErrorDialog: false })}>
                        <div className="default_dialog">
                            <div className="title">Запустить кампанию</div>
                            <div className="description">
                                Недостаточно средств для запуска кампании. Пожалуйста, пополните Ваш баланс.
                            </div>
                        </div>
                    </DialogComponent>
                </div>
            </TransitionedBlock>
        );
    }
}

export const validate = values => {
    const errors = {};
    if (!values.budget) {
        errors.budget = "Обязательное поле";
    } else if (values.budget && !values.budget.toString().match(regExpNumber)) {
        errors.budget = "Некорректное значение";
    }
    if (!values.task_text) {
        errors.task_text = "Обязательное поле";
    }
    return errors;
};

export const renderField = ({ input, id, type, placeholder, className, max, meta: { touched, error } }) => (
    <Fragment>
        <input {...input} id={id} placeholder={placeholder} type={type} max={max} className={className} />
        {touched && error && <span className="error">{error}</span>}
    </Fragment>
);

export const renderTextarea = ({ input, placeholder, className, meta: { touched, error } }) => (
    <Fragment>
        <textarea {...input} placeholder={placeholder} className={className} />
        {touched && error && <span className="error">{error}</span>}
    </Fragment>
);

CampaingPage = reduxForm({
    form: "CampaingPage",
    validate
})(CampaingPage);

function mapStateToProps(state) {
    return {
        campaignPage: state.campaignPage,
        initialValues: {
            preview: false,
            public_view: false
        }
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getCertainCampaign,
            patchCampaignStatus,
            postTask,
            deleteCertainCampaign,
            getTasks,
            getDeals,
            getTransactions,
            getCampaigns
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaingPage);
