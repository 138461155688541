import * as types from "../actions/constants";

const INITIAL_STATE = {
    loading: false,
    buttonLoading: false,
    errorSnack: false,
    errorSnackText: "",
    successSnack: false,
    successSnackText: "",
    openPaymentDetails: false,
    notifications: [],
    offerPage: undefined,
    privacyPolicyPage: undefined,
    faqPage: undefined
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.LOADING:
            return { ...state, loading: action.payload };
        case types.BUTTON_LOADING:
            return { ...state, buttonLoading: action.payload };
        case types.ERROR_SNACK_OPEN:
            return { ...state, errorSnack: true, errorSnackText: action.payload };
        case types.ERROR_SNACK_CLOSE:
            return { ...state, errorSnack: false };
        case types.SUCCESS_SNACK_OPEN:
            return { ...state, successSnack: true, successSnackText: action.payload };
        case types.SUCCESS_SNACK_CLOSE:
            return { ...state, successSnack: false };
        case types.OPEN_PAYMENT_DETAILS:
            return { ...state, openPaymentDetails: action.payload };
        case types.GET_ALL_NOTIFICATIONS_SUCCESS:
            return { ...state, notifications: action.payload.data };
        case types.DELETE_SPECIFIC_NOTIFICATION_SUCCESS:
            return { ...state, notifications: [...state.notifications] };
        case types.FILTER_NOTIFICATIONS:
            return {
                ...state,
                notifications: [...state.notifications.filter((notification) => notification.id !== action.payload)]
            };
        case types.DELETE_ALL_NOTIFICATIONS:
            return { ...state, notifications: [] };
        case types.GET_OFFER_PAGE_SUCCESS:
            return { ...state, offerPage: action.payload.data.text };
        case types.GET_PRIVACY_POLICY_PAGE_SUCCESS:
            return { ...state, privacyPolicyPage: action.payload.data.text };
        case types.GET_FAQ_PAGE_SUCCESS:
            return { ...state, faqPage: action.payload.data.text };
        case types.SNACK_TEXTS_CLEARING:
            return {
                ...state,
                errorSnackText: "",
                successSnackText: ""
            };
        default:
            return state;
    }
}
