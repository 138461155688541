import * as types from "../actions/constants";

const INITIAL_STATE = {
    dealsList: { loading: true },
    deal: { loading: true },
    dealError: undefined
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_DEALS_SUCCESS:
            return { ...state, dealsList: action.payload.data };
        case types.GET_CURRENT_DEAL_SUCCESS:
            return { ...state, deal: action.payload.data };
        case types.PATCH_CURRENT_DEAL_SUCCESS:
            return { ...state, deal: action.payload.data };
        case types.POST_MESSAGE_SUCCESS:
            return {
                ...state,
                deal: {
                    ...state.deal,
                    chat: [...state.deal.chat, { user: "Вы", msg: action.payload.data.msg, date: new Date() }]
                }
            };
        case types.POST_REVIEW_SUCCESS:
            return { ...state, deal: { ...state.deal, review: { ...action.payload.data, date: new Date() } } };
        case types.CLEAR_CURRENT_DEAL:
            return { ...state, deal: { loading: true } };

        case types.PATCH_DEAL_WITH_VIDEO_FAIL:
            return { ...state, dealError: action.error.response.data };
        case types.PATCH_DEAL_WITH_VIDEO_SUCCESS:
            return { ...state, dealError: undefined };
        case types.RESET_DEAL_ERRORS:
            return { ...state, dealError: undefined };
        default:
            return state;
    }
}
