import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { numberWithSpaces } from "../../helpers/functions";
import { notificationsDeals } from "../../helpers/constants";
import {
    getAllNotifications,
    deleteAllNotifications,
    deleteSpecificNotification,
    filterNotifications
} from "../../actions/appActions";
import { patchProfile, postAdvertiserType, getAdvertiserType } from "../../actions/profileActions";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import PaymentDetails from "../PaymentDetails/PaymentDetails";
import OfferForm from "../OfferForm/OfferForm";

import LogoHeader from "../../assets/image/logo.svg";
import notifIcon from "../../assets/image/notif_empty.png";
import notificationIcon from "../../assets/image/notification.png";
import arrow from "../../assets/image/arrow_down.png";
import emailIcon from "../../assets/image/email.png";
import reserveIcon from "../../assets/image/reserve.svg";
import backFromReserveIcon from "../../assets/image/back_from_reserve.svg";
import topUpIcon from "../../assets/image/topup.svg";
import synchronization from "../../assets/image/synchronization.svg";
import coin from "../../assets/image/coin.svg";
import gear from "../../assets/image/gear.svg";
import logout from "../../assets/image/log-out.svg";
import IP from "../../assets/image/IP.png";
import UL from "../../assets/image/UL.png";

import "./Head.scss";

class Head extends Component {
    state = {
        notificationIsOpen: false,
        menuIsOpen: false,
        openPaymentDialog: false,
        openAdvertiserTypeDialog: false,
        advertiserType: null,
        openOfferDialog: true
    };

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({ menuIsOpen: false });
        }
    }

    openNotifications = () => {
        this.setState(({ notificationIsOpen }) => ({
            notificationIsOpen: !notificationIsOpen
        }));
    };

    openMenu = () => {
        this.setState(({ menuIsOpen }) => ({
            menuIsOpen: !menuIsOpen
        }));
    };

    handleOpenNotifications = () => {
        setTimeout(() => {
            this.setState({
                notificationIsOpen: false
            });
        }, 100);
    };

    handleOpenMenu = () => {
        setTimeout(() => {
            this.setState({
                menuIsOpen: false
            });
        }, 100);
    };

    handleOut = () => {
        const { history } = this.props;
        localStorage.clear();
        history.push("/auth");
    };

    renderNotification = (amount, deal, status, type) => {
        switch (type) {
            case "deal_status_changed":
                const dealStatus = notificationsDeals.find(el => el.value === status);
                return (
                    <Fragment>
                        <span className="" style={{ backgroundColor: dealStatus["color"] }} />
                        Сделка <p>#{deal}</p>
                        {dealStatus["label"]}
                    </Fragment>
                );
            case "deal_new_message":
                return (
                    <Fragment>
                        <img src={emailIcon} alt="email" className="email_icon" />
                        Новое сообщение в чате сделки <p>#{deal}</p>
                    </Fragment>
                );
            case "balance_reserved":
                return (
                    <Fragment>
                        <img src={reserveIcon} alt="email" className="balance_icon" />
                        Зарезервировано {amount} рублей
                    </Fragment>
                );
            case "balance_reserved_refund":
                return (
                    <Fragment>
                        <img src={backFromReserveIcon} alt="email" className="balance_icon" />
                        Возвращено из резерва {amount} рублей
                    </Fragment>
                );
            case "balance_refill":
                return (
                    <Fragment>
                        <img src={topUpIcon} alt="email" className="balance_icon" />
                        Баланс пополнен на {amount} рублей
                    </Fragment>
                );
            default:
                return <Fragment>Уведомление с типом {type}</Fragment>;
        }
    };

    handleNotificationClick = async (id, deal, type) => {
        const { history, filterNotifications, patchProfile, bloggerInterface } = this.props;
        this.clearNotifications(id);
        filterNotifications(id);
        if (type.includes("deal")) {
            history.push(`/main/deals-inner/${deal}`);
        } else if (type.includes("balance")) {
            if (bloggerInterface === "blogger") {
                await patchProfile({ current_role: "advertiser" });
                localStorage.setItem("bloggerInterface", "advertiser");
            }
            history.push(`/main/finance-page`);
        }
    };

    clearNotifications = (id = null) => {
        if (id) {
            const { deleteSpecificNotification } = this.props;
            deleteSpecificNotification(id);
        } else {
            const { deleteAllNotifications } = this.props;
            deleteAllNotifications();
        }
    };

    handlePaymentPopup = () => this.setState(({ openPaymentDialog }) => ({ openPaymentDialog: !openPaymentDialog }));

    handleOfferPopup = () => this.setState(({ openOfferDialog }) => ({ openOfferDialog: !openOfferDialog }));

    fetchAdvertiserType = async () => {
        const { getAdvertiserType, handleInterfaceChange } = this.props;
        const res = await getAdvertiserType();
        if (res.payload && res.payload.data.show_popup) {
            this.setState({ openAdvertiserTypeDialog: true });
        } else {
            handleInterfaceChange("advertiser");
        }
    };

    handleAdvertiserType = async () => {
        const { postAdvertiserType, handleInterfaceChange } = this.props;
        const { advertiserType } = this.state;
        await postAdvertiserType({ type: advertiserType });
        this.setState({ openAdvertiserTypeDialog: false });
        handleInterfaceChange("advertiser");
    };

    submitOffer = () => {
        console.log(111);
    };

    render() {
        const { handleInterfaceChange, bloggerInterface, notifications, isAgent } = this.props;
        const {
            notificationIsOpen,
            menuIsOpen,
            openPaymentDialog,
            openAdvertiserTypeDialog,
            advertiserType,
            openOfferDialog
        } = this.state;
        const {
            first_name,
            last_name,
            email,
            balance,
            requisite,
            profilePaymentInfo,
            acquainted_offer
        } = this.props.profile_data;
        const {
            payment_account,
            bank_code,
            inn,
            kpp,
            organisation_name,
            bank_name,
            correspondent_account
        } = profilePaymentInfo;
        let username = email;
        first_name && (username = first_name);
        last_name && (username = `${first_name} ${last_name}`);
        let showRequisitesForm =
            !payment_account ||
            !bank_code ||
            !inn ||
            !kpp ||
            !organisation_name ||
            !bank_name ||
            !correspondent_account;
        if (profilePaymentInfo.type === "IP") {
            showRequisitesForm =
                !payment_account ||
                !bank_code ||
                !bank_name ||
                !inn ||
                !correspondent_account ||
                !profilePaymentInfo.first_name ||
                !profilePaymentInfo.middle_name ||
                !profilePaymentInfo.last_name;
        }
        return (
            <header className="app_head">
                <div className="header_wrapper">
                    <div className="block_logo">
                        <Link to="/main">
                            <img src={LogoHeader} alt="logo header" />
                        </Link>
                    </div>
                    <div className="head_options">
                        {(!!balance || balance === 0) && (
                            <Fragment>
                                <div className="balance">
                                    {numberWithSpaces(balance)}
                                    <div className="ru_value"> ₽</div>
                                </div>
                                <button className="replenish_btn" onClick={this.handlePaymentPopup}>
                                    + Пополнить
                                </button>
                            </Fragment>
                        )}
                        <div className="notifications_wrapper" onClick={this.openNotifications}>
                            <button className="notifications_block">
                                {notifications.length ? (
                                    <img src={notificationIcon} alt="notifications" />
                                ) : (
                                    <img src={notifIcon} alt="notifications" />
                                )}
                            </button>
                            {notificationIsOpen ? (
                                <ClickAwayListener onClickAway={this.handleOpenNotifications}>
                                    <div className="notifications_info_wrapper">
                                        {notifications.length ? (
                                            <Fragment>
                                                {notifications.map(({ id, amount, deal, status, type }) => {
                                                    return (
                                                        <div
                                                            className="status_notifications"
                                                            key={`notification_${id}`}
                                                            onClick={() => this.handleNotificationClick(id, deal, type)}
                                                        >
                                                            {this.renderNotification(amount, deal, status, type)}
                                                        </div>
                                                    );
                                                })}
                                                <div className="no_items">
                                                    <button onClick={() => this.clearNotifications()}>
                                                        Очистить все
                                                    </button>
                                                </div>
                                            </Fragment>
                                        ) : (
                                            <div className="no_items">Нет непросмотренных уведомлений</div>
                                        )}
                                    </div>
                                </ClickAwayListener>
                            ) : null}
                        </div>
                        <div className="menu_wrapper">
                            <div className="block_drop_down" onClick={this.openMenu}>
                                <div className="text_block">
                                    <div>{username}</div>
                                    <span>
                                        {bloggerInterface === "blogger"
                                            ? isAgent
                                                ? "Агент"
                                                : "Блогер"
                                            : "Рекламодатель"}
                                    </span>
                                </div>
                                <div className={menuIsOpen ? "arrow_block open_block" : "arrow_block"}>
                                    {/*<div className="arrow_block active_arrow">*/}
                                    <img src={arrow} alt="arrow" />
                                </div>
                            </div>
                            {menuIsOpen ? (
                                <ClickAwayListener onClickAway={this.handleOpenMenu}>
                                    <div className="menu">
                                        <div
                                            onClick={() => {
                                                profilePaymentInfo.type || bloggerInterface === "advertiser"
                                                    ? handleInterfaceChange(
                                                          bloggerInterface === "blogger" ? "advertiser" : "blogger"
                                                      )
                                                    : this.fetchAdvertiserType();
                                            }}
                                        >
                                            <img src={synchronization} alt="synchronization" />
                                            Интерфейс
                                        </div>
                                        {bloggerInterface !== "blogger" && (
                                            <Link to="/main/finance-page">
                                                <img src={coin} alt="coin" />
                                                Финансы
                                            </Link>
                                        )}
                                        <Link to="/main/profile">
                                            <img src={gear} alt="gear" />
                                            Настройки
                                        </Link>
                                        <hr />
                                        <button onClick={this.handleOut}>
                                            <img src={logout} alt="log-out" />
                                            Выход
                                        </button>
                                    </div>
                                </ClickAwayListener>
                            ) : null}
                        </div>
                    </div>
                </div>
                {!acquainted_offer && (
                    <DialogComponent open={openOfferDialog} hideCloseBtn={true}>
                        <OfferForm handleOfferPopup={this.handleOfferPopup} />
                    </DialogComponent>
                )}
                <DialogComponent open={openPaymentDialog} onClose={this.handlePaymentPopup}>
                    <div className="payment_details_dialog">
                        <div className="big_title">Пополнить счёт</div>
                        {requisite ? (
                            <Fragment>
                                <div className="payment_reason">
                                    <div>Назначение платежа</div>
                                    <p>
                                        Пополнение номинального счета для получения доступа к услугам платформы iMAST
                                        согласно договору-оферте ver.0.1. НДС не облагается.
                                    </p>
                                </div>
                                <div className="description">Реквизиты для пополнения счёта.</div>
                                <div className="requisites_payment_wrapper">
                                    <div>
                                        <p>Индивидуальный предприниматель</p>
                                        <span>Мартиросян Альберт Эдуардович</span>
                                    </div>
                                    <div>
                                        <p>ИНН</p>
                                        <span>773301375475</span>
                                    </div>
                                    <div>
                                        <p>Номинальный счёт</p>
                                        <span>40802810601500124807</span>
                                    </div>
                                    <div>
                                        <p>Корреспондентский счёт</p>
                                        <span>30101810845250000999</span>
                                    </div>
                                    <div>
                                        <p>Банк</p>
                                        <span>ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ"</span>
                                    </div>
                                    <div>
                                        <p>БИК</p>
                                        <span>044525999</span>
                                    </div>
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className="description">
                                    Пополнение счёта будет доступно после того, как вы заполните реквизиты и они пройдут
                                    модерацию.
                                </div>
                                {showRequisitesForm && (
                                    <PaymentDetails
                                        requisiteDialog
                                        initialValues={profilePaymentInfo}
                                        closePaymentPopup={this.handlePaymentPopup}
                                        bloggerInterface={bloggerInterface}
                                    />
                                )}
                            </Fragment>
                        )}
                    </div>
                </DialogComponent>
                <DialogComponent
                    open={openAdvertiserTypeDialog}
                    onClose={() => this.setState({ openAdvertiserTypeDialog: false })}
                >
                    <div className="default_dialog">
                        <div className="title">Интерфейс рекламодателя</div>
                        <div className="choose_your_type">Пожалуйста, выберите Ваш статус</div>
                        <div className="advertiser_types">
                            <div
                                className={`type ${advertiserType === "IP" && "chosen_one"}`}
                                onClick={() => this.setState({ advertiserType: "IP" })}
                            >
                                <img src={IP} alt="IP" />
                                <p>Я - индивидуальный предприниматель</p>
                            </div>
                            <div
                                className={`type ${advertiserType === "UL" && "chosen_one"}`}
                                onClick={() => this.setState({ advertiserType: "UL" })}
                            >
                                <img src={UL} alt="UL" />
                                <p>Я - юридическое лицо</p>
                            </div>
                        </div>
                        <div className="btn_wrapper">
                            <button
                                className="cancel_btn"
                                onClick={() => this.setState({ openAdvertiserTypeDialog: false })}
                            >
                                Отмена
                            </button>
                            <button
                                className="green_btn"
                                type="submit"
                                disabled={!advertiserType}
                                onClick={this.handleAdvertiserType}
                            >
                                Продолжить
                            </button>
                        </div>
                    </div>
                </DialogComponent>
            </header>
        );
    }
}

function mapStateToProps(state) {
    return {
        profile_data: state.profile.profile_data,
        notifications: state.app.notifications
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getAllNotifications,
            deleteAllNotifications,
            deleteSpecificNotification,
            filterNotifications,
            patchProfile,
            postAdvertiserType,
            getAdvertiserType
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Head);
