import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { patchPaymentInformation } from "../../actions/profileActions";

import "./PaymentDetails.scss";
import Snack from "../HelperComponents/Snack/Snack";

class PaymentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bloggerInterface: this.props.bloggerInterface === "blogger",
            userData: {
                payment_information: {
                    payment_account: props.initialValues.payment_account ? props.initialValues.payment_account : "",
                    bank_code: props.initialValues.bank_code ? props.initialValues.bank_code : "",
                    inn: props.initialValues.inn ? props.initialValues.inn : "",
                    kpp: props.initialValues.kpp ? props.initialValues.kpp : "",
                    organisation_name: props.initialValues.organisation_name
                        ? props.initialValues.organisation_name
                        : "",
                    recipient: props.initialValues.recipient ? props.initialValues.recipient : "",
                    bank_name: props.initialValues.bank_name ? props.initialValues.bank_name : "",
                    correspondent_account: props.initialValues.correspondent_account
                        ? props.initialValues.correspondent_account
                        : "",
                    first_name: props.initialValues.first_name ? props.initialValues.first_name : "",
                    middle_name: props.initialValues.middle_name ? props.initialValues.middle_name : "",
                    last_name: props.initialValues.last_name ? props.initialValues.last_name : "",
                    type: props.initialValues.type ? props.initialValues.type : ""
                }
            },
            errorSnack: false,
            errorSnackText: ""
        };
    }

    handleInputChangeProfile = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            userData: {
                ...this.state.userData,
                payment_information: {
                    ...this.state.userData.payment_information,
                    [name]: value
                }
            }
        });
    };

    sendProfileData = async () => {
        const { closePaymentPopup, patchPaymentInformation } = this.props;
        const {
            userData: { payment_information }
        } = this.state;
        const res = await patchPaymentInformation(payment_information);
        if (res.payload) {
            closePaymentPopup();
        } else {
            this.setState({
                errorSnack: true,
                errorSnackText: Object.values(Object.values(res.error.response.data)[0])[0]
            });
        }
    };

    renderBloggerFields = () => {
        const {
            payment_information: { payment_account, bank_code, bank_name, inn, recipient, correspondent_account }
        } = this.state.userData;
        return (
            <>
                <div className="row">
                    <div className="col">
                        <label>Номер счёта</label>
                        <input
                            type="text"
                            value={payment_account}
                            name="payment_account"
                            placeholder="Введите номер счёта"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                    <div className="col">
                        <label>Наименование банка</label>
                        <input
                            type="text"
                            value={bank_name}
                            name="bank_name"
                            placeholder="Введите наименование банка"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>БИК банка</label>
                        <input
                            type="text"
                            value={bank_code}
                            name="bank_code"
                            placeholder="Введите БИК банка"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                    <div className="col">
                        <label>Корреспондентский счёт</label>
                        <input
                            type="text"
                            value={correspondent_account}
                            name="correspondent_account"
                            placeholder="Введите корреспондентский счёт"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Получатель</label>
                        <input
                            type="text"
                            value={recipient}
                            name="recipient"
                            placeholder="Введите имя получателя"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                    <div className="col">
                        <label>ИНН</label>
                        <input
                            type="text"
                            value={inn}
                            name="inn"
                            placeholder="Введите ИНН"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                </div>
            </>
        );
    };

    renderAdvertiserULFields = () => {
        const {
            payment_information: {
                payment_account,
                bank_code,
                bank_name,
                kpp,
                organisation_name,
                inn,
                correspondent_account
            }
        } = this.state.userData;
        return (
            <>
                <div className="row">
                    <div className="col">
                        <label>Номер счёта</label>
                        <input
                            type="text"
                            value={payment_account}
                            name="payment_account"
                            placeholder="Введите номер счёта"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                    <div className="col">
                        <label>Наименование банка</label>
                        <input
                            type="text"
                            value={bank_name}
                            name="bank_name"
                            placeholder="Введите наименование банка"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>КПП</label>
                        <input
                            type="text"
                            value={kpp}
                            name="kpp"
                            placeholder="Введите КПП"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                    <div className="col">
                        <label>БИК банка</label>
                        <input
                            type="text"
                            value={bank_code}
                            name="bank_code"
                            placeholder="Введите БИК банка"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Корреспондентский счёт</label>
                        <input
                            type="text"
                            value={correspondent_account}
                            name="correspondent_account"
                            placeholder="Введите корреспондентский счёт"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Наименование организации</label>
                        <input
                            type="text"
                            value={organisation_name}
                            name="organisation_name"
                            placeholder="Введите наименование организации"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                    <div className="col">
                        <label>ИНН</label>
                        <input
                            type="text"
                            value={inn}
                            name="inn"
                            placeholder="Введите ИНН"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                </div>
            </>
        );
    };

    renderAdvertiserIPFields = () => {
        const {
            payment_information: {
                payment_account,
                bank_code,
                bank_name,
                inn,
                correspondent_account,
                first_name,
                middle_name,
                last_name
            }
        } = this.state.userData;
        return (
            <>
                <div className="row">
                    <div className="col">
                        <label>Номер счёта</label>
                        <input
                            type="text"
                            value={payment_account}
                            name="payment_account"
                            placeholder="Введите номер счёта"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                    <div className="col">
                        <label>Наименование банка</label>
                        <input
                            type="text"
                            value={bank_name}
                            name="bank_name"
                            placeholder="Введите наименование банка"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>БИК банка</label>
                        <input
                            type="text"
                            value={bank_code}
                            name="bank_code"
                            placeholder="Введите БИК банка"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                    <div className="col">
                        <label>Корреспондентский счёт</label>
                        <input
                            type="text"
                            value={correspondent_account}
                            name="correspondent_account"
                            placeholder="Введите корреспондентский счёт"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Фамилия получателя</label>
                        <input
                            type="text"
                            value={last_name}
                            name="last_name"
                            placeholder="Введите фамилию получателя"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                    <div className="col">
                        <label>Имя получателя</label>
                        <input
                            type="text"
                            value={first_name}
                            name="first_name"
                            placeholder="Введите имя получателя"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Отчество получателя</label>
                        <input
                            type="text"
                            value={middle_name}
                            name="middle_name"
                            placeholder="Введите отчество получателя"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                    <div className="col">
                        <label>ИНН</label>
                        <input
                            type="text"
                            value={inn}
                            name="inn"
                            placeholder="Введите ИНН"
                            onChange={this.handleInputChangeProfile}
                            onKeyDown={this.handlerKeyDown}
                        />
                    </div>
                </div>
            </>
        );
    };

    render() {
        const { bloggerInterface, errorSnack, errorSnackText } = this.state;
        const {
            payment_information: {
                payment_account,
                bank_code,
                bank_name,
                kpp,
                organisation_name,
                inn,
                recipient,
                correspondent_account,
                type,
                first_name,
                last_name
            }
        } = this.state.userData;
        const { closePaymentPopup, requisiteDialog } = this.props;
        let isSubmitButtonDisabled = undefined;
        if (bloggerInterface) {
            isSubmitButtonDisabled =
                !payment_account || !bank_code || !recipient || !inn || !bank_name || !correspondent_account;
        } else {
            if (type === "UL") {
                isSubmitButtonDisabled =
                    !payment_account ||
                    !bank_code ||
                    !kpp ||
                    !organisation_name ||
                    !inn ||
                    !bank_name ||
                    !correspondent_account;
            } else {
                isSubmitButtonDisabled =
                    !payment_account ||
                    !bank_code ||
                    !bank_name ||
                    !inn ||
                    !correspondent_account ||
                    !first_name ||
                    !last_name;
            }
        }
        const fieldsToRender = bloggerInterface
            ? this.renderBloggerFields()
            : type === "UL"
            ? this.renderAdvertiserULFields()
            : this.renderAdvertiserIPFields();
        return (
            <form className="container-form payment_details_form">
                <hr />
                {fieldsToRender}
                <div className="btn_wrapper">
                    <button
                        className="cancel_btn"
                        onClick={(e) => {
                            e.preventDefault();
                            closePaymentPopup();
                        }}
                    >
                        {requisiteDialog ? "Отмена" : "Пропустить"}
                    </button>
                    <button
                        className="green_btn"
                        type="submit"
                        disabled={isSubmitButtonDisabled}
                        title={isSubmitButtonDisabled ? "Для подтверждения необходимо заполнить все поля" : ""}
                        onClick={(e) => {
                            e.preventDefault();
                            this.sendProfileData();
                        }}
                    >
                        Подтвердить
                    </button>
                </div>
                <Snack
                    open={errorSnack}
                    handleSnack={() => this.setState({ errorSnack: false })}
                    message={errorSnackText}
                    type="error"
                />
            </form>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            patchPaymentInformation
        },
        dispatch
    );
}

export default connect(null, mapDispatchToProps)(PaymentDetails);
